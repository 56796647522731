import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPropietarios, removePropietario } from '../../reducers/propietario_slice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import FormPropietario from './FormPropietario';
import ModalPropietario from './ModalPropietario';

const ListPropietarios = () => {
  const dispatch = useDispatch();
  const propietarios = useSelector(state => state.propietario.propietarios || []);
  const [showModal, setShowModal] = useState(false);
  const [currentPropietario, setCurrentPropietario] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key1: null,key2: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPropietarios());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePropietario(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La categoría ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la categoría', 'error');
        });
      }
    });
  };

  
  const handleSort = (key1, key2) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key1, key2, direction });
  };
  const handleEdit = propietario => {
    setCurrentPropietario(propietario);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentPropietario(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentPropietario(null);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const headers = [['N°', 'CATEGORÍA','COSTO MES', 'ÁREA','DESCRIPCIÓN']];

  // Mapear datos para personalizar los nombres de las columnas
  const data = sortedPropietarios.map((propietario, index) => [
    index + 1,
    propietario.categoria.nombre,
        propietario.categoria.costo_mes,
        propietario.area.nombre,
        propietario.descripcion,
  ]);

  // Combinar las cabeceras y los datos
  const exportData = [...headers, ...data];

  // Crear la hoja de trabajo con las cabeceras personalizadas
  const worksheet = XLSX.utils.aoa_to_sheet(exportData); 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Propietarios");
    XLSX.writeFile(workbook, "propietarios.xlsx");
  };

  const sortedPropietarios = useMemo(() => {
    let sortedData = [...propietarios];
    if (sortConfig.key2) {
      sortedData.sort((a, b) => {
      let aValue, bValue;
          // Comparar subpropiedades
          if (sortConfig.key1 !== "") {
             // Comparar como números enteros
            if (sortConfig.key2 === "costo_mes") {

              aValue = parseInt(a[sortConfig.key1][sortConfig.key2], 10);  
              bValue = parseInt(b[sortConfig.key1][sortConfig.key2], 10);
            }else{
              aValue = a[sortConfig.key1][sortConfig.key2];
              bValue = b[sortConfig.key1][sortConfig.key2];
            }
          } 
          // Comparar otros valores
          else {
              aValue = a[sortConfig.key2];
              bValue = b[sortConfig.key2];
          }
          // Manejar valores nulos para que vayan al final
          if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
          if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
  
          // Comparar valores no nulos
          if (aValue < bValue) {
              return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
              return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
    });
  }
    return sortedData;
  }, [propietarios, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'CATEGORÍA','COSTO MES', 'ÁREA','DESCRIPCIÓN']],
      body: sortedPropietarios.map((propietario, index) => [
        index+1,
        propietario.categoria.nombre,
        propietario.categoria.costo_mes,
        propietario.area.nombre,
        propietario.descripcion,
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Propietarios</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6">
           
          </div>
          <div className="col-md-6">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Propietario
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {propietarios.length === 0 ? (
          <p>No hay propietarios disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('categoria', 'num')}>
                    Categoría
                    {sortConfig.key1 === 'categoria' && sortConfig.key2 === 'num' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('categoria','costo_mes')}>
                    Costo Mes
                    {sortConfig.key2 === 'costo_mes' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('area','nombre')}>
                    Rubro
                    {sortConfig.key1 === 'area'  && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','descripcion')}>
                    Descripción
                    {sortConfig.key2 === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedPropietarios.map((propietario, index) => (
                  <tr key={propietario.id}>
                    <td>{index + 1}</td>
                    <td>{propietario.categoria.nombre}</td>
                    <td>{propietario.categoria.costo_mes}</td>
                    <td>{propietario.area.nombre}</td>
                    <td>{propietario.descripcion}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(propietario)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(propietario.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ModalPropietario currentPropietario={currentPropietario} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE TIPOLOGÍAS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default ListPropietarios;
