import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstituciones, removeInstitucion } from '../../reducers/institucion_slice';
import { fetchPaises } from '../../reducers/pais_slice';
import InstitucionForm from './InstitucionForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal';

const InstitucionList = () => {
  const dispatch = useDispatch();
  const institucionState = useSelector(state => state.institucion || { instituciones: [] });
  const paisState = useSelector(state => state.pais || { paises: [] });
  const instituciones = institucionState.instituciones || [];
  const paises = paisState.paises || [];
  const [showModal, setShowModal] = useState(false);
  const [currentInstitucion, setCurrentInstitucion] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchInstituciones());
    dispatch(fetchPaises());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeInstitucion(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La institución ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la institución', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key: null, direction: 'asc' });
      return;
    }

    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }

    setSortConfig({ key, direction });
  };

  const handleEdit = institucion => {
    setCurrentInstitucion(institucion);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentInstitucion(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentInstitucion(null);
  };

  const handleBack = () => {
    navigate('/admin'); // Ruta a la que deseas regresar, en este caso, la página principal
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedInstituciones.map(inst => ({
      ...inst,
      pais: getPaisNombre(inst.pais)
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Instituciones");
    XLSX.writeFile(workbook, "instituciones.xlsx");
  };

  const sortedInstituciones = useMemo(() => {
    let sortedData = [...instituciones];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [instituciones, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['ID', 'Nombre', 'País']],
      body: sortedInstituciones.map(inst => [
        inst.id,
        inst.nombre,
        getPaisNombre(inst.pais)
      ])
    };
    return content;
  };

  const getPaisNombre = (paisId) => {
    const pais = paises.find(p => p.id === paisId);
    return pais ? pais.nombre : 'Desconocido';
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Instituciones</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6"></div>
          <div className="col-md-6 text-right">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nueva Institución
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {instituciones.length === 0 ? (
          <p>No hay instituciones disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th className='col-index'>ID</th>
                  <th onClick={() => handleSort('nombre')}>
                    Nombre
                    {sortConfig.key === 'nombre' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('pais')}>
                    País
                    {sortConfig.key === 'pais' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedInstituciones.map((institucion, index) => (
                  <tr key={institucion.id}>
                    <td>{index + 1}</td>
                    <td>{institucion.id}</td>
                    <td>{institucion.nombre}</td>
                    <td>{getPaisNombre(institucion.pais)}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(institucion)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(institucion.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <InstitucionForm currentInstitucion={currentInstitucion} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE INSTITUCIONES"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default InstitucionList;
