import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchInmuebles } from '../../reducers/inmueble_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import ListInmuebles from './ListInmuebles';

const initialState = {
    id_inmueble:"",
    inmuebleSeleccionado:null

}
const SelectInmueble = ({ onSelect, inmuebleSelected, labelText, permitirEdicion = true }) => {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchInmuebles());
  }, [dispatch]);

  useEffect(() => {
    if (inmuebleSelected) {
      setDatos({ ...datos, id_inmueble: inmuebleSelected.id, inmuebleSeleccionado: inmuebleSelected });
    } else {
      setDatos(initialState);
    }
  }, [inmuebleSelected]);

  const handleSelectInmueble = (inmueble) => {
    if (permitirEdicion) {
      const inmuebleId = inmueble ? inmueble.id : '';
      setDatos({ ...datos, id_inmueble: inmuebleId, inmuebleSeleccionado: inmueble });
      onSelect('inmueble', inmueble);
      setShowModal(false); // Cierra el modal después de seleccionar
    }
  };

  return (
    <>
      <Row xs={1} className="mt-1">
        <Col className="col-form">
          <Form.Label>{labelText}</Form.Label>
          {datos.inmuebleSeleccionado ? (
            <div className="selected-container p-3 border rounded shadow-sm">
              <div>
                <strong>Cód. Catastral: {datos.inmuebleSeleccionado.cod_catastral}</strong>
                <br />
                <span>
                  Zona: {datos.inmuebleSeleccionado.zona?.nombre},{' '}
                  {datos.inmuebleSeleccionado.zona?.lugar?.nombre}
                </span>
              </div>
              {permitirEdicion && (
                <Button
                  variant="link"
                  onClick={() => {
                    setDatos({ ...datos, id_inmueble: '', inmuebleSeleccionado: null });
                    handleSelectInmueble(null);
                    setShowModal(true);
                  }}
                >
                  Cambiar inmueble
                </Button>
              )}
            </div>
          ) : (
            <div className="selected-container p-3 border rounded shadow-sm">
                <p>No se ha seleccionado un inmueble aún.</p>
              <Button variant="link" onClick={() => setShowModal(true)}>
                Seleccionar inmueble
              </Button>
            </div>
          )}
        </Col>
      </Row>

      {/* Modal para seleccionar inmueble */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar inmueble</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListInmuebles onSelectInmueble={handleSelectInmueble} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectInmueble;
