import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLotes, fetchLotesByUsuario, removeProyecto } from '../../reducers/proyecto_slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatNumber, fullNamePersona } from '../../util/customFormatter';
import CustomPrintModalH from '../UI/CustomPrintModalH';
import VerProyectoModal from './VerProyectoModal';
import { getEstadoProyecto } from '../../util/valueCalculator';
import { InputGroup } from 'react-bootstrap';
import FormProyecto from './FormProyecto';

const ListLotes = () => {
  const dispatch = useDispatch();
  const lotes = useSelector(state => state.proyecto.proyectos || [] );
  const [showModal, setShowModal] = useState(false);
  const [showModalVer, setShowModalVer] = useState(false);
  const [currentLote, setCurrentLote] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const { rolActivo, nombre, id:idUser} = useSelector(state => state.sesion);
  const [sortConfig, setSortConfig] = useState({ key1: null, key2: null, key3: null,  direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if(rolActivo?.alias === "gerente"){ dispatch(fetchLotes()) ;}
    else{dispatch(fetchLotesByUsuario());}
  }, [dispatch,rolActivo]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El lote de construcción ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el lote de construcción', 'error');
        });
      }
    });
  };

 
  const handleSort = (key1, key2, key3=null) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key3:null, key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key3, key1, key2, direction });
  };

  const handleEdit = lote => {
    setCurrentLote(lote);
    setShowModal(true);
  };

  const handleVer= lote => {
    setCurrentLote(lote);
    setShowModalVer(true);
  };
  const handleCloseVer = () => {
    setShowModalVer(false);
    setCurrentLote(null);
  };

  const handleCreate = () => {
    setCurrentLote(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentLote(null);
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    // Combinar las cabeceras y los datos
    const exportData = [...contentArrays.head, ...contentArrays.body];
    // Crear la hoja de trabajo con las cabeceras personalizadas
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
     const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lotes");
    XLSX.writeFile(workbook, "Lotes.xlsx");
  };

  const sortedLotes = useMemo(() => {
    // ORDENACION
    let sortedData = [...lotes];
    if (sortConfig.key2) {
      console.log("Ordenandooooo")
      sortedData.sort((a, b) => {
        let aValue, bValue;
            // Comparar subpropiedades
            if (sortConfig.key1 !== "") {
              
              if (sortConfig.key3 !== null) {
                aValue = a[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                bValue = b[sortConfig.key1][sortConfig.key2][sortConfig.key3];
              }else{
                aValue = a[sortConfig.key1][sortConfig.key2];
                bValue = b[sortConfig.key1][sortConfig.key2];
              }
            } 
            // Comparar otros valores
            else {
                aValue = a[sortConfig.key2];
                bValue = b[sortConfig.key2];
            }
            // Manejar valores nulos para que vayan al final
            if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
            if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
    
            // Comparar valores no nulos
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
      });
    }
  
    return sortedData;
  }, [lotes, sortConfig]);
  
  
  const sortedFilteredLotes = useMemo(() => {
    
    console.log("Filtrandoo")
    // FILTRADO
    let filteredList = sortedLotes.filter(proy => {
      const matchesSearch = proy.num_visado 
        ? `${proy.num_visado}`.includes(searchTerm.toLowerCase()) 
        : false;
     
      // const matchesState = selectedEstados?.length > 0
      //   ? selectedSituaciones.some(situ => situ.value === proy.id_situacion)
      //   : true;

      return matchesSearch;
    });
    return filteredList;
}, [sortedLotes, searchTerm]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°','Núm. Visado','Proyectista','Inmueble','Propietario','Descripción','Tipo de Lote','Superficie (m2)', 'Honorarios (Bs.)', 'Comisión C.A.T. (Bs.)', 'Estado del lote']],
      body: sortedFilteredLotes.map((lote, index) => [
        index+1,
        lote.num_visado,
        fullNamePersona(lote.proyectista.persona, true),
        `${lote.inmueble.zona.nombre} ${lote.inmueble.cod_catastral}`,
        fullNamePersona(lote.propietario, true),
        lote.descripcion || 'N/A',
        lote.tipo.nombre,
        formatNumber(lote.superficie_total),
        formatNumber(lote.honorarios_total),
        formatNumber(lote.comision_cat),
        getEstadoProyecto(lote.estado).titulo,
      ])
    };
    return content;
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>Lista de Lotes</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6">
           
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent" style={{marginRight:"2rem"}}><i className="bi bi-file-spreadsheet"></i></button>
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Lote
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
             size='sm'
               type="text"
              placeholder="Buscar por número de visado"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
              </InputGroup>
        </div>
        {lotes.length === 0 ? (
          <p>No hay lotes de construcción disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('','num_visado')}>
                    Número Visado
                    {sortConfig.key2 === 'num_visado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('proyectista','persona','apellido1')}>
                    Proyectista
                    {sortConfig.key1 === 'proyectista' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('inmueble','cod_catastral')}>
                    Inmueble
                    {sortConfig.key1 === 'inmueble' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('propietario','apellido1')}>
                    Propietario
                    {sortConfig.key1 === 'propietario' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','descripcion')}>
                    Descripción del lote
                    {sortConfig.key2 === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('tipo', 'nombre')}>
                    Tipo de lote
                    {sortConfig.key1 === 'tipo' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','superficie_total')}>
                    Superficie (m2)
                    {sortConfig.key2 === 'superficie_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','honorarios_total')}>
                    Honorarios (Bs)
                    {sortConfig.key2 === 'honorarios_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','comision_cat')}>
                    Comisión C.A.T. (Bs)
                    {sortConfig.key2 === 'comision_cat' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','estado')}>
                    Estado
                    {sortConfig.key2 === 'estado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedFilteredLotes.map((lote, index) => (
                  <tr key={lote.id}>
                    <td>{index + 1}</td>
                    <td>{lote.num_visado || ""}</td>
                    <td>{fullNamePersona(lote.proyectista.persona, true)}</td>
                    <td>{`${lote.inmueble.zona.nombre} ${lote.inmueble.cod_catastral}`}</td>
                    <td>{fullNamePersona(lote.propietario, true)}</td>
                    <td>{lote.descripcion}</td>
                    <td>{lote.tipo_lote || "Normal"}</td>
                    <td>{formatNumber(lote.superficie_total)}</td>
                    <td>{formatNumber(lote.honorarios_total)}</td>
                    <td>{formatNumber(lote.comision_cat)}</td>
                    <td>{getEstadoProyecto(lote.estado).titulo}</td>
                    <td>
                     {(lote.estado === "borrador" || (rolActivo?.alias === "gerente" && lote.estado !=="visado")) 
                     && (
                     <><button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(lote)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(lote.id)}>
                        <i className="bi bi-trash"></i>
                       </button>
                       </>)
                      }
                      <button className="icon-button btn-secondary-outline" title="Ver" onClick={() => handleVer(lote)}>
                        <i className="bi bi-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <FormProyecto currentProyecto={currentLote} showModal={showModal} handleClose={handleClose} />
        <VerProyectoModal currentProyecto={currentLote} show={showModalVer} handleClose={handleCloseVer} />
       
        <CustomPrintModalH
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PROYECTOS DE CONSTRUCCIÓN"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default ListLotes;
