import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPersona, modifyPersona, fetchPersonas } from '../../reducers/persona_slice';
import { fetchLugares } from '../../reducers/lugar_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import userImgPlaceholder from '../../assets/usuario.png';
import { useInputFile } from '../../hooks/useInputFile';
import { datosNoNulos, formatearFecha, getUploadUrl } from '../../util/valueCalculator';
import ActionButton from '../UI/Buttons/ActionButton';
import { fetchAfiliados } from '../../reducers/afiliado_slice';

const initialState = {
    nombre: "",
    apellido1: "",
    apellido2: "",
    ci: "",
    id_lugar_nac: "",
    fecha_nac: "",
    direccion: "",
    telefono: "",
    celular: "",
    email: "",
    genero: ""
}
const initialFormDetalles = () =>({title:"Nueva Persona",
    headerColor:"#254159", 
    buttonText:"Crear" })

const PersonaForm = ({  simplePersona=null, onSelectPersona = null, showModal, handleClose, actualizarListaFn=null }) => {
    const dispatch = useDispatch();
    const [datos, setDatos] = useState(initialState);
    const {
        file: foto,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("image");
    const currentPersona = useSelector(state => state.persona.current);
    const [fotoPreview, setFotoPreview] = useState(userImgPlaceholder);
    const [errors, setErrors] = useState({});
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    const lugares = useSelector(state => state.lugar.lugares) || [];
    // const lugarStatus = useSelector(state => state.lugar.status);
    const requestStatus = useSelector(state => state.persona.statusSend);
    // const errorInRequest = useSelector(state => state.persona.error);

    useEffect(() => {
        if(showModal)
        {  if (currentPersona) {
                setFormDetalles({title:"Editar Datos Personales",headerColor:"#255957", buttonText:"Actualizar" })
                const persona = { ...currentPersona, fecha_nac: formatearFecha(currentPersona.fecha_nac) }
                setDatos(datosNoNulos(persona));
                setIsModified(false);

                if (currentPersona.foto) {
                    setFotoPreview(getUploadUrl(currentPersona.foto));
                } else {
                    setFotoPreview(userImgPlaceholder);
                }
                resetFoto()
            } else {
                resetForm();
        }}
    }, [currentPersona,showModal]);
    
    useEffect(() => {
        if(showModal)
        {   dispatch(fetchLugares());

        }
    }, [showModal]);

    const handleSubmit = e => {
        e.preventDefault();
        let nuevosErrores = {
            ci: datos.ci === '',
            nombre: datos.nombre === '',
            apellido1: datos.apellido1 === "",
            apellido2: datos.apellido2 === "",
            celular: datos.celular === "",
        };
        setErrors(nuevosErrores);

        if (isModified || !currentPersona || foto) {
            if (!Object.values(nuevosErrores).includes(true)) {
            const personaData = { ...datos,
                nombre: datos.nombre.toUpperCase(),
                apellido1: datos.apellido1.toUpperCase(),
                apellido2: datos.apellido2.toUpperCase(),
            };
            setErrors('');

            const formData = new FormData();
            for (const key in personaData) {
                if (personaData[key] !== null && personaData[key] !== '') {
                    formData.append(key, personaData[key]);
                }
            }
            if (foto) {
                formData.append("foto", foto);
            }
            if (currentPersona) {
                dispatch(modifyPersona({ id: currentPersona.id, persona: formData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar la persona.',
                        });
                    } else {
                        handleCloseForm()
                        if(actualizarListaFn) dispatch(actualizarListaFn())
                    }
                });
            } else {
                dispatch(addPersona(formData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear la persona.',
                        });
                    } else {
                        handleCloseForm()
                        if(actualizarListaFn) dispatch(actualizarListaFn())
                        if(onSelectPersona) onSelectPersona(response.payload)
                    }
                });
            }
        }} else {
            handleCloseForm()
        }

    };

    const resetForm = () => {
        setDatos(initialState);
        resetFoto();
        setFotoPreview(userImgPlaceholder);
        setErrors({});
        setFormDetalles(initialFormDetalles);
        setIsModified(false);
    };

    const handleCloseForm = ()=>{
        handleClose();
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
        setErrors({ ...errors, [name]: value === '' });
    }

    return (
        <Modal show={showModal} onHide={handleCloseForm} backdrop="static" keyboard={false} size={!simplePersona ? "xl" : "lg"}>
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.mensaje && (
                    <Alert variant="danger">
                        {errors.mensaje }
                    </Alert>
                )}
           
                <Form encType="multipart/form-data" className='soft-labels'>
              
                    <Row xs={1} md={2} lg={!simplePersona ? 4 : 2}>
                        <Col className='col-form'>
                                <Form.Label>C.I.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ci"
                                    placeholder='Número de cédula de identidad'
                                    value={datos.ci}
                                    onChange={handleInputChange}
                                    isInvalid={errors.ci}
                                    required
                                />
                                  <Form.Control.Feedback type='invalid'>
                                        El C.I. es requerido
                                    </Form.Control.Feedback>
                            </Col>
                        <Col className='col-form'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                placeholder='Nombre de la persona'
                                value={datos.nombre}
                                onChange={handleInputChange}
                                isInvalid={errors.nombre}
                                required
                            />           
                            <Form.Control.Feedback type='invalid'>
                            El nombre es requerido
                        </Form.Control.Feedback>
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Apellido paterno</Form.Label>
                            <Form.Control
                                type="text"
                                name="apellido1"
                                placeholder='Apellido paterno'
                                value={datos.apellido1}
                                onChange={handleInputChange}
                                isInvalid={errors.apellido1}
                                required
                            />
                              <Form.Control.Feedback type='invalid'>
                            El apellido paterno es requerido
                        </Form.Control.Feedback>
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Apellido materno</Form.Label>
                            <Form.Control
                                type="text"
                                name="apellido2"
                                placeholder='Apellido materno'
                                value={datos.apellido2}
                                onChange={handleInputChange}
                                isInvalid={errors.apellido2}
                                required
                            />
                            <Form.Control.Feedback type='invalid'>
                                El apellido materno es requerido
                            </Form.Control.Feedback>
                        </Col>
                        
                      
                    </Row>
                    {!simplePersona && <>
                        <Row xs={2} md={2} lg={4}>
                      
                    
                      <Col className='col-form'>
                          <Form.Label>Lugar de Nacimiento</Form.Label>
                          <Form.Control
                              as="select"
                              name="id_lugar_nac"
                              value={datos.id_lugar_nac}
                              onChange={handleInputChange}
                              required
                          >
                              <option value="">- Selecciona un lugar -</option>
                              {lugares.map(lugar =>
                               (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                              ))}
                          </Form.Control>
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Fecha de Nacimiento</Form.Label>
                          <Form.Control
                              type="date"
                              name="fecha_nac"
                              value={datos.fecha_nac}
                              onChange={handleInputChange}
                          />
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                              type="text"
                              name="direccion"
                              placeholder='Dirección de domicilio'
                              value={datos.direccion}
                              onChange={handleInputChange}
                          />
                      </Col>
                      <Col className='col-form'>
                          <Form.Label>Género</Form.Label>
                          <Form.Control
                              as="select"
                              name="genero"
                              value={datos.genero}
                              onChange={handleInputChange}
                              required
                          >
                              <option value="">- Selecciona un género -</option>
                              <option value="MASCULINO">MASCULINO</option>
                              <option value="FEMENINO">FEMENINO</option>
                          </Form.Control>
                      </Col>   
                  </Row>
                  </>}
                  <Row xs={2} md={2} lg={!simplePersona ? 4 : 2}>
                    <Col className='col-form'>
                            <Form.Label>Celular</Form.Label>
                            <Form.Control
                                type="text"
                                name="celular"
                                placeholder='Número de celular'
                                value={datos.celular}
                                isInvalid={errors.celular}
                                onChange={handleInputChange}
                            />
                                <Form.Control.Feedback type='invalid'>
                                    El número de celular es requerido
                                </Form.Control.Feedback>
                        </Col>
                    {!simplePersona &&
                    <><Col className='col-form'>
                          <Form.Label>Teléfono fijo</Form.Label>
                          <Form.Control
                              type="text"
                              name="telefono"
                              placeholder='Número de teléfono fijo'
                              value={datos.telefono}
                              onChange={handleInputChange}
                          />
                      </Col>
                       <Col className='col-form'>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                              type="email"
                              name="email"
                              placeholder='Correo electrónico'
                              value={datos.email}
                              onChange={handleInputChange}
                          />
                      </Col>
                      </> }
                   
                  </Row>
                  {!simplePersona &&
                  <Row xs={1} md={1} lg={2}>
             
             <Col className='col-form'>
                 <Form.Label>Foto</Form.Label>
                 <Form.Control
                     type="file"
                     name="foto"
                     isInvalid={!isFotoValid}
                     onChange={(e) => {handleFotoChange(e.target.files[0]); setIsModified(true);}}
                     disabled={false}
                 />
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen válida.
                  </Form.Control.Feedback>
                  </Col>
                  <Col className='col-form'>
                 <div className="d-flex justify-content-center mb-4">
                     <div className="text-center">
                         {fotoPreview && (
                             <div>
                                 <div>Foto</div>
                                 <Image 
                                     src={!currentPersona && foto && isFotoValid ? URL.createObjectURL(foto) : fotoPreview} 
                                     alt="Foto previa" 
                                     className="img-fluid img-cuadrada-150 mb-2 mt-3"
                                 />
                             </div>
                         )}
                     </div>
                     <div className="text-center">
                         {(currentPersona && foto && isFotoValid) && (
                             <>
                                 <div>Nueva foto</div>
                                 <Image
                                     className="img-fluid img-cuadrada-150 mb-2 mt-3"
                                     src={URL.createObjectURL(foto)}
                                 />
                             </>
                         )}
                     </div>
                 </div>
                 </Col>
                  </Row>}
                 
                    <div className="d-flex justify-content-end mt-3">
                        <button className="button-mine btn-cancel-outline" onClick={handleCloseForm} type='button'>
                            Cancelar
                        </button>
                        <button type="button" onClick={handleSubmit}  className="button-mine btn-success-outline">
                        <i className="bi bi-check-circle"></i>   {requestStatus !== "sending" ? formDetalles.buttonText: "Enviando..."}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PersonaForm;
