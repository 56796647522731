import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addInstitucion, modifyInstitucion, fetchInstituciones } from '../../reducers/institucion_slice';
import { fetchPaises } from '../../reducers/pais_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';

const initialState = {
    nombre: "",
    pais: "",
}

const InstitucionForm = ({ currentInstitucion, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.institucion.statusSend || 'idle');
    const paises = useSelector(state => state.pais.paises || []);

    useEffect(() => {
        dispatch(fetchPaises());
        if (currentInstitucion) {
            setDatos(currentInstitucion);
            setIsModified(false);
        } else {
            resetForm();
        }
    }, [currentInstitucion, dispatch]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            const datosConMayusculas = {
                ...datos,
                nombre: datos.nombre.toUpperCase()
            };

            if (currentInstitucion) {
                dispatch(modifyInstitucion({ id: currentInstitucion.id, institucion: datosConMayusculas })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar la institución.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchInstituciones());
                    }
                });
            } else {
                dispatch(addInstitucion(datosConMayusculas)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear la institución.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchInstituciones());
                    }
                });
            }
        } else {
            handleClose();
            resetForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    let title = currentInstitucion ? "Editar institución" : "Nueva institución";
    let headerColor = currentInstitucion ? '#255957' : '#254159';
    let buttonText = requestStatus !== "sending" ? (currentInstitucion ? 'Actualizar' : 'Crear') : "Enviando...";

    return (
        <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row xs={1} md={2}>
                        <Col className='col-form'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                value={datos.nombre}
                                onChange={handleInputChange}
                                required
                            />
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>País</Form.Label>
                            <Form.Control
                                as="select"
                                name="pais"
                                value={datos.pais}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecciona un país</option>
                                {paises.map(pais => (
                                    <option key={pais.id} value={pais.id}>{pais.nombre}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
                            Cancelar
                        </Button>
                        <ActionButton outline>
                            {buttonText}
                        </ActionButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default InstitucionForm;
