import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/cabeceraCat.png';
import { useSelector } from 'react-redux';

const CustomPrintModalBalanceGeneral = ({
  show,
  handleClose,
  title1,
  title2,
  content,
  copia,
}) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const { nombre } = useSelector((state) => state.sesion); // Datos del usuario

  useEffect(() => {
    if (show && content) {
      generatePdf();
    }
  }, [show, content]);

  const generatePdf = () => {
    const doc = new jsPDF('p', 'pt', 'letter');
    const marginLeftRight = 55;
    const marginTop = 55;
    const marginBottom = 75;
    const headerHeight = 90;

    const addWatermark = (doc) => {
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.setFontSize(72);
      doc.setTextColor(240, 240, 240);
      doc.setFont('helvetica', 'bold');
      doc.text('COPIA', pageWidth / 2, pageHeight / 2, {
        angle: 45,
        align: 'center',
      });
    };

    const addHeader = (doc) => {
      doc.addImage(
        cabeceraCud,
        'PNG',
        marginLeftRight,
        marginTop,
        doc.internal.pageSize.getWidth() - marginLeftRight * 2,
        headerHeight
      );
      doc.setFontSize(16);
      doc.setFont('times', 'bold');
      
      doc.text(title1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 18, { align: 'center' });
      doc.setFontSize(12);
      doc.text(title2, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 31, { align: 'center' });


      
    };

    const addFooter = (doc) => {
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.setDrawColor(0, 50, 0);
      doc.setLineWidth(1);
      doc.line(
        marginLeftRight,
        pageHeight - marginBottom - 10,
        pageWidth - marginLeftRight,
        pageHeight - marginBottom - 10
      );

      doc.setFontSize(12);
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(
                `Impreso el: ${new Date().toLocaleString()} | Usuario: ${nombre}`, 
                pageWidth - marginLeftRight, 
                pageHeight - marginBottom + 40, 
                { align: 'right' }
            );
    };

    const generateOneColumn = (doc) => {
      const pageWidth = doc.internal.pageSize.getWidth();
      const marginLeft = 50; // Margen izquierdo
      const startY = marginTop + headerHeight + 80;
    
      const activos = content?.activos || [];
      const pasivosYpatrimonio = content?.pasivosYpatrimonio || [];
    
      let currentY = startY;
    
      // **Función para imprimir cuentas según nivel**
      const printCuentasNivel = (doc, cuentas, x, y) => {
        cuentas.forEach((cuenta) => {
          if (!cuenta || !cuenta.codigo_cuenta) return;
    
          // Calcular nivel por longitud del código de cuenta
          const nivel = Math.floor(cuenta.codigo_cuenta.length / 2);
          const nombre = cuenta.nombre || "Sin Nombre";
          const valor = typeof cuenta.valor === "number" ? cuenta.valor : 0;
    
          // Ajustar la posición del valor en función del nivel jerárquico
          const valorX = pageWidth - 70 - nivel * 40; // Más separación entre niveles
    
          doc.setFont("times", "normal");
          doc.setFontSize(10); // Letra más pequeña para mejorar legibilidad
          doc.text(nombre, x, y); // Nombre de la cuenta alineado a la izquierda
          doc.text(
            `${valor.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            valorX, // Alinear valor jerárquicamente
            y,
            { align: "right" }
          );
    
          y += 15; // Incrementar Y para la siguiente cuenta
        });
    
        return y;
      };
    
      // **Sección de Activos**
      doc.setFontSize(12);
      doc.setFont("times", "bold");
      doc.text("ACTIVO", marginLeft, currentY); // Título principal
      currentY += 20;
    
      currentY = printCuentasNivel(doc, activos, marginLeft, currentY);
    
      doc.setFont("times", "bold");
      doc.setFontSize(10);
      doc.text(
        `TOTAL ACTIVO: ${
          typeof content?.totalActivos === "number"
            ? content.totalActivos.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        }`,
        pageWidth - 70,
        currentY,
        { align: "right" }
      );
      currentY += 30;
    
      // **Sección de Pasivo y Patrimonio**
      doc.setFontSize(12);
      doc.setFont("times", "bold");
      doc.text("PASIVO Y PATRIMONIO", marginLeft, currentY); // Título principal
      currentY += 20;
    
      currentY = printCuentasNivel(doc, pasivosYpatrimonio, marginLeft, currentY);
    
      doc.setFont("times", "bold");
      doc.setFontSize(10);
      doc.text(
        `TOTAL PASIVO Y PATRIMONIO: ${
          typeof content?.totalPasivosPatrimonio === "number"
            ? content.totalPasivosPatrimonio.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"
        }`,
        pageWidth - 70,
        currentY,
        { align: "right" }
      );
    };
    
    
    
    
    
    
    

    // Generar PDF
    addHeader(doc);
    if (copia) addWatermark(doc);
    generateOneColumn(doc);
    addFooter(doc);

    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title1}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            width="100%"
            height="500px"
            title="Vista previa del Balance General"
          ></iframe>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomPrintModalBalanceGeneral;
