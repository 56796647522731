import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Image } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBancos } from '../../reducers/banco_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { getUploadUrl, formatearFecha, numeroALetras } from '../../util/valueCalculator';

const DepositoForm = ({ show, onHide, deposito }) => {
    const [imagenPreview, setImagenPreview] = useState(null);
    const [imagenVisible, setImagenVisible] = useState([]);
    const [reciboData, setReciboData] = useState(null);
    const [showImprimirReciboModal, setShowImprimirReciboModal] = useState(false);

    const current = useSelector(state => state.recibo.current || { recibos: [] });
    const dispatch = useDispatch();
    const bancos = useSelector(state => state.banco.bancos);
    const personas = useSelector(state => state.persona.personas);

    useEffect(() => {
        if (show) {
            dispatch(fetchBancos());
            dispatch(fetchPersonas());
            if (deposito?.id_recibo) {
                dispatch(fetchReciboById(deposito.id_recibo));
            }
        }
    }, [show, dispatch, deposito]);

    const toggleImageVisibility = (index, archivo) => {
        const newVisibility = [...imagenVisible];
        newVisibility[index] = !newVisibility[index];
        setImagenVisible(newVisibility);

        if (newVisibility[index] && archivo) {
            setImagenPreview({
                index, // Guardar el índice para que sea único por detalle
                url: getUploadUrl(archivo), // Generar la URL específica del archivo
                nombre: archivo, // Guardar el nombre del archivo
            });
        } else {
            setImagenPreview(null); // Ocultar la imagen si se cierra
        }
    };

    const handleImprimirRecibo = async () => {
        if (!current) {
            return;
        }

        try {
            const totalLiteral = numeroALetras(current.monto || 0);
            setReciboData({
                recibo: current,
                reciboDetalles: current.reciboDets || [],
                totalLiteral,
            });
            setShowImprimirReciboModal(true);
        } catch (error) {
            console.error('Error al generar el recibo:', error);
        }
    };

    if (!deposito) return null;

    // Obtener persona relacionada con el depósito
    const persona = personas.find(p => p.ci === deposito.ci);

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles del Depósito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Datos Generales */}
                    <div className="mb-4">
                        <h5>Información del Depósito</h5>
                        <p><strong>Fecha:</strong> {formatearFecha(deposito.fecha)}</p>
                        <p><strong>Monto Total:</strong> {parseFloat(deposito.monto_total).toFixed(2)} Bs.</p>
                        <p><strong>CI:</strong> {deposito.ci}</p>
                        <p>
                            <strong>Nombre:</strong>{' '}
                            {persona ? `${persona.nombre} ${persona.apellido1} ${persona.apellido2}` : 'No registrado'}
                        </p>
                        <p><strong>Usuario:</strong> {deposito.id_usuario}</p>
                    </div>

                    {/* Previsualización de imagen */}
                    {imagenPreview && (
                        <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '10' }}>
                            <Image
                                src={imagenPreview.url}
                                alt="Archivo relacionado"
                                className="img-fluid"
                                style={{
                                    maxWidth: '200px',
                                    border: '1px solid #ccc',
                                    padding: '5px',
                                    borderRadius: '5px',
                                }}
                            />
                            <p style={{ textAlign: 'center', marginTop: '5px' }}>{imagenPreview.nombre}</p>
                        </div>
                    )}

                    {/* Detalles del Depósito */}
                    <div className="mb-4">
                        <h5>Detalles del Depósito</h5>
                        {deposito.detalles && Array.isArray(deposito.detalles) && deposito.detalles.length > 0 ? (
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Sigla</th>
                                        <th>Cuenta Bancaria</th>
                                        <th>Fecha Depósito</th>
                                        <th>Monto</th>
                                        <th>Documento</th>
                                        <th>Archivo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deposito.detalles.map((detalle, index) => {
                                        const banco = bancos.find(b => b.id === detalle.id_banco);
                                        return (
                                            <tr key={detalle.id}>
                                                <td>{index + 1}</td>
                                                <td>{banco ? banco.sigla : 'No registrado'}</td>
                                                <td>{banco ? banco.cuenta_bancaria : 'No disponible'}</td>
                                                <td>{formatearFecha(detalle.fecha_deposito)}</td>
                                                <td>{parseFloat(detalle.monto).toFixed(2)} Bs.</td>
                                                <td>{detalle.docum}</td>
                                                <td>
                                                    {detalle.archivo && (
                                                        <>
                                                            <button
                                                                className="icon-button"
                                                                onClick={() => toggleImageVisibility(index, detalle.archivo)}
                                                            >
                                                                {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                                                            </button>
                                                            <span style={{ marginLeft: '10px' }}>{detalle.archivo}</span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No hay detalles del depósito disponibles.</p>
                        )}
                    </div>

                    {/* Detalles del Recibo */}
                    <div className="mb-4">
                        <h5>Detalles del Recibo</h5>
                        <p><strong>Nro Recibo:</strong> {current?.nroRecibo || 'No disponible'}</p>
                        {current.reciboDets && Array.isArray(current.reciboDets) && current.reciboDets.length > 0 ? (
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Precio Unitario</th>
                                        <th>Monto</th>
                                        <th>Descuento</th>
                                        <th>Pagado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {current.reciboDets.map((detalle, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{detalle.concepto}</td>
                                            <td>{detalle.cantidad}</td>
                                            <td>{parseFloat(detalle.pu).toFixed(2)} Bs.</td>
                                            <td>{parseFloat(detalle.monto).toFixed(2)} Bs.</td>
                                            <td>{parseFloat(detalle.descuento).toFixed(2)} Bs.</td>
                                            <td>{parseFloat(detalle.pagado).toFixed(2)} Bs.</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No se encontraron detalles del recibo.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleImprimirRecibo}>
                        Imprimir Recibo
                    </Button>
                    <Button variant="secondary" onClick={onHide}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {showImprimirReciboModal && reciboData && (
                <ImprimirRecibo
                    show={showImprimirReciboModal}
                    handleClose={() => setShowImprimirReciboModal(false)}
                    recibo={reciboData.recibo}
                    reciboDetalles={reciboData.reciboDetalles}
                    totalLiteral={reciboData.totalLiteral}
                />
            )}
        </>
    );
};

export default DepositoForm;
