import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addZona, modifyZona, fetchZonas } from '../../reducers/zona_slice';
import { fetchLugares } from '../../reducers/lugar_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const ZonaForm = ({ currentZona, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [idLugar, setIdLugar] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();
  const lugares = useSelector(state => state.lugar.lugares || []); // Obtener lugares del store

  useEffect(() => {
    dispatch(fetchLugares()); // Cargar lugares al montar el formulario
    if (currentZona) {
      setNombre(currentZona.nombre);
      setIdLugar(currentZona.id_lugar || '');
      setIsModified(false);
    } else {
      resetForm();
    }
  }, [currentZona, dispatch]);

  useEffect(() => {
    if (currentZona) {
      const isDifferent = nombre !== currentZona.nombre || idLugar !== currentZona.id_lugar;
      setIsModified(isDifferent);
    }
  }, [nombre, idLugar, currentZona]);

  const handleSubmit = e => {
    e.preventDefault();
    const zonaData = { nombre, id_lugar: idLugar };

    if (currentZona) {
      dispatch(modifyZona({ id: currentZona.id, zona: zonaData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar la zona.', 'error');
        } else {
          Swal.fire('Éxito', 'La zona se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchZonas());
        }
      });
    } else {
      dispatch(addZona(zonaData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear la zona.', 'error');
        } else {
          Swal.fire('Éxito', 'La zona se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchZonas());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setIdLugar('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentZona ? 'Editar Zona' : 'Nueva Zona'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control 
              type="text" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              required 
              placeholder="Nombre de la zona"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Lugar</Form.Label>
            <Form.Control 
              as="select" 
              value={idLugar} 
              onChange={(e) => setIdLugar(e.target.value)} 
              required
            >
              <option value="">Seleccione un lugar</option>
              {lugares.map(lugar => (
                <option key={lugar.id} value={lugar.id}>
                  {lugar.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={currentZona && !isModified}
            >
              {currentZona ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ZonaForm;
