import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProyecto, modifyProyecto, fetchProyectos } from '../../reducers/proyecto_slice';
import { fetchAreasProyecto } from '../../reducers/areaProyecto_slice';
import { fetchTiposProyecto } from '../../reducers/tipoProyecto_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert,  Table, Card, Col, Row, Tab, Tabs, Image } from 'react-bootstrap';
import { fetchTipologias } from "../../reducers/tipologia_slice";
import { fetchCategoriasConIndices } from "../../reducers/categoria_slice";
import { formatearDecimal, formatNumber, formatoOracion, fullNamePersona } from "../../util/customFormatter";
import { fetchHonorariosUnidadRepetida } from "../../reducers/unidad_slice";
import SelectAfiliado from '../afiliado/SelectAfiliado';
import SelectInmueble from '../inmueble/SelectInmueble';
import SelectPersona from '../persona/SelectPersona';
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchCuotasByAfiliado } from '../../reducers/cuota_slice';
import { useInputFile } from '../../hooks/useInputFile';
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import { fechaActualBolivia, getEstadoProyecto, getUploadUrl } from '../../util/valueCalculator';
import VistaGeneralProyecto from './VistaGeneralProyecto';
import TabDatosProyecto from './TabDatosProyecto';
import moment from 'moment-timezone';
import { BsEye, BsEyeSlash, BsCamera } from 'react-icons/bs';
import { fetchBancos } from '../../reducers/banco_slice';
import EpsonPrintModal from '../UI/EpsonPrintModal';
import { fetchCajasDiariasByUsuario } from '../../reducers/caja_slice';

const tiposEspecialesLote =[ {
    nombre:"Licencia de funcionamiento",
    alias:"licencia",
    precio:35
},
{
    nombre:"Cementerio",
    alias:"cementerio",
    precio:5
},
{  nombre:"Plano catastral",
    alias:"plano_catastral",
    precio:35
}]
const initialState = {
    id_proyectista: "",
    id_propietario: "",
    id_inmueble: "",
    id_gerente:"",
    id_tipo_proyecto: "",
    descripcion:"",
    honorarios_total:0,
    comisionCalculada:0,
    descuento:0,
    comision_cat:0,
    superficie_total:"",
    estado:"borrador",
    unidades:[],
    valorados:[],
    proyectista:null,
    inmueble:null,
    propietario:null,
    observacion:null,
}

const verificacionesInitialForm = {
   proyectistaAportes:null
}

const initialDetalles={
    tipo_lote:"", 
    descripcion:"", 
    comisionCalculada:0,
    descuento:0,
    comision_cat:0, 
    honorarios_total:0, 
    superficie_total:0
}

const initialUnidad = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:{},
    descripcion: "",
    superficie: "",
    descripcion: "",
    honorarios:"0",
    indice_calculo:"0",
    num_unidades: "1",
}

const initialErrors = {
    id_tipo_proyecto: false,
    descripcion: false,
    proyectista: false,
    proyectistaAportes:false,
    inmueble: false,
    propietario: false,
  }
const initialFormDetalles = {title:"Nuevo Proyecto",
    headerColor:"#254159", 
    buttonText:"Crear" }

const initialStateDepositos = [
    { id_banco: '', 
        monto: '', 
        fecha_deposito: moment().format('YYYY-MM-DD'), 
        docum: '', 
        archivo: null }
    ]   
         
const porcentaje_comision=0.06;
const FormProyecto = ({ currentProyecto, 
    tipoProceso = "crear", 
    showModal, 
    handleClose,
    handleViewRecibo }) => {


    const dispatch = useDispatch();

    const [datos, setDatos] = useState(initialState);
    const {
        file: fotoPlano,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("image");

    const [fotoPreview, setFotoPreview] = useState(imgPlaceholder);
    const [errors, setErrors] = useState(initialErrors);
    const [verificaciones, setVerificaciones] = useState(verificacionesInitialForm);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [key, setKey] = useState('proyectista');
    const tipologias = useSelector(state => state.tipologia.tipologias || []);
    const categorias = useSelector(state => state.categoria.categorias || []);
    const honorariosPorUnidadRepetida = useSelector(state => state.unidad.honorariosUnidadRepetida || []);
    const tiposProyecto = useSelector(state => state.tipoProyecto.tipos || []);
    const { rolActivo, id:idUser } = useSelector(state => state.sesion);

    const [unidadSelected, setUnidadSelected] = useState(initialUnidad);
    const [errorUnidadForm, setErrorUnidadForm] = useState("");
    const [editIndex, setEditIndex] = useState(null); // Para manejar la edición
    const [tipoChanged, setTipoChanged] = useState(null);
    const [requiereUnidades, setRequiereUnidades] =useState(false)
    const [requiereCalcular, setRequiereCalcular] =useState(false)
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const [isCheckedEspecial, setIsCheckedEspecial] = useState(null);

    const [tipoPago, setTipoPago] = useState("B");
    const [depositos, setDepositos] = useState(initialStateDepositos);
    const [paymentSaved, setPaymentSaved] = useState(false);
    const [totalDepositado, setTotalDepositado] = useState({
        pagado:0,
        porPagar:0,
        pagadoAnteriormente:0
    });
    const bancos = useSelector(state => state.banco.bancos || []);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [imagenVisible, setImagenVisible] = useState([]);
    const [nombreArchivo, setNombreArchivo] = useState(''); 
    const [depositIndex, setDepositIndex] = useState(null); // Nuevo estado para almacenar el índice de la fila actual
    const [showCameraModal, setShowCameraModal] = useState(false); 
    const videoRef = useRef(null); 
    const [botonProformaHabilitado, setBotonProformaHabilitado] = useState(false);
    const [proformaContent, setProformaContent] = useState(null); 
    const [showEpsonPrintModal, setShowEpsonPrintModal] = useState(false); 
    const cuotas = useSelector(state => state.cuota.cuotas);
    const xcaja=useSelector(state => state.sesion.caja);
    const xcuentaC=useSelector(state => state.sesion.cuentacaja);
    const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria);

    useMemo(() => 
      { 
        const cuotasFiltradas =  cuotas.filter(cuota => cuota.estado === 'no_pagada')
        if(datos.proyectista) {
            if(cuotasFiltradas.length === 0) setVerificaciones((prev)=> ({...prev, proyectistaAportes:'sin_pendientes'})) 
            else setVerificaciones((prev)=> ({...prev, proyectistaAportes:'con_pendientes'})) 
        }
        return cuotasFiltradas
        }, 
      [cuotas]
    );

    useEffect(() => {
        return () => {
          if (showCameraModal && videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
          }
        };
      }, [videoRef, showCameraModal]);

    const esLote = useMemo(()=>{ 
        return datos.tipo && datos.tipo.alias === "lote"
    },[datos.tipo])

    useEffect(() => {
        dispatch(fetchTipologias());
        dispatch(fetchPersonas());
        dispatch(fetchTiposProyecto());
        dispatch(fetchCategoriasConIndices());
        dispatch(fetchHonorariosUnidadRepetida());
        dispatch(fetchAreasProyecto());
        dispatch(fetchBancos());
        dispatch(fetchCajasDiariasByUsuario(idUser)); // Llamar al thunk para cajas diarias
    }, [dispatch, idUser]);

      useEffect(() => {
        const totalYaPagado= datos.recibos?.reduce((total, recibo) => total + parseFloat(recibo.monto || 0), 0) || 0
        const total = (tipoPago === "B" ? (depositos.reduce((total, deposito) => total + parseFloat(deposito.monto || 0), 0)) 
                            : (parseFloat(datos.comision_cat)-totalYaPagado));
        setTotalDepositado({porPagar: Math.max(0, parseFloat(datos.comision_cat)-totalYaPagado), 
                            pagado:total,
                            pagadoAnteriormente:totalYaPagado});
        validarCondicionesParaProforma();
      }, [depositos, tipoPago, datos?.comision_cat]);
    
  

    useEffect(() => {
        if(showModal){
        if(currentProyecto){
            console.log("currentProyecto en useEffect",!currentProyecto.id)
            const {unidades, valorados, ...proyecto} = currentProyecto
            const unidadesLista = unidades.map(unidad => {
                const tipologia = tipologias.find(tip => tip.id === unidad.id_tipologia)
                
                return {...unidad, tipologia:tipologia, id_categoria:tipologia.id_categoria,
                        id_area:tipologia.id_area
                        }
            })
            let valoradosLista;
            if(!currentProyecto.id){
                valoradosLista = valorados.map(val => {
                    const {descripcion, id } = val
                    return {descripcion:descripcion,
                            id_valorado:id, 
                            id_proyecto:"", 
                            nrovalorado:"",
                            activo:true
                            }
                    })
                if(tipoProceso === "resellar")
                 {  
                    setFormDetalles({title:`Resellar proyecto`, headerColor:"#255957", buttonText:"Resellar" })
                }else if(tipoProceso === "duplicar"){
                    setFormDetalles(initialFormDetalles);
                }
            }else if(tipoProceso === "editar") {
                if(valorados.length>0)
                  {  valoradosLista = valorados.map(val => {
                        const {ValoradoProyecto, descripcion, id } = val
                        return {descripcion:descripcion,
                                id_valorado:id, 
                                id_proyecto:ValoradoProyecto.id_proyecto, 
                                nrovalorado:ValoradoProyecto.nrovalorado,
                                activo:true
                                }
                    })}
                    else{
                        const tipoProyecto = tiposProyecto.find(tp => `${tp.id}` === `${currentProyecto.id_tipo_proyecto}`);
                        const valoradosActuales = valorados;
                        
                        // Mapear sobre los nuevos valorados, copiando los datos de los existentes si hay coincidencias de id
                        valoradosLista = actualizarValoradosDelProyecto(tipoProyecto, valoradosActuales)
                            
                    }
                    setFormDetalles({title:`Editar proyecto (${getEstadoProyecto(currentProyecto.estado).titulo})`,headerColor:"#255957", buttonText:"Actualizar" })
            } 
            console.log("proyecto.tipo_lote",proyecto.tipo_lote)
         
            let tieneUnidades=false
            let esTipoLoteEspecial = false
            if(unidadesLista.length === 1 && unidadesLista[0].descripcion === currentProyecto.descripcion && unidadesLista[0].num_unidades===1) 
            {       setUnidadSelected({ 
                    id_categoria: unidadesLista[0].tipologia.id_categoria,
                    id_area:unidadesLista[0].tipologia.id_area,
                    id_tipologia:unidadesLista[0].id_tipologia,
                    tipologia:unidadesLista[0].tipologia,
                    descripcion: "",
                    superficie: unidadesLista[0].superficie,
                    num_unidades: "1"
                })
            }else  if(unidadesLista.length > 0){
                tieneUnidades= true
            }else if(proyecto.tipo_lote){
                esTipoLoteEspecial=true
            }
            setIsCheckedEspecial(esTipoLoteEspecial)
            setRequiereUnidades(tieneUnidades)
            setKey("vistaGeneral")
            resetFormEdit()
            console.log("valoradosLista",valoradosLista)

            setDatos({...proyecto,
                        unidades:unidadesLista,
                        valorados:valoradosLista,
            })
            handleSelect("proyectista", proyecto.proyectista)

            if (currentProyecto.foto_plano && tipoProceso==="editar") {
                setFotoPreview(getUploadUrl(currentProyecto.foto_plano));
            } else {
                setFotoPreview(imgPlaceholder);
                resetFoto()
            }
        } else {
            resetForm();
        }}
    }, [ dispatch, currentProyecto, showModal]);

 

   
    const handleRequiereUnidadesChange = (e) => {
        setDatos(prevD =>({...prevD, ...initialDetalles, unidades:[]}))
        const requiere = e.target.checked
        console.log("requiere",requiere)
        if(requiere === true){
            setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"}));
        setRequiereCalcular(false)
         } // Mantener valores
        else{handleAddUniqueUnidad();}

        setRequiereUnidades(requiere);
    };


    const handleCheckboxChange = (e) => {
        setDatos(prevD =>({...prevD, ...initialDetalles}))
        setIsCheckedEspecial(e.target.checked);
    };


    const showAdvertencia = async(estado) =>{
        const pagoRegistrado = tipoPago==="C" || totalDepositado.pagado>0
            let mensajeAlerta = `El proyecto se guardará como <strong>${getEstadoProyecto(estado).titulo}</strong>`
            if(estado!=="visado" && pagoRegistrado) {
                mensajeAlerta= "No se guardará el pago registrado.<br>"+mensajeAlerta
            }else if(estado === "visado"){
                mensajeAlerta= "Esto es irreversible.<br>"+mensajeAlerta;
                mensajeAlerta+= parseFloat(totalDepositado.porPagar)>0 ? ` y se generará un <strong>recibo</strong> por el pago realizado de <strong>${formatNumber(totalDepositado.pagado)} Bs</strong>`:""
            }
            mensajeAlerta+="."
    
            const result = estado==="borrador" && !pagoRegistrado? {isConfirmed:true} :
                        await Swal.fire({
                            title: '¿Estás seguro?',
                            html: mensajeAlerta, // Usa HTML aq
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#464646',
                            confirmButtonText: 'Sí, continuar',
                            cancelButtonText: 'Cancelar'
                            })
        return result
    }
    const handleSubmit = async(estado) => {
        const valoradosLlenados=datos.valorados.every(valorado => valorado.activo? valorado.nrovalorado !== "" : true)

        let nuevosErrores = {
            id_tipo_proyecto: datos.id_tipo_proyecto === '',
            descripcion: datos.descripcion === '',
            proyectista: datos.id_proyectista === "",
            inmueble: datos.id_inmueble === "",
            propietario: datos.id_propietario === "",
            valorados:!valoradosLlenados,
            recalcularHonorarios: tipoChanged,
            calcularHonorarios: requiereCalcular,
            tipoLote:isCheckedEspecial && !datos.tipo_lote
        };

        if(estado!=="borrador") {
            let supTot = parseFloat(datos.superficie_total)
            nuevosErrores = {...nuevosErrores, 
                    proyectista: nuevosErrores.proyectista || verificaciones.proyectistaAportes === "con_pendientes",
                    unidades: requiereUnidades && datos.unidades.length === 0,
                    plano: fotoPlano && !isFotoValid || ( !fotoPlano && !datos.foto_plano),
                    superficie: !isCheckedEspecial? isNaN(supTot) || supTot === 0 : false

                }
        }
        if(estado === "visado") {
            const coincidenMontos =parseFloat(totalDepositado.porPagar) === parseFloat(totalDepositado.pagado)
            nuevosErrores = {...nuevosErrores, 
                    totalDepositado:!coincidenMontos,
                    depositos :parseFloat(totalDepositado.porPagar)>0 &&  tipoPago==="B" && !depositos.every(deposito => deposito.id_banco && deposito.monto && deposito.fecha_deposito && deposito.docum && deposito.archivo),
                }
        }
        setErrors(nuevosErrores);
      

        // console.log("antes de enviar", nuevosErrores, isModified)
        // Si todos los campos están completos, enviar el formulario
        if(isModified || estado != datos.estado || fotoPlano)
        {  
            console.log("Nuevos errores", nuevosErrores)
            if (!Object.values(nuevosErrores).includes(true)) {
            setError('');
            const result = await showAdvertencia(estado)
            if (result.isConfirmed) {
            const {unidades, propietario, proyectista, 
                inmueble, tipo, createdAt, 
                updatedAt, ...proyectoData} = datos
            const unidadesData = unidades.map(unidad=>{
                const {tipologia, id_categoria, id_area, ...unidadData} = unidad
                return unidadData
            })
            const formData = new FormData();
            for (const key in proyectoData) {
                // console.log("key "+key, proyectoData[key])
                if (typeof proyectoData[key] === 'object' && proyectoData[key] !== null) {
                    // Convertir arrays u objetos en JSON si es necesario
                    formData.append(key, JSON.stringify(proyectoData[key]));
                } else {
                    formData.append(key, proyectoData[key]);
                }
                    // console.log("formData "+key,formData)
            }
            const fechaActual =  fechaActualBolivia() 

            if(rolActivo?.alias !== "gerente" && estado === "revision") {
                formData.set("fecha_registro",fechaActual);
            }
            if(rolActivo?.alias === "gerente" && ((estado === "visado" && datos.estado !=="aprobado" ) || estado === "aprobado")) {
                if(datos.estado === "borrador") {
                    formData.set("fecha_registro",fechaActual);
                }
                formData.set("fecha_aprobacion", fechaActual);
            }

            if (fotoPlano) {
                formData.append("foto_plano", fotoPlano);
            }
            formData.set("estado", estado);
           if(estado === "visado"){ 
            formData.set("montoAPagar", totalDepositado.porPagar)
            formData.set("montoPagado", totalDepositado.pagado)
            formData.set("tipoPago", tipoPago);}
          // Agregar datos específicos si es necesario
            if (Array.isArray(unidadesData)) {
                if(unidadesData.length === 1 && !unidadesData[0].descripcion)
                { unidadesData[0].descripcion = proyectoData.descripcion }
                formData.append("unidades", JSON.stringify(unidadesData));
            }
            let concepto;
            if (depositos.length > 0 && depositos.some(deposito => deposito.monto)) {
                concepto = "Pago visado " + depositos.map(deposito => {
                  const banco = bancos.find(b => b.id === Number(deposito.id_banco)); // Convertimos id_banco a número
                   // console.log("banco=",banco," deposito=",deposito)
                    const siglaBanco = banco ? banco.sigla : 'Sin sigla';
                    const cuentaBancaria = banco ? banco.cuenta_bancaria : 'Sin cuenta';
                    return `${siglaBanco} (${cuentaBancaria}): Bs. ${parseFloat(deposito.monto).toFixed(2)} Trans.: ${deposito.docum}`;
                }).join(' | ');
            } else {
                concepto = 'Pago de visado en efectivo';
            }
           if( tipoPago === "B"){ 
            formData.append('depositos', JSON.stringify(depositos));
            // Agregar los archivos de los depósitos
            depositos.forEach((deposito, index) => {
              if (deposito.archivo) {
                formData.append(`depositos[${index}][archivo]`, deposito.archivo);
              }
            });
        }
        if (currentProyecto && tipoProceso==="editar") {
                dispatch(modifyProyecto({ id: currentProyecto.id, proyecto: formData })).then(response => {
                    console.log("REsponse ", response)
                    if (response.error || response.payload.messages) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.error || response.payload.messages[0] || 'Error al actualizar el proyecto.',
                        });
                    } else {
                        if(estado === "visado") { 
                        if(response.payload.recibo){
                            console.log("rresponse.payload.recibo ", response.payload.recibo)
                            handleViewRecibo({recibos:[response.payload.recibo]});
                            setPaymentSaved(true);}
                        }   
                        handleClose();
                    }
                });
            } else {
                dispatch(addProyecto(formData)).then(response => {
                    console.log("response",response)
                    if(response.payload?.error || response.payload?.messages){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.payload.messages[0] || 'Error al crear el proyecto.',
                        });
                       }
                      else {
                        if(estado === "visado") { 
                            if(response.payload.recibo){
                                console.log("rresponse.payload.recibo ", response.payload.recibo)
                                handleViewRecibo({recibos:[response.payload.recibo]});
                            } 
                        } else {
                        Swal.fire('Creado', 'Se ha creado correctamente el proyecto.', 'success');   
                        }handleClose();
               
                }}).catch((error) => {
                    console.log("error",error)
                    Swal.fire('Error', error.error || 'Ocurrió un error al crear el proyecto.', 'error');
                });;
            }
        }     
    } else {
        // Acción cancelada
        
        console.log('nuevosErrores',nuevosErrores);
      }
    }else {
            handleClose();
        }
    
    };

      const validarCondicionesParaProforma = () => {
        const montoExistente = totalDepositado.porPagar > 0 && datos.tipo && !requiereCalcular && !tipoChanged
        setBotonProformaHabilitado(montoExistente && !paymentSaved); 
      };
    
    const toggleImageVisibility = (index) => {
        const newVisibility = [...imagenVisible];
        newVisibility[index] = !newVisibility[index];
        setImagenVisible(newVisibility.map((visible, i) => (i === index ? visible : false)));
    
        if (newVisibility[index] && depositos[index].archivo && depositos[index].archivo.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => setImagenPreview(e.target.result);
          reader.readAsDataURL(depositos[index].archivo);
        } else {
          setImagenPreview(null);
        }
      };
    const resetForm = () => {
        setDatos(initialState);
        setRequiereUnidades(false)
        setRequiereCalcular(false)
        setUnidadSelected(initialUnidad);
        setEditIndex(null)
        setIsModified(false);
        setTipoChanged(false)
        setFormDetalles(initialFormDetalles);
        setVerificaciones(verificacionesInitialForm)
        setFotoPreview(imgPlaceholder);
        resetFoto()
        setKey("proyectista")
        resetFormEdit()
    };

    const resetFormEdit = () => {
        resetErrors()
        setTipoPago("B")
        setDepositos(initialStateDepositos)
        setPaymentSaved(false)
    };

    const resetErrors = () => {
        setError('');
        setVerificaciones(verificacionesInitialForm)
        setErrors(initialErrors)
        setErrorUnidadForm("")
    };
    
  const handleProforma = () => {
     const totalGeneral = parseFloat(totalDepositado.porPagar)

     const calculoPorFormula = !esLote || datos.superficie_total>0 ? [ `Superficie total: ${formatNumber(datos.superficie_total)} m2`,
        `Honorarios calculados: ${formatNumber(datos.honorarios_total)} Bs.`]:[]

        const titulo = datos.observacion?.toLowerCase().includes("resellado")  ?['PROFORMA PAGO DE RESELLADO']
        :['PROFORMA PAGO DE VISADO']
    const detalle = datos.proyectista? [
      ...titulo,
      '=======================',
      `Afiliado: ${fullNamePersona(datos.proyectista.persona)}`,
      `C.I.: ${datos.proyectista.persona.ci}`,
      `Nro. Reg.: ${datos.proyectista.num_reg}`,
      '--------------------------------------',
      `Tipo de proyecto: ${datos.tipo.nombre}`,
      `Descripción: ${datos.descripcion}`,
     ...calculoPorFormula,
      `Comisión C.A.T.: ${formatNumber(datos.comision_cat)} Bs.`,
      '--------------------------------------',
      `TOTAL A PAGAR = ${formatNumber(totalGeneral)} Bs.`,
    ]: ['PROFORMA PAGO DE VISADO'];

    setProformaContent({ detalle });
    setShowEpsonPrintModal(true); 
  };


const captureImage = () => {
    if (!nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    const blob = dataURLtoBlob(dataUrl);

    const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });

    if (depositIndex !== null) {
      handleFileDepositoChange(depositIndex, file); // Actualiza la fila correcta
    }

        setShowCameraModal(false);
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };
    const handleChangeTipoPago=(e)=>{
        const nuevoTipoPago = e.target? (e.target.checked? "C": "B") : e 
      
        setTipoPago((prevTipo) =>{
            setDepositos((prevDep => prevTipo !== nuevoTipoPago? initialStateDepositos : prevDep))
            return nuevoTipoPago
        })
    }
  const handleAddDeposito = async() => {
    const lastDeposito = depositos[depositos.length - 1];
    if (lastDeposito.id_banco && lastDeposito.monto && lastDeposito.fecha_deposito && lastDeposito.docum && lastDeposito.archivo) {
      setDepositos(prevDepositos=> ([...prevDepositos, { id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]));
    } else {
      await Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
    }
  };

  const handleRemoveDeposito = (index) => {
    setDepositos(prevDepositos => prevDepositos.filter((_, i) => i !== index))
  };

  const handleChangeDeposito = async(index, field, value) => {
    const newDepositos = [...depositos];
    if (field === 'fecha_deposito' && moment(value).isAfter(moment())) {
      await Swal.fire('Error', 'La fecha de depósito no puede ser posterior a la fecha actual.', 'error');
      return;
    }
    newDepositos[index][field] = value;
    setDepositos(newDepositos);
  };
  const handleFileDepositoChange = (index, file) => {
    const newDepositos = [...depositos];
    newDepositos[index].archivo = file;
    setDepositos(newDepositos);
  };

  
  const startCamera = (index) => {
    console.log("startCamera",datos)
    const iniciales = datos.proyectista? `${datos.proyectista.persona.ci}`:"sinproyectista";
    const randomNum = Math.floor(Math.random() * 1000) + 1;

    console.log("iniciales",iniciales)
    console.log("randomNum",randomNum, index)
    setNombreArchivo(`${iniciales}-${randomNum}.jpg`);
    setDepositIndex(index); // Guarda el índice actual
    setShowCameraModal(true);

    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }).catch((err) => {
      console.error("Error al acceder a la cámara: ", err);
    });
  };
  const handleTipoLoteChange = (name, value) => {
        if(esLote)
        {   
            if( name==="tipo_lote") {
            const tipoLote = tiposEspecialesLote.find(t => t.alias === value)
            const precioLote = tipoLote?.precio || null;
            setDatos((prevDatos) => ({ ...prevDatos, 
                tipo_lote: value, 
                superficie_total:0,
                honorarios_total:0,
                honorarios_total:0,
                descripcion:tipoLote.nombre,
                comision_cat:precioLote}));
             
            }else 
            if( name==="superficie_total"){
                const selectedCategoria = categorias.find(cat => `${cat.num}` === `6`);
                const indiceCalculo = obtenerIndiceCalculo(value, selectedCategoria);
               
                console.log("indiceCalculo", indiceCalculo)
                const honorariosTotal = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, 1 , value);
               const superficieTotal = parseFloat(value)
               const comision = redondearComision(honorariosTotal*porcentaje_comision)
               setDatos((prevDatos)=>{
                    return({...prevDatos, honorarios_total:honorariosTotal,
                                           comision_cat:comision,
                                           comisionCalculada:comision,
                                           superficie_total:superficieTotal,
                                  
               })})

            }
            setIsModified(true);
            setErrors({ ...errors, tipoLote: false});
        }
  
};
    const actualizarValoradosDelProyecto =(nuevoTipoProyecto, valoradosActuales) =>{
        const nuevosValorados = nuevoTipoProyecto && nuevoTipoProyecto.valorados.length > 0 ? nuevoTipoProyecto.valorados : [];
         
        const valoradosToAdd = nuevosValorados.filter(nuevoValorado => {
                 
            const valoradoExistente = valoradosActuales.find(val => val.id_valorado === nuevoValorado.id);
            return !valoradoExistente
        }).map(newVal => ({id_valorado:newVal.id, descripcion:newVal.descripcion,
                            id_proyecto:datos.id, nrovalorado:"", activo:true}));
        const valoradosUpdated = valoradosActuales.map(valoradoActual => {
            const existeEnNuevos = nuevosValorados.some(nuevoValorado => nuevoValorado.id === valoradoActual.id_valorado);
             return existeEnNuevos ?  { ...valoradoActual, activo: true } : { ...valoradoActual, activo: false };
        });

        return [...valoradosUpdated, ...valoradosToAdd]
    }

      const handleInputChange = (name, value) => {

        if (name === "id_tipo_proyecto" && value !== datos.id_tipo_proyecto) {
            //Si cambia el tipo de proyecto...
            const tipoProyecto = tiposProyecto.find(tp => `${tp.id}` === `${value}`);

            setDatos((prevDatos) => {
                const valoradosActuales = prevDatos.valorados;
                // Mapear sobre los nuevos valorados, copiando los datos de los existentes si hay coincidencias de id
                const valoradosActualizados = actualizarValoradosDelProyecto(tipoProyecto, valoradosActuales)
                
                let datosBasicos;
                let unidades=prevDatos.unidades;
                if(prevDatos.tipo?.alias === "lote" || tipoProyecto?.alias === "lote") 
                  { datosBasicos=initialDetalles 
                    unidades=[]
                    setUnidadSelected(initialUnidad)
                    setRequiereCalcular(false)
                    setIsCheckedEspecial(false)}
                else {datosBasicos={}}
                
                if (unidades.length> 0) setTipoChanged(true)
                return { ...prevDatos, ...datosBasicos,unidades,valorados: valoradosActualizados, [name]: value, tipo:tipoProyecto };
            });
        
        }
        else{
            setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        }
        setIsModified(true);
        setErrors({ ...errors, [name]: value === '' });
        setErrorUnidadForm("")
    };
    
    const handleChangeValorado = (index, value) => {
        setDatos(prevDatos => {
            const valorados = prevDatos.valorados.map((val) =>
                val.id_valorado === index ? { ...val, nrovalorado: value } : val
            );
            return { ...prevDatos, valorados }; // Actualiza el estado con la nueva copia de 'valorados'
        });
        setErrors(prev => ({...prev, valorados:false }))
        setIsModified(true);
    };
console.log("Proyecto proy", datos)
    const handleSelect = (name, object) => {
    //Para manejar la seleccion de proyectista, inmueble o propietario
        const objetoId=object? object.id : ""
        if(name === 'proyectista') {
            if(objetoId !== ""){
            dispatch(fetchCuotasByAfiliado(objetoId));
            }else{
                setVerificaciones((prev)=> ({...prev, proyectistaAportes:null})) 
            }
           }
        setIsModified(true);
        setDatos((prevDatos) => ({ ...prevDatos, [`id_${name}`]: objetoId, [name]:object }));
        if(object) setErrors((prev)=>({...prev, [name]:false}));
        
    };


    const esUnidadValida = () => {
       if(unidadSelected.id_tipologia === "") return "Debe seleccionar una tipología.";
       if(unidadSelected.descripcion === "") return "Debe indicar una descripción para la unidad.";
       if(parseInt(unidadSelected.num_unidades) <= 0) return "La cantidad de unidades debe ser un número mayor a 0.";
       if(parseInt(unidadSelected.superficie) <= 0 || unidadSelected.superficie === "") return "La superficie debe ser mayor a 0.";
       return "";
    };
    
    const handleAddUniqueUnidad = () => {
        console.log("handleAddUniqueUnidad, unidad seletec",unidadSelected,parseInt(unidadSelected.superficie)>=0)
        let mensajeError
        if(unidadSelected.id_tipologia === "") mensajeError = "Debe seleccionar una tipología.";
        if(parseInt(unidadSelected.superficie) <= 0 || unidadSelected.superficie === "") mensajeError = "La superficie debe ser mayor a 0.";
   
        if(mensajeError && mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
      
        console.log("en handleAddUniqueUnidad selectedTipologia",selectedTipologia,selectedCategoria,indiceCalculo)
        if(!indiceCalculo) setErrorUnidadForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida.")
        else {
        setErrorUnidadForm("")
        setErrors({ ...errors, unidades: false });
        const honorariosTotal = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
        const superficieTotal = parseFloat(unidadSelected.superficie)
        const comision = redondearComision(honorariosTotal*porcentaje_comision)
        setDatos((prevDatos)=>{
            const nuevaListaUnidades = [{...unidadSelected, indice_calculo:indiceCalculo, honorarios:honorariosTotal,tipologia:selectedTipologia}]
            return({...prevDatos, honorarios_total:honorariosTotal,
                                    comision_cat:comision,
                                    comisionCalculada:comision,
                                    superficie_total:superficieTotal,
                                    unidades:nuevaListaUnidades
        })})
        
        setIsModified(true);  
        setRequiereCalcular(false) 
        setErrors({ ...errors, calcularHonorarios: false });
        }
   } };
    const handleAddUnidad = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
      
        if(!indiceCalculo) setErrorUnidadForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida.")
        {setErrorUnidadForm("")
        setErrors({ ...errors, unidades: false });
        const honorariosUnidad = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
        const honorariosTotal = parseFloat(datos.honorarios_total)+honorariosUnidad
        const superficieTotal = (parseFloat(datos.superficie_total)  || 0) + (parseFloat(unidadSelected.superficie)*unidadSelected.num_unidades)
        const comision = redondearComision(honorariosTotal*porcentaje_comision)
        setDatos((prevDatos)=>{
            const nuevaListaUnidades = [...prevDatos.unidades, {...unidadSelected, indice_calculo:indiceCalculo, honorarios:honorariosUnidad,tipologia:selectedTipologia}]
            return({...prevDatos, honorarios_total:honorariosTotal,
                                    comision_cat:comision,
                                    comisionCalculada:comision,
                                    superficie_total:superficieTotal,
                                    unidades:nuevaListaUnidades
        })})
        
        setIsModified(true);
        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        }
   } };

   const obtenerIndiceCalculo  = (superficie, categoria) => {
    console.log("Categoria", categoria)
     const indices = categoria.indicesCalculo

    const indiceEncontrado = indices.find(indice => {
        // Verificar si la superficie está dentro del rango del índice
        return superficie >= indice.superficie_min &&
               (indice.superficie_max === null || superficie <= indice.superficie_max);
    });

    // Devolver el índice de cálculo encontrado o un valor por defecto si no lo encuentra
    return indiceEncontrado ? indiceEncontrado.indice_calculo : 0;
};
const recalcularHonorariosTotal = () => {
    let totalHonorarios = 0;
    let totalSuperficie = 0;

    // Luego actualiza 'datos' basándose en 'total'
    setDatos(prevDatos => {
        const unidadesRecalculadas = prevDatos.unidades.map(unidad => {
            const honorariosUnidad = calculoHonorariosUnidad(
                unidad.indice_calculo,
                unidad.tipologia.categoria,
                unidad.num_unidades,
                unidad.superficie
            );
            totalHonorarios += honorariosUnidad;
            totalSuperficie += unidad.superficie
            return {
                ...unidad, 
                honorarios: honorariosUnidad
            };
        });
        const comisionCalculada =redondearComision(totalHonorarios * porcentaje_comision)
        return {
            ...prevDatos,
            superficie_total:totalSuperficie,
            honorarios_total: totalHonorarios,
            comisionCalculada:comisionCalculada,
            comision_cat: comisionCalculada,
            unidades:unidadesRecalculadas
        };
    });
    setErrors({ ...errors, recalcularHonorarios: false });
    setTipoChanged(null)
};

const calculoHonorariosUnidad  = (indiceCalculo, categoria, unidades_repetidas, superficie ) => {
    let total = 0;
    const tipoProyecto = datos.id_tipo_proyecto ? tiposProyecto.find(t=> `${t.id}` === `${datos.id_tipo_proyecto}`):null
    console.log("tipoProyecto",tipoProyecto)
    const adicionAlCostoMes =  tipoProyecto?.adicion_costo_mes || 0
    const extra =  categoria.costo_mes * adicionAlCostoMes
    const honorarioPrimeraUnidad = (parseFloat(superficie) * parseFloat(indiceCalculo) * (parseFloat(categoria.costo_mes) + parseFloat(extra)))
    console.log("honorarioPrimeraUnidad",parseFloat(superficie),parseFloat(indiceCalculo),(parseFloat(categoria.costo_mes) + parseFloat(extra)), honorarioPrimeraUnidad)
    for (let u = 1; u <= unidades_repetidas; u++) {
        const honorario = honorariosPorUnidadRepetida.find(h=> h.num_unidad_desde <=u && h.num_unidad_hasta >=u)
        // console.log(`Costo mensual: ${categoria.costo_mes}, Extra: ${extra}`); // Verifica si los valores son correctos
        const subtotal = honorarioPrimeraUnidad * (honorario.porcentaje / 100);
        total += subtotal; // Sumar el subtotal al total
        // console.log("Unidad "+u+": subtotal: ",subtotal)
        // console.log("Datos de calculo ",superficie,indiceCalculo,parseFloat(categoria.costo_mes) + parseFloat(extra),honorario.porcentaje / 100)
    }
    return total
    }

    const handleEditUnidad = (index) => {
        setUnidadSelected(datos.unidades[index]);
        setEditIndex(index);
    };

    const handleSaveEdit = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
     
        if(!indiceCalculo) setErrorUnidadForm("Ingrese una superficie válida.")
        {
        const honorariosUnidad = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
        setIsModified(true);
        setDatos((prevDatos)=>{
            const updatedUnidades = [...prevDatos.unidades]; // Hacer una copia del array
            const prevUnidad = updatedUnidades[editIndex]
            updatedUnidades[editIndex] = { ...unidadSelected, indice_calculo:indiceCalculo, honorarios:honorariosUnidad,tipologia: selectedTipologia }; // Modificar el elemento específico
            // console.log("Honorarios a editar",prevDatos.honorarios_total,prevUnidad.honorarios,honorariosUnidad)
            const honorariosTotal = parseFloat(prevDatos.honorarios_total) - parseFloat(prevUnidad.honorarios) + honorariosUnidad
            const superficieTotal = parseFloat(prevDatos.superficie_total) - (parseFloat(prevUnidad.superficie)*prevUnidad.num_unidades) + (parseFloat(unidadSelected.superficie)*unidadSelected.num_unidades)
            const comisionCalculada= redondearComision(honorariosTotal*porcentaje_comision)
            return({...prevDatos, honorarios_total:honorariosTotal,
                                    comision_cat:comisionCalculada,
                                    comisionCalculada:comisionCalculada,
                                    superficie_total:superficieTotal,
                                    unidades:updatedUnidades
        })})
        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        setEditIndex(null);} // Limpiar la edición    
        setErrorUnidadForm("")
    }     
  
    };
    const redondearComision = (comision) => {
        return Math.ceil(comision);
    };
    const handleDeleteUnidad = (index) => {
        setDatos((prevDatos) => {
            const updatedUnidades = prevDatos.unidades.filter((_, i) => i !== index); // Eliminar la unidad en el índice específico
            
            // Obtener los valores de la unidad que se eliminará
            const unidadEliminada = prevDatos.unidades[index];
            const honorariosEliminados = unidadEliminada.honorarios || 0;
            const superficieEliminada = unidadEliminada.superficie || 0;
            
            // Actualizar el total de honorarios y superficie
            const honorariosTotal = prevDatos.honorarios_total - honorariosEliminados;
            const superficieTotal = parseFloat(prevDatos.superficie_total) - (parseFloat(superficieEliminada)*unidadEliminada.num_unidades);
            const comisionCalculada=redondearComision(honorariosTotal * porcentaje_comision)
            return {
                ...prevDatos,
                honorarios_total: honorariosTotal,
                comision_cat: comisionCalculada,
                comisionCalculada:comisionCalculada,
                superficie_total: superficieTotal,
                unidades: updatedUnidades
            };
        });
    };

    const handleFormat = e => {
        const { name, value } = e.target;
        const valueFormatted = name === 'superficie' || name === 'indice_calculo' ? formatearDecimal(value) : value 
        setUnidadSelected((prevDatos) => ({ ...prevDatos, 
            [name]: valueFormatted
        }));
    };

    const handlePlanoChange = (e) => {
        handleFotoChange(e.target.files[0]); 
        setIsModified(true);
        setErrors((prev)=>({...prev, plano:false}));
    }
  
    const areasFiltered = tipologias.filter(tipologia => `${tipologia.id_categoria}` === `${unidadSelected.id_categoria}`) // Filtra por categoría
                    .map(tipologia => tipologia.area); // Extrae el rubro

    // Si necesitas eliminar rubros duplicadas
    const uniqueAreas = areasFiltered.filter((area, index, self) =>
                    index === self.findIndex(a => a.id === area.id) // Compara el 'id' de cada rubro
                );
      
    const tipologiasFiltered = tipologias.filter(t=> (unidadSelected.id_categoria? `${t.id_categoria}`=== `${unidadSelected.id_categoria}` : true) &&
                                                    (unidadSelected.id_area? `${t.id_area}`=== `${unidadSelected.id_area}` : true))
    const listoParaRevision = datos.id_proyectista && verificaciones.proyectistaAportes === "sin_pendientes" 
                                && datos.id_inmueble && datos.id_propietario
    const erroreDatosProyecto = errors.id_tipo_proyecto || errors.descripcion || errors.unidades || errors.recalcularHonorarios || errors.calcularHonorarios || errors.superficie
  
    const permitirPago = currentProyecto?.estado ==="aprobado" || rolActivo?.alias ==="gerente" 
   
    const permitirCambios = !currentProyecto || currentProyecto?.estado === "borrador" || (currentProyecto?.estado === "revision" && rolActivo?.alias === "gerente")
    
    const valoradosProyecto = datos.valorados.filter(val=>val.activo && val.activo===true)
    return (
        <>
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form className='normal-form'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    justify
                    >
                <Tab 
                        eventKey="proyectista" 
                        title={
                            <>
                             {errors.proyectista ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Involucrados  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Involucrados</>
                                )}
                            </>
                        }
                    style={{padding:"0px 30px"}}
                    >
                        <Row>
                            <Col>
                            <SelectInmueble labelText="Inmueble" onSelect={handleSelect} 
                                        inmuebleSelected={datos.inmueble} 
                                        permitirEdicion={!(tipoProceso==="resellar" || tipoProceso==="editar")&& permitirCambios}
                                       />
                            </Col>
                            <Col>
                            <SelectPersona labelText="Propietario" onSelect={handleSelect} 
                                        personaSelected={datos.propietario} simplePersona={true}
                                        permitirEdicion={!(tipoProceso==="resellar" || tipoProceso==="editar") && permitirCambios}
                                     />
                            </Col>
                        </Row>
                       
                        <Row>
                            <Col>
                            <SelectAfiliado onSelect={handleSelect} afiliado={datos.proyectista} labelText='Proyectista'
                             permitirEdicion={!(tipoProceso==="resellar" || tipoProceso==="editar") && permitirCambios}/>
                            
                            </Col>
                        </Row>
                      
                       {verificaciones.proyectistaAportes && 
                       <>
                       {verificaciones.proyectistaAportes === "sin_pendientes"? <div className="alert alert-success" role="alert">
                        El afiliado está al día con sus aportes
                        </div>
                        : <>
                          <div className="alert alert-danger" role="alert">
                        El afiliado tiene aportes pendientes
                        </div>
                        <div className="mt-3">
                            <Form.Label>Cuotas Pendientes</Form.Label>
                            <Table bordered hover size="sm">
                                <thead style={{ backgroundColor: '#b2aea3' }}>
                                <tr>
                                    <th>Cuota</th>
                                    <th>Monto CAT</th>
                                    <th>Monto CAB</th>
                                    <th>Total</th>
                                    <th>Estado</th>
                                </tr>
                                </thead>
                                <tbody>
                                {cuotas.length === 0 ? (
                                    <tr>
                                    <td colSpan="6">No hay cuotas pendientes</td>
                                    </tr>
                                ) : (
                                    cuotas.map((cuota, index) => (
                                    <tr key={cuota.id}>
                                        <td>{cuota.cuota}</td>
                                        <td >
                                        {parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.
                                        </td>
                                        <td>
                                        {parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.
                                        </td>
                                        <td >
                                        {(parseFloat(cuota.monto_cat || 0) + parseFloat(cuota.monto_cab || 0)).toFixed(2)} Bs.
                                        </td>
                                        <td>{cuota.estado === 'pagada' ? 'Pagada' : 'No Pagada'}</td>
                                    </tr>
                                    ))
                                )}
                                </tbody>
                            </Table>
                            </div>
                        </>}
                       </>
                        }
                    </Tab>
                 {permitirCambios &&  <Tab eventKey="datos_proyecto" title={
                            <>
                             {erroreDatosProyecto ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Detalles  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Detalles</>
                                )}
                            </>
                        }
                        style={{padding:"0px 20px"}}>

                       <TabDatosProyecto 
                                datos={datos}
                                errors={errors}
                                tiposProyecto={tiposProyecto}
                                categorias={categorias}
                                uniqueAreas={uniqueAreas}
                                tipologiasFiltered={tipologiasFiltered}
                                unidadSelected={unidadSelected}
                                editIndex={editIndex}
                                errorUnidadForm={errorUnidadForm}
                                handleInputChange={handleInputChange}
                                setUnidadSelected={setUnidadSelected}
                                handleAddUnidad={handleAddUnidad}
                                handleAddUniqueUnidad={handleAddUniqueUnidad}
                                handleEditUnidad={handleEditUnidad}
                                handleSaveEdit={handleSaveEdit}
                                handleDeleteUnidad={handleDeleteUnidad}
                                recalcularHonorariosTotal={recalcularHonorariosTotal}
                                formatNumber={formatNumber}
                                handleFormat={handleFormat}
                                tipoChanged={tipoChanged}
                                requiereCalcular={requiereCalcular}
                                setRequiereCalcular={setRequiereCalcular}
                                requiereUnidades={requiereUnidades}
                                esLote={esLote}
                                handleCheckboxChange={handleCheckboxChange}
                                isCheckedEspecial={isCheckedEspecial}
                                handleTipoLoteChange={handleTipoLoteChange}
                                tiposEspecialesVisado={tiposEspecialesLote}
                                handleRequiereUnidades={handleRequiereUnidadesChange}
                                tipoProceso={tipoProceso}
                                permitirCambios={permitirCambios}
                                />
                    </Tab>}
             
                   {permitirCambios && <Tab 
                        eventKey="Documentos" 
                        title={
                            <>
                            {(errors.documentos || errors.valorados || errors.plano) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Respaldos  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Respaldos</>
                                )}
                               
                            </>
                        }
                        style={{padding:"0px 15px"}}
                    >
                           <Row xs={1} md={1} lg={2}>
             
             <Col className='col-form'>
                 <Form.Label>PLANO</Form.Label>
                 <Form.Control
                     type="file"
                     name="foto_plano"
                     isInvalid={!isFotoValid}
                     onChange={handlePlanoChange}
                     disabled={!permitirCambios}
                     
                 />
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen válida.
                  </Form.Control.Feedback>
                  </Col>
                  <Col className='col-form'>
                 <div className="d-flex justify-content-center mb-4">
                     <div className="text-center">
                         {fotoPreview && (
                             <div>
                                 <div>Foto del plano</div>
                                 <div className="position-relative">
                                 <Image
                                    src={!currentProyecto && fotoPlano && isFotoValid ? URL.createObjectURL(fotoPlano) : fotoPreview}
                                    alt="Foto previa"
                                    className={`img-fluid mb-2 mt-3 ${errors.plano ? 'border border-danger' : ''}`}
                                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                                />
                                   {errors.plano && (
                                <div className="invalid-feedback d-block">
                                    Debe adjuntar la foto del plano, para la aprobación del visado.
                                </div>
                            )}
                               </div>
                             </div>
                         )}
                     </div>
                     <div className="text-center">
                         {(currentProyecto && fotoPlano && isFotoValid) && (
                             <>
                                 <div>Nueva foto</div>
                                 <Image
                                     className="img-fluid mb-2 mt-3"
                                     style={{ maxHeight: '200px', objectFit: 'cover' }}
                                     src={URL.createObjectURL(fotoPlano)}
                                 />
                             </>
                         )}
                     </div>
                 </div>
                 </Col>
                  </Row>
                   {(!esLote && valoradosProyecto?.length >0) &&<> <Form.Label>VALORADOS</Form.Label>
                     <Row className={`${errors.valorados ? 'border-danger' : ''}`}>
                            {valoradosProyecto?.length>0 ? 
                            <>
                            {valoradosProyecto.map((val,index)=> (
                                <Col className="mb-3" key={`valorado_${index}`}>
                                <Form.Group>
                                    <Form.Label>{formatoOracion(val.descripcion)}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Número de ${val.descripcion.toLowerCase()}`}
                                        value={val.nrovalorado}
                                        onChange={(e)=>handleChangeValorado(val.id_valorado,e.target.value)}
                                        required
                                        disabled={!permitirCambios}
                                    />
                                </Form.Group>
                                </Col>
                            ))}
                            {errors.valorados && <div className="text-center text-danger">
                                    Todos los valorados utilizados en este proyecto deben estar registrados.
                            </div>}
                            </>: (
                                <div>
                                Debe seleccionar el tipo de proyecto para registrar los valorados correspondientes
                        </div>
                            )
                        }
                        </Row></>}
                    </Tab>}
                  {permitirPago &&  <Tab 
                        eventKey="Pago" 
                        title={
                            <>
                            {(errors.totalDepositado || errors.depositos) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Pago  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Pago</>
                                )}
                               
                            </>
                        }
                    >
                      <Row className={`${errors.depositos ? 'border-danger' : ''}`}>
                  
                      {/* Mostrar los recibos previos */}
                        {datos.recibos && datos.recibos.length > 0 && (
                            <div className="mb-3">
                                <h5>Pagos Realizados</h5>
                                <Card className="p-3">
                                    <Card.Body>
                                        {datos.recibos.map((recibo, index) => (
                                            <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                <div style={{ fontWeight: 'bold', color: '#495057' }}>N° recibo: {recibo.nroRecibo}</div>
                                                <div style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                                                    {formatNumber(recibo.monto)} Bs.
                                                </div>
                                            </div>
                                        ))}
                                    </Card.Body>
                                </Card>
                            </div>
                        )}

                    {tipoPago==="B"&&  <div className={`table-container ${errors.depositos ? 'border-danger' : ''}`}>
                            <h5>Registrar Depósitos</h5>
                            <Table bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Banco</th>
                                        <th>Monto</th>
                                        <th>Fecha Depósito</th>
                                        <th>Documento</th>
                                        <th>Archivo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {depositos.map((deposito, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Form.Control
                                                    as="select"
                                                    value={deposito.id_banco}
                                                    onChange={(e) => handleChangeDeposito(index, 'id_banco', e.target.value)}
                                                    disabled={paymentSaved}
                                                >
                                                    <option value="">Seleccione un banco</option>
                                                    {bancos.map((banco) => (
                                                        <option key={banco.id} value={banco.id}>
                                                            {banco.banco}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="number"
                                                    value={deposito.monto}
                                                    onChange={(e) => handleChangeDeposito(index, 'monto', e.target.value)}
                                                    required
                                                    disabled={paymentSaved}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="date"
                                                    value={deposito.fecha_deposito}
                                                    onChange={(e) => handleChangeDeposito(index, 'fecha_deposito', e.target.value)}
                                                    required
                                                    disabled={paymentSaved}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    value={deposito.docum}
                                                    onChange={(e) => handleChangeDeposito(index, 'docum', e.target.value)}
                                                    disabled={paymentSaved}
                                                />
                                            </td>
                                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                                <Form.Control
                                                    type="file"
                                                    onChange={(e) => handleFileDepositoChange(index, e.target.files[0])}
                                                    disabled={paymentSaved}
                                                />
                                                {deposito.archivo && (
                                                    <>
                                                        <span style={{ marginLeft: '10px' }}>{deposito.archivo.name}</span>
                                                        <button
                                                            className="icon-button"
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => toggleImageVisibility(index)}
                                                        >
                                                            {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                className="icon-button"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => startCamera(index)}
                                            >
                                                <BsCamera />
                                            </button>
                                            </td>
                                            <td>
                                                {!paymentSaved && index === depositos.length - 1 && (
                                                    <button className="icon-button" onClick={handleAddDeposito}>
                                                        <i className="bi bi-plus-circle"></i>
                                                    </button>
                                                )}
                                                {!paymentSaved && depositos.length > 1 && (
                                                    <button className="icon-button" onClick={() => handleRemoveDeposito(index)}>
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {errors.depositos && (
                            <p className="text-danger mt-2">
                                Todos los datos de los depósitos deben ser llenados, incluyendo el archivo de respaldo correspondiente a cada uno.
                            </p>
                        )}
                        </div>}
                        <Col className="soft-labels mt-3 mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Pago en efectivo"
                                    onChange={handleChangeTipoPago}
                                    checked={tipoPago==="C"}
                                />
                                
                            </Col>
                        <div>
                        {datos.observacion?.toLowerCase().includes("resellado") &&<>
                        <strong>Comisión Total C.A.T:</strong>{' '}
                            <span style={{ fontWeight: 'bold' }}>{formatNumber(datos.comision_cat)} Bs.</span>
                            <br></br>
                            <strong>Total pagos realizados:</strong>{' '}
                            <span style={{ fontWeight: 'bold' }}>{formatNumber(totalDepositado.pagadoAnteriormente)} Bs.</span>
                
                          <hr></hr>
                          </>}
                            <strong>Monto a pagar:</strong>{' '}
                            <span style={{ fontWeight: 'bold', marginRight:"30px" }}>{formatNumber(totalDepositado.porPagar)} Bs.</span>
                            {tipoPago === "B" && <button className="btn-sm btn-mine-tertiary"  onClick={handleProforma} disabled={!datos.comision_cat > 0 || !datos.proyectista}>
                                Proforma
                            </button>}
                            <br></br>
                            <strong>Total pagado ahora:</strong>{' '}
                            <span style={{ fontWeight: 'bold' }}>{formatNumber(totalDepositado.pagado)} Bs.</span>
                    
                            {errors.totalDepositado && (
                                <div className="invalid-feedback d-block">
                                    El monto "Total pagado" debe ser igual al "Monto a pagar" calculado.
                                </div>
                            )}
                        </div>
     
                    
                    </Row>

                   </Tab>}
                    <Tab eventKey="vistaGeneral" title="Vista general">
                        <VistaGeneralProyecto currentProyecto={datos} nuevaFotoPlano={fotoPlano} ver={false} />
                   </Tab>
                    </Tabs>
                    <div className="list-container-header mt-4">
                        <div className="left-buttons">
                        {!currentProyecto && <button className="button-mine btn-cancel-outline" type="button" onClick={resetForm}>
                            Limpiar
                        </button>}
                        <button className="button-mine btn-cancel-outline" type="button" onClick={handleClose}>
                            Cancelar
                        </button>
                        </div>
                    
                        <div className="right-buttons">
                          {tipoProceso!=="resellar" ? <>  {(!currentProyecto || tipoProceso==="duplicar" || (idUser===currentProyecto.id_usuario_creador && currentProyecto.estado === "borrador") )&& 
                            <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                                Pre-Guardar
                            </button>}
                            { rolActivo?.alias !== "gerente" && datos?.estado==="borrador" && 
                                <button className="button-mine btn-presuccess-outline" type="button" onClick={() => handleSubmit("revision")} disabled={!isModified}>
                                    <i className="bi bi-check"></i>  Guardar para Revisión
                                </button>}
                            {rolActivo?.alias === "gerente" && currentProyecto?.estado !=="aprobado" &&
                                <button className="button-mine btn-presuccess-outline" style={{border:"0px"}} type="button" onClick={() => handleSubmit("aprobado")} disabled={!isModified}>
                                    <i className="bi bi-check-circle"></i> Aprobar 
                                    {tipoProceso !== "resellar"? " Proyecto" : " Resellado"}
                                </button>
                            }
                            { (rolActivo?.alias === "gerente" || currentProyecto?.estado === "aprobado" )&& 
                                <button className="button-mine  btn-success-outline" type="button" onClick={() => handleSubmit("visado")} disabled={!isModified}>
                                    <i className="bi bi-check2-all"></i>  Visar {tipoProceso !== "resellar"? " Proyecto" : " Resellado"}
                                </button>
                            }
                            </> : 
                             <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                                Pre-Guardar Resellado
                            </button>
                            }
                        </div>
                    </div>
                </Form>
            </Modal.Body>
               
      {showEpsonPrintModal && (
        <EpsonPrintModal
          show={showEpsonPrintModal}
          handleClose={() => setShowEpsonPrintModal(false)}
          content={proformaContent} 
        />
      )}

      
      {showCameraModal && (
        <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Capturar Imagen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} style={{ width: '100%' }} />
            <Form.Group className="mt-3">
              <Form.Label>Nombre del Archivo</Form.Label>
              <Form.Control
                type="text"
                value={nombreArchivo}
                onChange={(e) => setNombreArchivo(e.target.value)}
                placeholder="Ingrese el nombre del archivo"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button className="button-mine" onClick={() => setShowCameraModal(false)}>
              Cerrar
            </button>
            <button className="button-mine" onClick={captureImage}>
              Capturar Imagen
            </button>
          </Modal.Footer>
        </Modal>
      )}
        </Modal>
     
      </>
    );
};

export default FormProyecto;
