import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const noticiaService = apiService('/noticias');

// Thunks para operaciones CRUD
export const fetchNoticias = createAsyncThunk(
  'noticia/fetchNoticias',
  async (_, { rejectWithValue }) => {
    try {
      const response = await noticiaService.getAll();
      return response || [];
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const addNoticia = createAsyncThunk(
  'noticia/addNoticia',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await noticiaService.createWithFormData(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const modifyNoticia = createAsyncThunk(
  'noticia/modifyNoticia',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await noticiaService.updateWithFormData(id, formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const removeNoticia = createAsyncThunk(
  'noticia/removeNoticia',
  async (id, { rejectWithValue }) => {
    try {
      await noticiaService.remove(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

// Estado inicial
const initialState = {
  noticias: [],
  current: null,
  status: 'idle',
  error: null,
};

// Slice
const noticiaSlice = createSlice({
  name: 'noticia',
  initialState,
  reducers: {
    setCurrentNoticia(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNoticias.fulfilled, (state, action) => {
        state.noticias = action.payload;
        state.status = 'idle';
      })
      .addCase(addNoticia.fulfilled, (state, action) => {
        state.noticias.push(action.payload);
        state.status = 'idle';
      })
      .addCase(modifyNoticia.fulfilled, (state, action) => {
        const index = state.noticias.findIndex(n => n.id === action.payload.id);
        if (index !== -1) {
          state.noticias[index] = action.payload;
        }
        state.status = 'idle';
      })
      .addCase(removeNoticia.fulfilled, (state, action) => {
        state.noticias = state.noticias.filter(n => n.id !== action.payload);
        state.status = 'idle';
      });
  }
});

export const { setCurrentNoticia } = noticiaSlice.actions;

export default noticiaSlice.reducer;
