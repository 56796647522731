import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import RolRol from './RolRol';
import * as XLSX from 'xlsx';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormRol from './FormRol';
import { fetchRoles, removeRol } from '../../reducers/rol_slice';
import CustomPrintModal from '../UI/CustomPrintModal';
import ActionButton from '../UI/Buttons/ActionButton';
import { InputGroup } from 'react-bootstrap';
import { getEstadoSimple } from '../../util/valueCalculator';
import Swal from 'sweetalert2';
import FormProcesosRol from './FormProcesosRol';
import { useNavigate } from 'react-router-dom';
//import './ListRoles.css';  // Importa tu archivo CSS

const ListRoles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roles = useSelector(state => state.rol.roles || []);

    const [showModal, setShowModal] = useState(false);
    const [showRolModal, setShowRolModal] = useState(false);
    const [currentRol, setCurrentRol] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key1: null,key2: null, direction: 'asc' });
    const [searchTerm, setSearchTerm] = useState('');
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });

    useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

 
    const handleDelete = id => {
        Swal.fire({
          title: '¿Estás seguro?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#464646',
          confirmButtonText: 'Sí, eliminarlo!',
           cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(removeRol(id)).unwrap().then(() => {
              Swal.fire('¡Eliminado!', 'El rol ha sido eliminado.', 'success');
            }).catch((error) => {

                console.log("error en delete", error);
                // Usar el mensaje específico del backend si está disponible
                const errorMessage = error?.messages?.[0] || 'Ocurrió un error al eliminar el rol';
            
                Swal.fire('Error', errorMessage, 'error');
            });
    }});
        
      };

    const handleEdit = rol => {
        setCurrentRol(rol);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentRol(null);
        setShowModal(true);
    };

    const handleManageRoles = rol => {
        setCurrentRol(rol);
        setShowRolModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentRol(null);
    };
 
    const handleCloseRolModal = () => {
        setShowRolModal(false);
        setCurrentRol(null);
        dispatch(fetchRoles());
    };
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
      
  const handleBack = () => {
    navigate(-1);
  };
  const sortedList = useMemo(() => {
      // FILTRADO
      let filteredRoles= roles.filter(rol => {
        const matchesSearch =`${rol.nombre} ${rol.descripcion}`.toLowerCase().includes(searchTerm.toLowerCase())
        return matchesSearch;
      });
      let sortedData = [...filteredRoles];

        if (sortConfig.key2) {
            sortedData.sort((a, b) => {
        let aValue, bValue;

        if (sortConfig.key1 !== "") {
            aValue = a[sortConfig.key1][sortConfig.key2];
            bValue = b[sortConfig.key1][sortConfig.key2];
        } else {
            aValue = a[sortConfig.key2];
            bValue = b[sortConfig.key2];
        }

        if (aValue === null ) return 1;
        if (bValue === null ) return -1;

        if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });
}

return sortedData;
}, [roles, sortConfig, searchTerm]);

    const handleShowPreview = () => {
        setPrintContent(getPreviewContent());
        setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
    };
    const handleSort = (key1, key2) => {
        if (sortConfig.direction === 'desc') {
          setSortConfig({ key1:null, key2: null, direction: 'asc' });
          return;
        }
        let direction = 'asc';
        if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
          direction = 'desc';
        } else if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
          direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
        }
        setSortConfig({key1, key2, direction });
      };
    //IMPRESION
    const getPreviewContent = () => {
        const content = {
        head: [['N°', 'Nombre', 'Descripción', 'Estado']],
        body: sortedList.map((rol, index) => [
            index+1,
            rol.nombre,
            rol.descripcion,
            getEstadoSimple(rol.estado_sql)|| 'N/A',
        ])
        };
        return content;
    };
    //EXCEL
    const handleExport = () => {
        const contentArrays = getPreviewContent()
        // Combinar las cabeceras y los datos
        const exportData = [...contentArrays.head, ...contentArrays.body];
        // Crear la hoja de trabajo con las cabeceras personalizadas
        const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Roles");
        // Exportar el archivo Excel
        XLSX.writeFile(workbook, "roles.xlsx");
    };

    return (
        <div className="list-container list0">
        <h1 className='table-title'>Lista de Roles</h1>
        <div className="list-content">
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent mr-3"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent mr-3"><i className="bi bi-file-spreadsheet"></i></button>
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Rol
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
            <InputGroup size="sm">
                <InputGroup.Text>
                <i className="bi bi-search"></i>
                </InputGroup.Text>
                <Form.Control
                    size='sm'
                    type="text"
                    placeholder="Busca por nombre o descripción"
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearch}
                    />
            </InputGroup>
            </div>
        <div className='table-container'>
                <Table striped bordered hover className="table-bordered">
                    <thead className="sticky-header1">
                        <tr className='text-center'>
                        <th className='col-cod'>N°</th>
                        <th onClick={() => handleSort('','nombre')}>
                            Nombre
                            {sortConfig.key2 === 'nombre' && (
                                sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                            )}
                            </th>
                        <th onClick={() => handleSort('','descripcion')}>
                            Descripción
                            {sortConfig.key2 === 'descripcion' && (
                            sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                            )}
                        </th>
                        <th>Estado</th>
                            <th className='col-actions3'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedList.map((rol, index) => (
                            <tr key={rol.id}>
                                <td className='text-center'>{index+1}</td>
                                <td>{rol.nombre}</td>
                                <td>{rol.descripcion}</td>
                                <td className='text-center'>{getEstadoSimple(rol.estado_sql)}</td>
                                
                                <td className='text-center'>
                                {rol?.editable && 
                                <><button className="icon-button btn-secondary-outline" title="Editar rol" onClick={() => handleEdit(rol)}>
                                    <i className="bi bi-pencil"></i>
                                </button>
                                <button className="icon-button btn-secondary-outline" title="Eliminar rol" onClick={() => handleDelete(rol.id)}>
                                    <i className="bi bi-trash"></i>
                                </button></>}
                                <button className="btn-sm btn-secondary-outline" title="Gestionar Procesos del rol" onClick={() => handleManageRoles(rol)}>
                                   Procesos
                                </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            </div>
            
{/*            
            <ActionsContainer onPrint={handleShowPreview} /> */}
            <FormRol currentRol={currentRol} showModal={showModal} handleClose={handleClose} />
            {showRolModal && currentRol && (
                <FormProcesosRol show={showRolModal} handleClose={handleCloseRolModal} rol={currentRol} />
            )}
            <CustomPrintModal
                show={showPreviewModal}
                handleClose={handleClosePreview}
                title1="LISTA DE ROLES"
                title2=""
                content={printContent}
                copia={false}
            />
        </div>
    );
};

export default ListRoles;
