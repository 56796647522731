import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCajaDiaria, modifyCajaDiaria } from '../../reducers/caja_slice';
import { Form, Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const CajaDiariaForm = ({ cajaDiaria, handleClose, show }) => {
  const dispatch = useDispatch();

  const { id,nombre, rolActivo, roles, caja, cuentacaja } = useSelector(state => state.sesion);
  console.log(id,nombre,caja)
  const xcaja = caja; // nrocaja
  const xusuario = id; // Usuario provisional
  const today = new Date().toLocaleDateString('es-BO', {
    timeZone: 'America/La_Paz',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('-');

  // Obtener la lista de cajas desde el estado global
  const cajas = useSelector(state => state.caja.cajas);

  // Obtener la caja seleccionada por nrocaja (xcaja)
  const selectedCaja = cajas && cajas.length > 0 ? cajas.find(caja => caja.nrocaja === xcaja) : null;

  const [formData, setFormData] = useState({
    nrocaja: xcaja,
    numero_cuenta: selectedCaja ? selectedCaja.numero_cuenta : '',
    id_caja: selectedCaja ? selectedCaja.id : '',
    fechaAper: today, // Fecha actual
    montoInicial: '',
    id_usuario: xusuario, // Provisionalmente asignado
  });

  useEffect(() => {
    if (cajaDiaria) {
      setFormData(cajaDiaria);
    } else {
      setFormData({
        nrocaja: xcaja,
        numero_cuenta: selectedCaja ? selectedCaja.numero_cuenta : '',
        id_caja: selectedCaja ? selectedCaja.id : '',
        fechaAper: today,
        montoInicial: '',
        id_usuario: xusuario,
      });
    }
  }, [cajaDiaria, selectedCaja, today]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar monto inicial
    if (formData.montoInicial < 0) {
      Swal.fire('Error', 'El monto inicial no puede ser menor a cero', 'error');
      return;
    }

    // Obtener la hora actual en formato de 24 horas
    const currentHour = new Date().toLocaleTimeString('en-GB'); // Formato 24 horas (HH:MM:SS)

    const updatedData = {
      ...formData,
      horaAper: currentHour, // Añadir la hora actual en formato de 24 horas
    };

    if (cajaDiaria) {
      dispatch(modifyCajaDiaria({ id: cajaDiaria.id, cajaDiaria: updatedData })).then(() => {
        Swal.fire('Éxito', 'La apertura diaria ha sido actualizada correctamente', 'success');
        handleClose();
      });
    } else {
      dispatch(addCajaDiaria(updatedData)).then(() => {
        Swal.fire('Éxito', 'La apertura diaria ha sido creada correctamente', 'success');
        handleClose();
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{cajaDiaria ? 'Editar Apertura Diaria' : 'Nueva Apertura Diaria'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Número de Caja (nrocaja), no editable */}
          <Form.Group controlId="formNroCaja">
            <Form.Label>Nro Caja</Form.Label>
            <Form.Control
              type="text"
              name="nrocaja"
              value={formData.nrocaja}
              readOnly
            />
          </Form.Group>

          {/* Número de Cuenta, no editable */}
          <Form.Group controlId="formNumeroCuenta">
            <Form.Label>Número Cuenta</Form.Label>
            <Form.Control
              type="text"
              name="numero_cuenta"
              value={formData.numero_cuenta}
              readOnly
            />
          </Form.Group>

          {/* Fecha de Apertura, no editable */}
          <Form.Group controlId="formFechaAper">
            <Form.Label>Fecha de Apertura</Form.Label>
            <Form.Control
              type="date"
              name="fechaAper"
              value={formData.fechaAper}
              readOnly
            />
          </Form.Group>

          {/* Monto Inicial */}
          <Form.Group controlId="formMontoInicial">
            <Form.Label>Monto Inicial</Form.Label>
            <Form.Control
              type="number"
              name="montoInicial"
              value={formData.montoInicial}
              onChange={handleChange}
              min="0" // Validación de mínimo 0
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {cajaDiaria ? 'Actualizar' : 'Guardar'}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="ml-2">
            Cancelar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CajaDiariaForm;
