import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CustomPrintModalBalanceGeneral from '../UI/CustomPrintModalBalanceGeneral'; // Ajustar la ruta según sea necesario

const BalanceGeneralModal = ({ show, handleClose, cuentasMap, fechaInicio, fechaFinal }) => {
  const [numLevels, setNumLevels] = useState(2);
  const [customHeader, setCustomHeader] = useState('');
  const [showPrintModal, setShowPrintModal] = useState(false); // Controla el modal de impresión
  const [printContent, setPrintContent] = useState(null); // Datos para imprimir

  const isValidLevel = numLevels >= 2 && numLevels <= 10;

  const prepareBalanceGeneralContent = () => {


    // Función recursiva para filtrar cuentas y subcuentas hasta un nivel dado
function filtrarCuentasPorNivel(cuentas, numLevels) {
    const resultado = [];
  
    cuentas.forEach((cuenta) => {
      const nivel = Math.floor(cuenta.codigo_cuenta.length / 2); // Cada 2 caracteres es un nivel
      if (
        nivel <= numLevels && // Verificar que el nivel esté dentro del rango permitido
        typeof cuenta.codigo_cuenta === "string" && // Validar que sea cadena
        cuenta.codigo_cuenta.trim() !== "" // Asegurar que no esté vacío
      ) {
        resultado.push(cuenta); // Agregar la cuenta al resultado
      }
  
      // Si tiene subcuentas, procesarlas recursivamente
      if (cuenta.subcuentas && cuenta.subcuentas.length > 0) {
        const subcuentasFiltradas = filtrarCuentasPorNivel(
          cuenta.subcuentas,
          numLevels
        );
        resultado.push(...subcuentasFiltradas);
      }
    });
  
    return resultado;
  }
    console.log("CuentasMap=",cuentasMap)
    if (!cuentasMap || Object.keys(cuentasMap).length === 0) {
      console.error('cuentasMap está vacío o no está definido.');
      return;
    }

    // Filtrar y organizar las cuentas por nivel
    const filteredAccounts = filtrarCuentasPorNivel(Object.values(cuentasMap), numLevels);
      
    console.log("fileteredAccount¿",filteredAccounts)
    if (filteredAccounts.length === 0) {
      console.error('No se encontraron cuentas dentro del nivel seleccionado.');
      return;
    }

    // Clasificar cuentas
   // Función recursiva para obtener todas las cuentas y subcuentas
// Filtrar activos, pasivos y patrimonio basados en el prefijo del código
const activos = filteredAccounts.filter((cuenta) =>
    cuenta.codigo_cuenta.startsWith("01")
  );
  const pasivos = filteredAccounts.filter((cuenta) =>
    cuenta.codigo_cuenta.startsWith("02")
  );
  const patrimonio = filteredAccounts.filter((cuenta) =>
    cuenta.codigo_cuenta.startsWith("03")
  );
  
  console.log("Activos:", activos);
  console.log("Pasivos:", pasivos);
  console.log("Patrimonio:", patrimonio);


// Encontrar las cuentas principales
const cuentaPrincipalActivos = activos.find(
    (cuenta) => cuenta.codigo_cuenta === "01"
  );
  const cuentaPrincipalPasivos = pasivos.find(
    (cuenta) => cuenta.codigo_cuenta === "02"
  );
  const cuentaPrincipalPatrimonio = patrimonio.find(
    (cuenta) => cuenta.codigo_cuenta === "03"
  );
  
  // Calcular los totales basados en el saldo de las cuentas principales
  const totalActivos =
    typeof cuentaPrincipalActivos?.saldo === "number"
      ? cuentaPrincipalActivos.saldo
      : 0;
  
  const totalPasivos =
    typeof cuentaPrincipalPasivos?.saldo === "number"
      ? cuentaPrincipalPasivos.saldo
      : 0;
  
  const totalPatrimonio =
    typeof cuentaPrincipalPatrimonio?.saldo === "number"
      ? cuentaPrincipalPatrimonio.saldo
      : 0;
  
  // Total de Pasivos + Patrimonio
  const totalPasivosPatrimonio = totalPasivos + totalPatrimonio;
  
  console.log("Total Activos:", totalActivos);
  console.log("Total Pasivos:", totalPasivos);
  console.log("Total Patrimonio:", totalPatrimonio);
  console.log("Total Pasivos + Patrimonio:", totalPasivosPatrimonio);

    // Transformar datos para impresión
    const content = {
        activos: activos
          .filter((cuenta) => Math.floor(cuenta.codigo_cuenta.length / 2) <= numLevels)
          .map((cuenta) => ({
            codigo_cuenta:cuenta.codigo_cuenta,
            nombre: cuenta.nombre_cuenta || "Sin Nombre",
            valor: typeof cuenta.saldo === "number" ? cuenta.saldo : 0,
          })),
        pasivosYpatrimonio: [
          ...pasivos
            .filter((cuenta) => Math.floor(cuenta.codigo_cuenta.length / 2) <= numLevels)
            .map((cuenta) => ({
              codigo_cuenta:cuenta.codigo_cuenta,
              nombre: cuenta.nombre_cuenta || "Sin Nombre",
              valor: typeof cuenta.saldo === "number" ? cuenta.saldo : 0,
            })),
          ...patrimonio
            .filter((cuenta) => Math.floor(cuenta.codigo_cuenta.length / 2) <= numLevels)
            .map((cuenta) => ({
              codigo_cuenta:cuenta.codigo_cuenta,
              nombre: cuenta.nombre_cuenta || "Sin Nombre",
              valor: typeof cuenta.saldo === "number" ? cuenta.saldo : 0,
            })),
        ],
        totalActivos,
        totalPasivosPatrimonio,
      };

    console.log('Contenido Generado para Imprimir:', content);

    setPrintContent(content);
  };

  const handlePrint = () => {
    if (isValidLevel) {
      prepareBalanceGeneralContent(); // Prepara los datos para el balance general
      setShowPrintModal(true); // Muestra el modal de impresión
    } else {
      console.error('El nivel seleccionado no es válido.');
    }
  };

  const handleClosePrintModal = () => {
    setShowPrintModal(false); // Cierra el modal de impresión
  };

  return (
    <>
      {/* Modal de Configuración del Balance General */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Configuración del Balance General</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            El Balance General muestra el estado financiero de la entidad,
            incluyendo sus activos, pasivos y patrimonio. Seleccione el número
            de niveles a mostrar y personalice el encabezado.
          </p>
          <div className="mb-3">
            <label htmlFor="numLevels">Número de niveles (2-10):</label>
            <input
              type="number"
              id="numLevels"
              value={numLevels}
              onChange={(e) => setNumLevels(Number(e.target.value))}
              min="2"
              max="10"
              className="form-control"
            />
            {!isValidLevel && (
              <small className="text-danger">
                Por favor, ingrese un nivel entre 2 y 10.
              </small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="customHeader">Texto de encabezado:</label>
            <input
              type="text"
              id="customHeader"
              value={customHeader}
              onChange={(e) => setCustomHeader(e.target.value)}
              className="form-control"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Volver
          </Button>
          <Button
            variant="primary"
            onClick={handlePrint}
            disabled={!isValidLevel}
          >
            Imprimir
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Impresión del Balance General */}
      {printContent && (
        <CustomPrintModalBalanceGeneral
          show={showPrintModal}
          handleClose={handleClosePrintModal}
          title1={customHeader || 'Balance General'}
          title2={`DEL ${fechaInicio} AL ${fechaFinal}`} // Concatenar fechas dinámicamente
          content={printContent}
          copia={false} // Configuración de marca de agua
        />
      )}
    </>
  );
};

export default BalanceGeneralModal;
