import { Card, Col, Image, ListGroup, Row, Table } from "react-bootstrap"
import { formatNumber, formatoOracion, fullNamePersona } from "../../util/customFormatter"
import { useEffect } from "react";
import { fetchProyectoById, setCurrentProyecto } from "../../reducers/proyecto_slice";
import { useDispatch, useSelector } from "react-redux";
import { formaFecha, formatFecha, formatFechaHora, getEstadoProyecto, getUploadUrl } from "../../util/valueCalculator";
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import CustomBadge from "./CustomBadge";

const VistaGeneralProyecto = ({currentProyecto, nuevaFotoPlano=null, ver=true}) => {
    const dispatch = useDispatch()
    const proyecto = useSelector(state => state.proyecto.current)
    useEffect(()=>{
        if(currentProyecto)
         {  
            if(currentProyecto.id && ver){
                dispatch(fetchProyectoById(currentProyecto.id))
            }else{
                dispatch(setCurrentProyecto(currentProyecto))
            }
        }
        
    },[dispatch, currentProyecto])

    if(!proyecto) return <></>

    const fotoPreview =  nuevaFotoPlano? URL.createObjectURL(nuevaFotoPlano) : 
                        currentProyecto?.foto_plano ? getUploadUrl(currentProyecto.foto_plano) : 
                            imgPlaceholder

    const valoradosProyecto = currentProyecto?.valorados.filter(val=>val.activo? val.activo===true: true)
    console.log("VistaProyecto", proyecto)
    return (
        <>
          <Row>
          {/* Columna izquierda: detalles del proyecto */}
          <Col md={7}>
          <Row className="mb-4">
                <Col>
                <Card className="border-0 shadow-sm">
                    <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                        <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Detalles del Proyecto</Card.Title>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Núm. de Visado:</div>
                                <div>{proyecto.num_visado}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Estado:</div>
                                <div><CustomBadge estado={proyecto.estado}/></div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Observación:</div>
                                <div>{proyecto.observacion || "Ninguna"} </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Proyectista:</div>
                                <div>{proyecto.proyectista && fullNamePersona(proyecto.proyectista.persona, true)}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Propietario:</div>
                                <div>{proyecto.propietario && fullNamePersona(proyecto.propietario, true)}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Cod Catastral:</div>
                                <div>{proyecto.inmueble?.cod_catastral}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Zona:</div>
                                <div>{proyecto.inmueble?.zona?.nombre}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Tipo de proyecto:</div>
                                <div>{proyecto.tipo?.nombre}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Descripción:</div>
                                <div>{proyecto.descripcion}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Superficie total:</div>
                                <div>{formatNumber(proyecto.superficie_total)} m²</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Honorarios total:</div>
                                <div>{formatNumber(proyecto.honorarios_total)} Bs.</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Comisión C.A.T.:</div>
                                <div>{formatNumber(proyecto.comision_cat)} Bs.</div>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>

                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                {proyecto.tipo?.alias !== "lote" && (
                    <Card className="border-0 shadow-sm">
                <Card.Body className="p-2">
                    <Card.Title className="mb-2" style={{ fontSize: '0.95rem' }}>Unidades</Card.Title>
                    {proyecto.unidades.length > 0 ? (
                        <Table responsive bordered hover size="sm" className="mt-2" style={{ fontSize: '0.85rem' }}>
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th>N°</th>
                                    <th>Tipología</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Superficie/unidad (m2)</th>
                                    <th>Honorarios Subtotal (Bs)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {proyecto.unidades.map((unidad,index) => (
                                    <tr key={unidad.id}>
                                        <td>{index+1}</td>
                                        <td>{`${unidad.tipologia?.categoria.nombre} CATEGORÍA `}<span style={{fontSize:"0.7rem"}}>{`/ ${unidad.tipologia?.area.nombre} / ${unidad.tipologia?.descripcion}`}</span></td>
                                        <td>{unidad.descripcion}</td>
                                        <td className="text-center">{unidad.num_unidades}</td>
                                        <td className="text-center">{formatNumber(unidad.superficie)}</td>
                                        <td className="text-center">{formatNumber(unidad.honorarios)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <p style={{ fontSize: '0.9rem', color: '#6c757d' }}>No hay unidades asociadas al proyecto.</p>
                    )}

                </Card.Body>
            </Card>
 )}
                </Col>
            </Row>  
            <Row className="mb-4">
                <Col>
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Información Adicional</Card.Title>
                    <ListGroup variant="flush">
                    <ListGroup.Item className="p-1 d-flex">
                            <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Registrado por :</div>
                            <div>{fullNamePersona(proyecto.usuario?.persona, true)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                            <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Fecha de registro :</div>
                            <div>{formatFechaHora(proyecto.fecha_registro)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Aprobado por : </div>
                            <div>{fullNamePersona(proyecto.gerente, true)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Fecha de aprobación :</div>
                            <div>{formatFechaHora(proyecto.fecha_aprobacion)}</div>
                        </ListGroup.Item>
                        {proyecto.usuarioAnulador &&   <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Anulado por : </div>
                            <div>{fullNamePersona(proyecto.usuarioAnulador.persona, true)}</div>
                        </ListGroup.Item>}
                        
                    </ListGroup>
                </Card.Body>
            </Card>

                </Col>
            </Row>
 
          </Col>
          {/* Columna derecha: foto del plano */}
          <Col md={5}>
            {fotoPreview ? (
              <div className="text-center">
                <Card.Title style={{ fontSize: '1rem' }}>Foto del plano</Card.Title>
                <Image
                  src={fotoPreview}
                  alt="Foto previa"
                  className="img-fluid mb-2 mt-3"
                  style={{ maxHeight: '400px', objectFit: 'cover' }}
                />
              </div>
            ) : (
              <div className="text-muted">No hay foto del plano disponible</div>
            )}

            <Row className="mt-4">
                <Col>
                {valoradosProyecto?.length>0 && 
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title key="cardValorados" className="mb-2" style={{ fontSize: '1rem' }}>Valorados</Card.Title>
                    <ListGroup variant="flush">
                    {valoradosProyecto.map((val,index)=> (
                                 <ListGroup.Item key={val.id} className="p-1 d-flex">
                                 <div style={{minWidth:"80px",fontWeight: 'bold' }}>{formatoOracion(val.descripcion)}: </div>
                                 <div> N° {val.ValoradoProyecto? val.ValoradoProyecto.nrovalorado : val.nrovalorado}</div>
                             </ListGroup.Item>
                            ))}
                    </ListGroup>
                    </Card.Body>
                    </Card>    
                }
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                {proyecto.recibos?.length>0  && 
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Recibos</Card.Title>
                    <ListGroup variant="flush">
                    {proyecto.recibos.map((recibo,index)=> (
                        <>
                               <ListGroup.Item key={recibo.id} className="p-2 d-flex flex-column flex-lg-row">
                                    <div style={{ marginRight:"20px"}}>
                                    <span style={{ fontWeight: 'bold' }}>N°: </span> {recibo.nroRecibo}
                                    </div>
                                    <div style={{ marginRight:"20px"}}>
                                    <span style={{ fontWeight: 'bold' }}>Fecha: </span> {formaFecha(recibo.fecha)}
                                    </div>
                                    <div>
                                    <span style={{ fontWeight: 'bold' }}>Monto: </span> {formatNumber(recibo.monto)} Bs.
                                    </div>
                                </ListGroup.Item>

                  
                             </>
                            ))}
                    </ListGroup>
                    </Card.Body>
                    </Card>    
                }
                </Col>
            </Row>
          </Col>
        </Row>
        
           
        </>
    )
}

export default VistaGeneralProyecto