import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const tituloProfesionalService = apiService('/titulo-profesional');

// Thunks para operaciones CRUD
export const fetchTitulosProfesionales = createAsyncThunk(
  'tituloProfesional/fetchTitulosProfesionales',
  async (afiliadoId) => {
    const response = await tituloProfesionalService.getAll({ afiliadoId });
    return response || [];
  }
);

export const fetchTitulosProfesionalesByAfiliado = createAsyncThunk(
  'tituloProfesional/fetchTitulosProfesionalesByAfiliado',
  async (afiliadoId, { rejectWithValue }) => {
    try {
      // Utilizando el método `getAllBySomeId` de apiService
      const response = await tituloProfesionalService.getAllBySomeId('afiliado', afiliadoId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error desconocido');
    }
  }
);

export const addTituloProfesional = createAsyncThunk(
  'tituloProfesional/addTituloProfesional',
  async (formData, { rejectWithValue }) => {
    try {
      console.log("Contenido del FormData:");
      for (const [key, value] of formData.entries()) {
          console.log(`Clave: ${key}, Valor: ${value instanceof File ? value.name : value}`);
      }
      const response = await tituloProfesionalService.createWithFormData(formData);
      return response;
    } catch (error) {
      console.error("Error en addTituloProfesional:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const modifyTituloProfesional = createAsyncThunk(
  'tituloProfesional/modifyTituloProfesional',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      console.log("Contenido del FormData:");
for (const [key, value] of formData.entries()) {
    console.log(`Clave: ${key}, Valor: ${value instanceof File ? value.name : value}`);
}
      const response = await tituloProfesionalService.updateWithFormData(id, formData);
      return response;
    } catch (error) {
      console.error("Error en modifyTituloProfesional:", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTituloProfesional = createAsyncThunk(
  'tituloProfesional/removeTituloProfesional',
  async (id, { rejectWithValue }) => {
    try {
      await tituloProfesionalService.remove(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Estado inicial
const initialState = {
  titulos: [],
  current: null,
  status: 'idle',
  error: null,
};

// Slice
const tituloProfesionalSlice = createSlice({
  name: 'tituloProfesional',
  initialState,
  reducers: {
    setCurrentTitulo(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTitulosProfesionales.fulfilled, (state, action) => {
        state.titulos = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchTitulosProfesionalesByAfiliado.fulfilled, (state, action) => {
        state.titulos = action.payload; // Actualiza solo los títulos del afiliado
        state.status = 'idle';
      })
      .addCase(addTituloProfesional.fulfilled, (state, action) => {
        state.titulos.push(action.payload);
        state.status = 'idle';
      })
      .addCase(modifyTituloProfesional.fulfilled, (state, action) => {
        const index = state.titulos.findIndex(t => t.id === action.payload.id);
        if (index !== -1) {
          state.titulos[index] = action.payload;
        }
        state.status = 'idle';
      })
      .addCase(removeTituloProfesional.fulfilled, (state, action) => {
        state.titulos = state.titulos.filter(t => t.id !== action.payload);
        state.status = 'idle';
      });
  }
});

export const { setCurrentTitulo } = tituloProfesionalSlice.actions;

export default tituloProfesionalSlice.reducer;
