import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addRequisito, modifyRequisito, fetchRequisitos } from '../../reducers/requisito_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const RequisitoForm = ({ currentRequisito, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [detalle, setDetalle] = useState('');
  const [estado, setEstado] = useState('');
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (currentRequisito) {
      setNombre(currentRequisito.nombre);
      setDetalle(currentRequisito.detalle);
      setEstado(currentRequisito.estado);
      setIsModified(false);
    } else {
      setNombre('');
      setDetalle('');
      setEstado('');
      setIsModified(true);
    }
  }, [currentRequisito]);

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    const requisitoData = {
      nombre: nombre ? nombre.toUpperCase() : null,
      detalle: detalle ? detalle.toUpperCase() : null,
      estado,
    };
    setError('');
    if (currentRequisito) {
      dispatch(modifyRequisito({ id: currentRequisito.id, requisito: requisitoData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar el requisito',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchRequisitos());
        }
      });
    } else {
      dispatch(addRequisito(requisitoData)).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al crear el requisito',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchRequisitos());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setDetalle('');
    setEstado('');
    setError('');
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'nombre') setNombre(value);
    if (name === 'detalle') setDetalle(value);
    if (name === 'estado') setEstado(value);
    setIsModified(true);
  };

  let title = currentRequisito ? "Editar requisito" : "Nuevo requisito";
  let headerColor = currentRequisito ? '#255957' : '#254159';
  let buttonText = currentRequisito ? 'Actualizar' : 'Crear';

  return (
    <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} centered>
      <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombreRequisito">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre del Requisito"
              name="nombre"
              value={nombre}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDetalleRequisito">
            <Form.Label>Detalle</Form.Label>
            <Form.Control
              type="text"
              placeholder="Detalle del Requisito"
              name="detalle"
              value={detalle}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEstadoRequisito">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              name="estado"
              value={estado}
              onChange={handleChange}
              required
            >
              <option value="">Selecciona un estado</option>
              <option value="ACTIVO">ACTIVO</option>
              <option value="INACTIVO">INACTIVO</option>
            </Form.Control>
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-between mt-5">
            <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
              Cancelar
            </Button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentRequisito && !isModified}>
              {buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RequisitoForm;
