import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLugares, removeLugar } from '../../reducers/lugar_slice';
import { fetchRegiones } from '../../reducers/region_slice';
import LugarForm from './LugarForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Button from 'react-bootstrap/Button';
import CustomPrintModal from '../UI/CustomPrintModal';

const LugarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lugares = useSelector(state => state.lugar.lugares || []);
  const regiones = useSelector(state => state.region.regiones || []);
  const [showModal, setShowModal] = useState(false);
  const [currentLugar, setCurrentLugar] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchLugares());
    dispatch(fetchRegiones());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeLugar(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El lugar ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar el lugar.', 'error');
        });
      }
    });
  };

  const handleEdit = lugar => {
    setCurrentLugar(lugar);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentLugar(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      lugares.map(lugar => ({
        ID: lugar.id,
        Nombre: lugar.nombre,
        "Gentilicio Masculino": lugar.gentilicio_masc,
        "Gentilicio Femenino": lugar.gentilicio_fem,
        Abreviatura: lugar.abrev,
        Región: lugar.region ? lugar.region.nombre : 'Sin región',
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lugares");
    XLSX.writeFile(workbook, "lugares.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['ID', 'Nombre', 'Gentilicio Masculino', 'Gentilicio Femenino', 'Abreviatura', 'Región']],
      body: lugares.map(lugar => [
        lugar.id,
        lugar.nombre,
        lugar.gentilicio_masc,
        lugar.gentilicio_fem,
        lugar.abrev,
        lugar.region ? lugar.region.nombre : 'Sin región',
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Lugares</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nuevo Lugar
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className='table-container'>
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Gentilicio Masculino</th>
              <th>Gentilicio Femenino</th>
              <th>Abreviatura</th>
              <th>Región</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lugares.map(lugar => (
              <tr key={lugar.id}>
                <td>{lugar.id}</td>
                <td>{lugar.nombre}</td>
                <td>{lugar.gentilicio_masc}</td>
                <td>{lugar.gentilicio_fem}</td>
                <td>{lugar.abrev}</td>
                <td>{lugar.region ? lugar.region.nombre : 'Sin región'}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(lugar)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(lugar.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {lugares.length === 0 && (
          <p className="text-center mt-4">No hay lugares disponibles.</p>
        )}
      </div>
      <LugarForm currentLugar={currentLugar} showModal={showModal} handleClose={() => setShowModal(false)} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE LUGARES"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default LugarList;
