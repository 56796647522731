import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addGrado, modifyGrado, fetchGrados } from '../../reducers/grado_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const GradoForm = ({ currentGrado, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [abreviado, setAbreviado] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentGrado) {
      setNombre(currentGrado.nombre);
      setAbreviado(currentGrado.abreviado || '');
      setIsModified(false); // Restablecemos el estado de modificación
    } else {
      resetForm();
    }
  }, [currentGrado]);

  useEffect(() => {
    // Determinamos si los valores actuales son diferentes a los del grado cargado
    if (currentGrado) {
      const isDifferent = 
        nombre !== currentGrado.nombre ||
        abreviado !== (currentGrado.abreviado || '');
      setIsModified(isDifferent);
    } else {
      setIsModified(false); // Si no hay un grado cargado, no está modificado
    }
  }, [nombre, abreviado, currentGrado]);

  const handleSubmit = e => {
    e.preventDefault();
    const gradoData = { nombre, abreviado };

    if (currentGrado) {
      dispatch(modifyGrado({ id: currentGrado.id, grado: gradoData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar el grado.', 'error');
        } else {
          Swal.fire('Éxito', 'El grado se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchGrados());
        }
      });
    } else {
      dispatch(addGrado(gradoData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear el grado.', 'error');
        } else {
          Swal.fire('Éxito', 'El grado se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchGrados());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setAbreviado('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentGrado ? 'Editar Grado' : 'Nuevo Grado'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control 
              type="text" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              required 
              placeholder="Nombre del grado"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Abreviatura</Form.Label>
            <Form.Control 
              type="text" 
              value={abreviado} 
              onChange={(e) => setAbreviado(e.target.value)} 
              placeholder="Abreviatura (opcional)"
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={currentGrado && !isModified}
            >
              {currentGrado ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default GradoForm;
