import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import VistaPagoCuota from './VistaPagoCuota';
import { useDispatch } from 'react-redux';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { formaFechaI, formatMonetaryValue, numeroALetras } from '../../util/valueCalculator';

const PagosRealizados = ({ show, handleClose, afiliado, cuotas }) => {
  const dispatch = useDispatch();
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false);
  const [selectedDepositoId, setSelectedDepositoId] = useState(null);
  const [selectedReciboId, setSelectedReciboId] = useState(null);
  const [recibos, setRecibos] = useState([]);

  useEffect(() => {
    if (show && afiliado) {
      // Filtrar cuotas asociadas al afiliado
      const cuotasAfiliado = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === "pagada");
  
      // Obtener recibos únicos con detalles
      const uniqueRecibos = Array.from(
        new Map(
          cuotasAfiliado.map(cuota => [cuota.id_recibo, cuota.id_recibo])
        ).keys()
      );
  
      // Cargar detalles de cada recibo único
      Promise.all(
        uniqueRecibos.map(async idRecibo => {
          const recibo = await dispatch(fetchReciboById(idRecibo)).unwrap();
          
          // Validar que recibo y recibo.fecha sean válidos
          const fechaFormateada = recibo?.fecha ? formaFechaI(recibo.fecha) : 'Fecha inválida';
  
          return {
            nroRecibo: recibo.nroRecibo,
            fecha: fechaFormateada, // Usar la fecha validada
            monto: recibo.monto,
            idRecibo: recibo.id, // Por si necesitas ID para la acción
            tipoPago: recibo.pago === 'B' ? 'BANCO' : recibo.pago === 'C' ? 'CAJA' : 'DESCONOCIDO',
            idDeposito: cuotasAfiliado.find(c => c.id_recibo === idRecibo)?.id_deposito
          };
        })
      ).then(setRecibos);
    }
  }, [show, afiliado, cuotas, dispatch]);
  
  
  

  const handleImprimirDetalle = (reciboId, depositoId) => {
    setSelectedReciboId(reciboId);
    setSelectedDepositoId(depositoId);
    setShowVistaPagoCuota(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Pagos Realizados por {`${afiliado.persona.nombre} ${afiliado.persona.apellido1}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>N°</th>
              <th>Nro Recibo</th>
              <th>Fecha</th>
              <th>Monto</th>
              <th>TipoPago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {recibos.map((recibo, index) => (
              <tr key={recibo.idRecibo}>
                <td>{index + 1}</td>
                <td>{recibo.nroRecibo}</td>
                <td>{recibo.fecha}</td>
                <td>{recibo.monto} Bs.</td>
                <td>{recibo.tipoPago}</td>
                <td>
                  <Button size="sm" onClick={() => handleImprimirDetalle(recibo.idRecibo, recibo.idDeposito)}>
                    Ver Detalle
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal>

      {showVistaPagoCuota && (
        <VistaPagoCuota
          show={showVistaPagoCuota}
          handleClose={() => setShowVistaPagoCuota(false)}
          afiliado={afiliado}
          depositoId={selectedDepositoId}
          reciboId={selectedReciboId}
        />
      )}
    </>
  );
};

export default PagosRealizados;
