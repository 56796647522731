import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Inicializar apiService con el path de backup
const backupApi = apiService('/backup');

// Acción para iniciar el backup
export const iniciarBackup = createAsyncThunk(
  'backup/iniciarBackup',
  async (_, thunkAPI) => {
    try {
      const response = await backupApi.create(); // Llamada al método 'create'
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.error || 'Error al realizar el backup.'
      );
    }
  }
);

const backupSlice = createSlice({
  name: 'backup',
  initialState: {
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    successMessage: null,
  },
  reducers: {
    resetState: (state) => {
      state.status = 'idle';
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(iniciarBackup.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.successMessage = null;
      })
      .addCase(iniciarBackup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.successMessage = action.payload.message;
      })
      .addCase(iniciarBackup.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Error desconocido.';
      });
  },
});

export const { resetState } = backupSlice.actions;

export default backupSlice.reducer;
