import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import { fetchCuotas } from '../../reducers/cuota_slice';
import PagoCuotasModal from './PagoCuotasModal';
import CustomPrintModal from '../UI/CustomPrintModal';
import CustomPrintModal3 from '../UI/CustomPrintModal3';
import VistaPagoCuota from './VistaPagoCuota';
import PagosRealizados from './PagosRealizados'; // Nuevo componente
import * as XLSX from 'xlsx';
import { fetchCajasDiariasByUsuario } from "../../reducers/caja_slice";

const ListaAfiliados = () => {
  const { codigoFuncionalidad } = useParams();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.sesion.id);
  const { nombre, rolActivo, roles,id } = useSelector(state => state.sesion);
  const afiliadoState = useSelector(state => state.afiliado || { afiliados: [] });
  const cajaState = useSelector(state => state.caja || { cajas: [] });
  const cajasDiarias = cajaState.cajasDiarias || [];
  const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria); // Selector para verificar si hay caja abierta
  const afiliados = afiliadoState.afiliados || [];
  const cuotas = useSelector(state => state.cuota.cuotas || []);

  const [showModal, setShowModal] = useState(false);
  const [currentAfiliado, setCurrentAfiliado] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEstadoCuentaModal, setShowEstadoCuentaModal] = useState(false);
  const [showPagosRealizados, setShowPagosRealizados] = useState(false); // Estado nuevo
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false);
  const [depositoId, setDepositoId] = useState(null);
  const [reciboId, setReciboId] = useState(null);
  const [numCuotasFilter, setNumCuotasFilter] = useState('Todos');
  const [filterType, setFilterType] = useState('<=');
  const [customNumCuotas, setCustomNumCuotas] = useState('');
  

  
  //console.log("usuario nombre=",nombre,"  id=",id)

  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchCuotas());
    dispatch(fetchCajasDiariasByUsuario(id)); // Llamar al thunk para cajas diarias
  }, [dispatch,id]);

  useEffect(() => {
    if (refreshFlag) {
      dispatch(fetchAfiliados());
      dispatch(fetchCuotas());
      setRefreshFlag(false);
    }
  }, [dispatch, refreshFlag]);


  //console.log("cajasDiarias=",cajasDiarias, "idcajadiaria=",idCajaDiaria)
  const filteredAfiliados = useMemo(() => {
    return afiliados
      .filter(afiliado => afiliado.situacion.extra !== 1) // Excluir afiliados con situacion.extra === 1
      .filter(afiliado => {
        const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
  
        if (numCuotasFilter === 'Todos') return true; // Mostrar todos los afiliados
        if (numCuotasFilter === 'custom') return true; // Mantener visible mientras se escribe
        if (filterType === '<=') return cuotasNoPagadas <= parseInt(numCuotasFilter);
        return cuotasNoPagadas === parseInt(numCuotasFilter);
      });
  }, [afiliados, cuotas, numCuotasFilter, filterType]);

  const handlePagoCuotas = afiliado => {
    setCurrentAfiliado(afiliado);
    setShowModal(true);
  };

  const handleImprimirEstadoCuenta = afiliado => {
    setCurrentAfiliado(afiliado);

    const cuotasNoPagadas = cuotas
      .filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada')
      .sort((a, b) => a.cuota - b.cuota);

    const totalCABNoPagadas = cuotasNoPagadas.reduce((total, cuota) => total + (Number(cuota.monto_cab) || 0), 0);
    const totalCATNoPagadas = cuotasNoPagadas.reduce((total, cuota) => total + (Number(cuota.monto_cat) || 0), 0);
    const totalTOTNoPagadas = cuotasNoPagadas.reduce((total, cuota) => total + (Number(cuota.monto_cat) + Number(cuota.monto_cab) || 0), 0);

    const estadoCuentaContent = {
      head: [['Emisión', 'Cuota', 'Monto CAB', 'Monto CAT']],
      body: cuotasNoPagadas.map(cuota => [
        new Date(cuota.fecha_emision).toLocaleDateString(),
        cuota.cuota,
        (Number(cuota.monto_cab) || 0).toFixed(2),
        (Number(cuota.monto_cat) || 0).toFixed(2)
      ])
    };

    setPrintContent({
      head: estadoCuentaContent.head,
      body: estadoCuentaContent.body,
      total1: `TOTAL CAB: ${totalCABNoPagadas.toFixed(2)} Bs    TOTAL CAT: ${totalCATNoPagadas.toFixed(2)} Bs`,
      total2: `TOTAL GENERAL: ${totalTOTNoPagadas.toFixed(2)} Bs `
    });

    setShowEstadoCuentaModal(true);
  };

  const handleImprimirPagosRealizados = afiliado => {
    setCurrentAfiliado(afiliado);
    setShowPagosRealizados(true); // Mostrar modal para Pagos Realizados
  };

  const handleClose = (data) => {
    //console.log("volvio con:", data);
    if (data && data.idDeposito) {
          setShowModal(false); // Cierra primero el modal de PagoCuotasModal
          setTimeout(() => {
              openVistaPagoCuotaModal(data.idDeposito, data.idAfiliado, data.idRecibo);
          }, 0); // Asegúrate de abrir VistaPagoCuota después del cierre y renderizado
      } else {
          setShowModal(false);
      }
      setCurrentAfiliado(null); // Limpia el afiliado si no hay datos de retorno
      setRefreshFlag(true);
    };

  const openVistaPagoCuotaModal = (idDeposito, idAfiliado,idRecibo) => {
    setDepositoId(idDeposito);
    setReciboId(idRecibo);
    setCurrentAfiliado(afiliados.find(af => af.id === idAfiliado));
    setTimeout(() => setShowVistaPagoCuota(true), 0);
    //console.log("deposito=",depositoId, "  recibo=",reciboId, "  afiliado=",currentAfiliado)
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleNumCuotasChange = event => {
    setNumCuotasFilter(event.target.value);
  };

  const handleFilterTypeChange = event => {
    setFilterType(event.target.value);
  };

  const sortedAfiliados = useMemo(() => {
    return filteredAfiliados.filter(afiliado => {
      const searchText = `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre} ${afiliado.persona.ci} ${afiliado.situacion.nombre || 'N/A'}`.toLowerCase();
      return searchText.includes(searchTerm.toLowerCase());
    });
  }, [filteredAfiliados, searchTerm]);

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'N° Registro', 'Ap. Paterno', 'Ap. Materno', 'Nombre', 'C.I.', 'Situación', 'Cuotas No Pagadas']],
      body: sortedAfiliados.map((afiliado, index) => {
        const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
        return [
          index + 1,
          afiliado.num_reg,
          afiliado.persona.apellido1,
          afiliado.persona.apellido2,
          afiliado.persona.nombre,
          afiliado.persona.ci,
          afiliado.situacion.nombre || 'N/A',
          cuotasNoPagadas
        ];
      })
    };
    return content;
  };

  const handleExport = () => {
    const exportData = sortedAfiliados.map((afiliado, index) => {
      const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
      return {
        'N°': index + 1,
        'N° Registro': afiliado.num_reg,
        'Ap. Paterno': afiliado.persona.apellido1,
        'Ap. Materno': afiliado.persona.apellido2,
        'Nombre': afiliado.persona.nombre,
        'C.I.': afiliado.persona.ci,
        'Situación': afiliado.situacion.nombre || 'N/A',
        'Cuotas No Pagadas': cuotasNoPagadas
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados");
    XLSX.writeFile(workbook, "afiliados.xlsx");
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>LISTA DE AFILIADOS</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-3 mt-2">
            <input
              type="text"
              placeholder="Nombre del afiliado o situación del afiliado"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-md-2 mt-2">
            <select className="form-control" value={filterType} onChange={handleFilterTypeChange}>
              <option value="<=">Menor o igual que</option>
              <option value="=">Igual a</option>
            </select>
          </div>
          <div className="col-md-2 mt-2">
          <select
            className="form-control"
            value={numCuotasFilter}
            onChange={(e) => {
              if (e.target.value === 'custom') {
                setNumCuotasFilter('custom'); // Habilita el campo de entrada
                setCustomNumCuotas(''); // Limpia el valor anterior
              } else {
                setNumCuotasFilter(e.target.value);
              }
            }}
          >
            <option value="Todos">Todos los afiliados</option>
            <option value="0">0 cuotas (al día)</option>
            <option value="1">1 cuota</option>
            <option value="2">2 cuotas</option>
            <option value="3">3 cuotas</option>
            <option value="4">4 cuotas</option>
            <option value="5">5 cuotas</option>
            <option value="custom">Más de 5 cuotas</option> {/* Opción personalizada */}
          </select>
          </div>
          {numCuotasFilter === 'custom' && (
            <div className="col-md-2 mt-2">
              <input
                type="number"
                className="form-control"
                placeholder="Ingrese un número"
                min="6"
                value={customNumCuotas} // Usa el estado local
                onChange={(e) => setCustomNumCuotas(e.target.value)} // Actualiza el valor local mientras se escribe
                onBlur={() => {
                  if (customNumCuotas !== '') {
                    setNumCuotasFilter(customNumCuotas); // Confirma el número personalizado
                  }
                }}
              />
            </div>
          )}

        </div>
        <div className="list-container-header">
          <div className="right-buttons">
            <button title="Imprimir" onClick={() => setShowPreviewModal(true)} className="icon-button btn-transparent">
              <i className="bi bi-printer"></i>
            </button>
            <button title="Exportar a Excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
              <i className="bi bi-file-spreadsheet"></i>
            </button>
          </div>
        </div>
        {sortedAfiliados.length === 0 ? (
          <p>No hay afiliados con cuotas no pagadas.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead style={{ backgroundColor: "#b2aea3" }}>
                <tr>
                  <th>N°</th>
                  <th>N° Registro</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombre</th>
                  <th>C.I.</th>
                  <th>Situación</th>
                  <th>Cuotas No Pagadas</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedAfiliados.map((afiliado, index) => {
                  const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
                  return (
                    <tr key={afiliado.id}>
                      <td>{index + 1}</td>
                      <td>{afiliado.num_reg}</td>
                      <td>{afiliado.persona.apellido1}</td>
                      <td>{afiliado.persona.apellido2}</td>
                      <td>{afiliado.persona.nombre}</td>
                      <td>{afiliado.persona.ci}</td>
                      <td>{afiliado.situacion.nombre || 'N/A'}</td>
                      <td>{cuotasNoPagadas}</td>
                      <td>
                        {codigoFuncionalidad === "1" && (
                         <button
                            className="icon-button btn-secondary-outline"
                            title="Pagar cuotas"
                            onClick={() => handlePagoCuotas(afiliado)}
                            disabled={!idCajaDiaria} // Deshabilitar si no hay caja abierta
                          >
                            <i className="bi bi-cash-stack"></i>
                          </button>
                        )}
                        {codigoFuncionalidad === "2" && (
                          <>
                            <button
                              className="icon-button btn-secondary-outline"
                              title="Imprimir estado de cuenta"
                              onClick={() => handleImprimirEstadoCuenta(afiliado)}
                            >
                              <i className="bi bi-printer"></i>
                            </button>
                            <button
                              className="icon-button btn-secondary-outline"
                              title="Imprimir depositos realizados"
                              onClick={() => handleImprimirPagosRealizados(afiliado)}
                            >
                              <i className="bi bi-receipt"></i>
                            </button>
                          </>
                        )}
                         {codigoFuncionalidad === "3" && (
                          <>
                            <button
                              className="icon-button btn-secondary-outline"
                              title="Registrar descuento"
                              onClick={() => handleImprimirEstadoCuenta(afiliado)}
                            >
                              <i className="bi bi-printer"></i>
                            </button>
                            <button
                              className="icon-button btn-secondary-outline"
                              title="Imprimir depositos realizados"
                              onClick={() => handleImprimirPagosRealizados(afiliado)}
                            >
                              <i className="bi bi-receipt"></i>
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {showModal && currentAfiliado && (
          <PagoCuotasModal
            show={showModal}
            handleClose={handleClose}
            afiliado={currentAfiliado}
            openVistaPagoCuotaModal={openVistaPagoCuotaModal}
          />
        )}
        {showVistaPagoCuota && currentAfiliado && (
          <VistaPagoCuota
            show={showVistaPagoCuota}
            handleClose={() => setShowVistaPagoCuota(false)}
            afiliado={currentAfiliado}
            depositoId={depositoId}
            reciboId={reciboId}
          />
        )}
        {showPagosRealizados && currentAfiliado && (
          <PagosRealizados
            show={showPagosRealizados}
            handleClose={() => setShowPagosRealizados(false)}
            afiliado={currentAfiliado}
            cuotas={cuotas} // Pasamos las cuotas desde el estado global
          />
        )}

        <CustomPrintModal
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          title1="LISTA DEL ESTADO DE CUENTAS DE AFILIADOS"
          title2={`${
            numCuotasFilter === 'Todos' ? 'TODOS' : `${filterType === '<=' ? 'MENOR O IGUAL A' : 'IGUAL A'} ${numCuotasFilter}`
          } CUOTAS VENCIDAS`}
          content={getPreviewContent()}
        />
        {showEstadoCuentaModal && currentAfiliado && (
          <CustomPrintModal3
            show={showEstadoCuentaModal}
            handleClose={() => setShowEstadoCuentaModal(false)}
            title1="ESTADO DE CUENTA - CUOTAS ADEUDADAS"
            title2={`AFILIADO: ${currentAfiliado.persona.apellido1} ${currentAfiliado.persona.apellido2} ${currentAfiliado.persona.nombre}`}
            title3={`Registro #: ${currentAfiliado.num_reg}`}
            title4={`#C.I.: ${currentAfiliado.persona.ci}`}
            title5=""
            content={printContent}
            total1={printContent.total1}
            total2={printContent.total2}
          />
        )}
      </div>
    </div>
  );
};

export default ListaAfiliados;
