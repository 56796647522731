import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

const detalleComprobanteService = apiService('/detalle-comprobantes'); // Servicio específico para detalle comprobantes

// Thunks
export const fetchDetalleComprobantes = createAsyncThunk(
  'detalleComprobantes/fetchDetalleComprobantes',
  async (_, { rejectWithValue }) => {
    console.log("Entro a slice..detalles")
    try {
      const response = await detalleComprobanteService.getAll();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching detalle comprobantes');
    }
  }
);

export const fetchDetalleComprobanteById = createAsyncThunk(
  'detalleComprobantes/fetchDetalleComprobanteById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await detalleComprobanteService.get(`/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching detalle comprobante by ID');
    }
  }
);

export const createDetalleComprobante = createAsyncThunk(
  'detalleComprobantes/createDetalleComprobante',
  async (detalleComprobante, { rejectWithValue }) => {
    try {
      const response = await detalleComprobanteService.post('/', detalleComprobante);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error creating detalle comprobante');
    }
  }
);

export const updateDetalleComprobante = createAsyncThunk(
  'detalleComprobantes/updateDetalleComprobante',
  async ({ id, detalleComprobante }, { rejectWithValue }) => {
    try {
      const response = await detalleComprobanteService.put(`/${id}`, detalleComprobante);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error updating detalle comprobante');
    }
  }
);

export const deleteDetalleComprobante = createAsyncThunk(
  'detalleComprobantes/deleteDetalleComprobante',
  async (id, { rejectWithValue }) => {
    try {
      await detalleComprobanteService.delete(`/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error deleting detalle comprobante');
    }
  }
);

// Slice
const detalleComprobanteSlice = createSlice({
  name: 'detalleComprobantes',
  initialState: {
    detalleComprobantes: [],
    currentDetalleComprobante: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchDetalleComprobantes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDetalleComprobantes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.detalleComprobantes = action.payload;
      })
      .addCase(fetchDetalleComprobantes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Fetch by ID
      .addCase(fetchDetalleComprobanteById.fulfilled, (state, action) => {
        state.currentDetalleComprobante = action.payload;
      })
      // Create
      .addCase(createDetalleComprobante.fulfilled, (state, action) => {
        state.detalleComprobantes.push(action.payload);
      })
      // Update
      .addCase(updateDetalleComprobante.fulfilled, (state, action) => {
        const index = state.detalleComprobantes.findIndex(
          (detalle) => detalle.id === action.payload.id
        );
        if (index !== -1) {
          state.detalleComprobantes[index] = action.payload;
        }
      })
      // Delete
      .addCase(deleteDetalleComprobante.fulfilled, (state, action) => {
        state.detalleComprobantes = state.detalleComprobantes.filter(
          (detalle) => detalle.id !== action.payload
        );
      });
  },
});

export const { resetStatus } = detalleComprobanteSlice.actions;

export default detalleComprobanteSlice.reducer;
