import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react'; // Generador de códigos QR
import { fetchTitulosProfesionalesByAfiliado } from '../../reducers/tituloProfesional_slice'; // Importación del fetch
import { formatFecha, getUploadUrl } from '../../util/valueCalculator'; // Función para formatear fechas y obtener URL de fotos
import cabeceraCud from '../../assets/cabeceraCat.png'; // Importar logotipo

const CarnetAfiliadoModal = ({ showModal, handleClose, afiliado }) => {
  const dispatch = useDispatch();
  const titulos = useSelector((state) => state.tituloProfesional.titulos || []);
  const [tituloVinculado, setTituloVinculado] = useState({
    nombre_titulo: 'Sin Vinculación',
    institucion: { nombre: 'N/A' },
    fecha_obtencion: 'N/A',
  });
  const [fotoPreview, setFotoPreview] = useState('');

  useEffect(() => {
    if (afiliado?.id) {
      dispatch(fetchTitulosProfesionalesByAfiliado(afiliado.id)).then(() => {
        const titulo = titulos.find((t) => t.id === afiliado.id_titulo);
        if (titulo) {
          setTituloVinculado(titulo);
        }
      });

      // Configurar la foto del afiliado
      if (afiliado?.persona?.foto) {
        setFotoPreview(getUploadUrl(afiliado.persona.foto));
      } else {
        setFotoPreview('https://via.placeholder.com/150'); // Placeholder por defecto
      }
    }
  }, [dispatch, afiliado, titulos]);

  // Datos para generar el QR
  const qrData = `
    Registro #: ${afiliado?.num_reg || 'N/A'}
    Apellidos: ${afiliado?.persona?.apellido1 || ''} ${afiliado?.persona?.apellido2 || ''}
    Nombres: ${afiliado?.persona?.nombre || ''}
    C.I.: ${afiliado?.persona?.ci || 'N/A'}
    Fecha Nacimiento: ${formatFecha(afiliado?.persona?.fecha_nacimiento) || 'N/A'}
    Título: ${tituloVinculado.nombre_titulo}
    Universidad: ${tituloVinculado.institucion?.nombre}
    Fecha del título: ${formatFecha(tituloVinculado.fecha_obtencion) || 'N/A'}
  `;

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <img
            src={cabeceraCud}
            alt="Logotipo Colegio"
            style={{ width: '300px', height: 'auto', marginRight: '20px' }}
          />
          <div style={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'right' }}>
            CARNET DEL AFILIADO
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-start">
          {/* Foto y QR */}
          <div className="me-4 text-center">
            <Image
              src={fotoPreview}
              alt="Foto del Afiliado"
              rounded
              style={{ width: '150px', height: '150px', objectFit: 'cover' }}
            />
            <div className="mt-3">
              <QRCodeCanvas value={qrData} size={150} />
            </div>
          </div>

          {/* Datos del Afiliado */}
          <div>
            <p>
              <strong>Registro #: </strong>
              {afiliado?.num_reg || 'N/A'}
            </p>
            <p>
              <strong>Fecha de Registro: </strong>
              {formatFecha(afiliado?.fecha_registro) || 'N/A'}
            </p>
            <p>
              <strong>Apellidos: </strong>
              {afiliado?.persona?.apellido1 || ''} {afiliado?.persona?.apellido2 || ''}
            </p>
            <p>
              <strong>Nombres: </strong>
              {afiliado?.persona?.nombre || ''}
            </p>
            <p>
              <strong>C.I.: </strong>
              {afiliado?.persona?.ci || 'N/A'}
            </p>
            <p>
              <strong>Fecha Nacimiento: </strong>
              {formatFecha(afiliado?.persona?.fecha_nacimiento) || 'N/A'}
            </p>
            <p>
              <strong>Título: </strong>
              {tituloVinculado.nombre_titulo}
            </p>
            <p>
              <strong>Universidad: </strong>
              {tituloVinculado.institucion?.nombre}
            </p>
            <p>
              <strong>Fecha del título: </strong>
              {formatFecha(tituloVinculado.fecha_obtencion) || 'N/A'}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Volver
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CarnetAfiliadoModal;
