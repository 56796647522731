import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal.js';
import { formatearFechaSimple, fullNamePersona } from '../../util/customFormatter.js';
import { Form } from 'react-bootstrap';
import { fetchRegistrosSegSituAfiliados } from '../../reducers/seg_situ_afiliado_slice.js';
import { theadNormal } from '../../styles/StylesCode.js';

const ListaSeguimientosSituAfiliado = () => {
  const dispatch = useDispatch();
  const segSituAfiliadosState = useSelector(state => state.segSituAfiliado);
  const situaciones = useSelector(state => state.situacion.situaciones);
  const registrosSeg = segSituAfiliadosState?.registros || [];
  const [sortConfig, setSortConfig] = useState({ key0: null, key1: null, key2: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [selectedSituacion, setSelectedSituacion] = useState('');
  const [filters, setFilters] = useState({ 
    fecha: '',
    situacionActual: '',
    situacionAnterior: '',
    searchTerm:''
  })
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchRegistrosSegSituAfiliados());
  }, [dispatch]);

  const handleFilters = (filter, value) => {
      setFilters((prev) => ({ ...prev, [filter]: value }));
  };

  const handleSort = (key0, key1, key2) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key0:null, key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key0 === key0 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key0 === key0 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key0, key1, key2, direction });
  };

  const handleBack = () => {
    navigate('/admin');
  };
  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleStateChange = (event) => {
    setSelectedSituacion(event.target.value);
  };
  
//EXCEL
const handleExport = () => {
  // Definir las cabeceras
  const headers = [['N°', 'Apellido Paterno', 'Apellido Materno', 'Nombre', 'C.I.', 'Número de registro', 'Fecha de registro', 'Situación']];

  // Mapear datos para personalizar los nombres de las columnas
  const data = filteredRegistros.map((afiliado, index) => [
    index + 1,
    afiliado.persona.apellido1,
    afiliado.persona.apellido2,
    afiliado.persona.nombre,
    afiliado.persona.ci,
    afiliado.num_reg || 'N/A',
    afiliado.fecha_reg_cab || 'N/A',
    afiliado.situacion.nombre || 'N/A',
  ]);

  // Combinar las cabeceras y los datos
  const exportData = [...headers, ...data];

  // Crear la hoja de trabajo con las cabeceras personalizadas
  const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados");

  // Exportar el archivo Excel
  XLSX.writeFile(workbook, "afiliados.xlsx");
};


//IMPRESION
  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Fecha', 'Hora', 'C.I.','Afiliado', 'Usuario ejecutor', 'Situacion anterior', 'Situacion actual', 'Comentario', 'Observacion']],
      body: filteredRegistros.map((registro, index) => [
        index+1,
        formatearFechaSimple(registro.fecha),
        registro.hora,
        registro.afiliado.persona.ci,
        fullNamePersona(registro.afiliado.persona),
        registro.usuario.usuario|| 'N/A',
        registro.situacion_anterior || 'N/A',
        registro.situacion_actual || 'N/A',
        registro.comentario || 'N/A',
        registro.observacion || 'N/A',
      ])
    };
    return content;
  };

//FILTRACION
const filteredRegistros =useMemo(() => {

   if(filters.fecha !== "" || filters.idAfiliado !== "" || filters.searchTerm !== "" ) 
    {  
      return [...registrosSeg].filter((reg) => {

      const matchesFecha = reg.fecha.includes(filters.fecha)
      const matchesSituacionAnterior = filters.situacionAnterior !== "" ? `${reg.situacion_anterior}`.toLowerCase().includes(filters.situacionAnterior.toLowerCase()) : true;
      const matchesSituacionActual = filters.situacionActual !== "" ? `${reg.situacion_actual}`.toLowerCase().includes(filters.situacionActual.toLowerCase()) : true;
      const matchesBusqueda = fullNamePersona(reg.afiliado.persona, true).toLowerCase().includes(filters.searchTerm.toLowerCase());

      return matchesFecha && matchesSituacionAnterior && matchesSituacionActual && matchesBusqueda

    });}else{ return [...registrosSeg]}

}, [registrosSeg,  filters]);

  //ORDENACION
  const sortedRegistros = useMemo(() => {

    let sortedData = [...filteredRegistros];
    if (sortConfig.key2) {
      sortedData.sort((a, b) => {
          let aValue, bValue;
  
          // Comparar fechas correctamente
          if (sortConfig.key2 === "fecha") {
              aValue = a[sortConfig.key2] ? new Date(a[sortConfig.key2]) : null;
              bValue = b[sortConfig.key2] ? new Date(b[sortConfig.key2]) : null;
          }
          // Comparar subpropiedades
          else if (sortConfig.key0 !== "") {
            aValue = a[sortConfig.key0][sortConfig.key1][sortConfig.key2];
            bValue = b[sortConfig.key0][sortConfig.key1][sortConfig.key2];
          } 
          else if (sortConfig.key1 !== "") {
              aValue = a[sortConfig.key1][sortConfig.key2];
              bValue = b[sortConfig.key1][sortConfig.key2];
          } 
          // Comparar otros valores
          else {
              aValue = a[sortConfig.key2];
              bValue = b[sortConfig.key2];
          }
          // Manejar valores nulos para que vayan al final
          if (aValue === null || aValue === undefined) return 1;
          if (bValue === null || bValue === undefined) return -1;
  
          // Comparar valores no nulos
          if (aValue < bValue) {
              return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
              return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
      });
  }
    return sortedData;
  }, [filteredRegistros, sortConfig]);

  return (
    <div className="list-container list1">
      <h1 className='table-title'>Seguimiento de cambios de situacion de afiliados</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-3 mt-2">
          <Form.Label > Buscar afiliado:</Form.Label>
             <Form.Control
               type="text"
              size="sm"
              placeholder="Nombre o C.I. de afiliado"
              className="form-control"
              value={filters.searchTerm}
              onChange={(e) => handleFilters("searchTerm", e.target.value)}
            />
          </div>
          <div className="col-md-3 mt-2">
          <Form.Label > Situación anterior:</Form.Label>
             <Form.Control
               type="text"
              size="sm"
              placeholder="Buscar por situación anterior"
              className="form-control"
              value={filters.situacionAnterior}
              onChange={(e) => handleFilters("situacionAnterior", e.target.value)}
            />
          </div>
          <div className="col-md-3 mt-2">
          <Form.Label > Situación nueva:</Form.Label>
             <Form.Control
               type="text"
              size="sm"
              placeholder="Buscar por situación nueva"
              className="form-control"
              value={filters.situacionActual}
              onChange={(e) => handleFilters("situacionActual", e.target.value)}
            />
          </div>
          <div className="col-md-2 mt-2">
          <Form.Label > Fecha:</Form.Label>
                <Form.Control
                  type="date"
                  size="sm"
                  value={filters.fecha}
                  onChange={(e) => handleFilters("fecha", e.target.value)}
                  className="form-control"
                  placeholder="Seleccionar fecha"
                />
          </div>
          <div className="col-md-3 text-right mt-2">
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {sortedRegistros.length === 0 ? (
          <p>No hay afiliados disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm' style={{fontSize:"0.95rem"}}>
              <thead style={theadNormal}>
                <tr>
                  <th className='col-index' rowSpan="2">N°</th>
                  <th onClick={() => handleSort('','','fecha')}  rowSpan="2">
                    Fecha
                    {sortConfig.key2 === 'fecha' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','','hora')}  rowSpan="2">
                    Hora
                    {sortConfig.key2 === 'hora' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('afiliado','persona','ci')} rowSpan="2">
                    C.I.
                    {sortConfig.key2 === 'ci' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  
                  <th onClick={() => handleSort('afiliado','persona','apellido1')} rowSpan="2">
                    Afiliado
                    {sortConfig.key2 === 'apellido1' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','usuario','usuario')} rowSpan="2">
                    Usuario ejecutor
                    {sortConfig.key2 === 'usuario' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th colSpan={2}> Situación</th>
                  <th rowSpan="2"> Comentario </th>
                  <th rowSpan="2"> Observación </th>
                  
                </tr>
                <tr>
                  <th style={{ padding: "0px" }}>  Anterior </th>
                  <th style={{ padding: "0px" }}> Nueva </th>
                </tr>
              </thead>
              <tbody>
                {sortedRegistros.map((registro, index) => (
                  <tr key={registro.id}>
                    <td>{index + 1}</td>
                    <td>{formatearFechaSimple(registro.fecha)}</td>
                    <td>{registro.hora}</td>
                    <td>{registro.afiliado.persona.ci}</td>  
                    <td>{`${fullNamePersona(registro.afiliado.persona)}`}</td>
                    <td>{registro.usuario.usuario}</td>  
                    <td style={{fontSize:"0.9rem"}}>{registro.situacion_anterior}</td>
                    <td style={{fontSize:"0.9rem"}}>{registro.situacion_actual}</td>
                    <td>{registro.comentario}</td>
                    <td>{registro.observacion}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
         <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE AFILIADOS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default ListaSeguimientosSituAfiliado;
