import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert } from 'react-bootstrap';
import SelectPersona from '../persona/SelectPersona';
import { addRol, fetchRoles, modifyRol } from '../../reducers/rol_slice';

const initialState = {
    nombre: "",
    alias:"",
    descripcion:"",
    estado_sql:""
}

const initialFormDetalles = {title:"Nuevo Rol",
    headerColor:"#254159", 
    buttonText:"Crear" }

const FormRol = ({ currentRol, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    const dispatch = useDispatch();
    const personas = useSelector(state => state.persona.personas);
    const persona = useSelector(state => state.persona.current);
    const roles = useSelector(state => state.rol.roles);
    const requestStatus = useSelector(state => state.rol.statusSend);
    const errorInRequest = useSelector(state => state.rol.error);
    const [isSending, setIsSending] = useState(false);
    useEffect(() => {
        if(showModal)
        {
        if (currentRol) {
            dispatch(fetchRoles());
            setFormDetalles({title:"Editar datos del rol",
                headerColor:"#255957", 
                buttonText:"Actualizar" })
            setDatos({...currentRol});
            setIsModified(false);
        } else {
            resetForm();
        }}
    }, [currentRol,showModal, dispatch]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');
            setIsSending(true)
            datos.nombre = datos.nombre.toUpperCase()
            datos.alias = datos.alias.toLowerCase()
            datos.descripcion = datos.descripcion.toUpperCase()
            if (currentRol) {
                dispatch(modifyRol({ id:currentRol.id, rol: datos })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el rol.',
                        });
                    } else {
                        handleClose();
                        console.log("Va a cambiar lista")
                        dispatch(fetchRoles());
                    }
                    setIsSending(false)
                });
            } else {
                dispatch(addRol(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el rol.',
                        });
                    } else {
                        handleClose();
                        console.log("Va a cambiar lista")
                        dispatch(fetchRoles());
                    }
                    setIsSending(false)
                });
            }
        } else {
            handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setFormDetalles(initialFormDetalles);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    return (
        <>
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit} className='normal-form'>
                
                    <Form.Group className="row">
                        <div className="col-md-6 modal-input">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                            type="text"
                            name="nombre"
                            placeholder="Nombre de rol"
                            value={datos.nombre}
                            onChange={handleInputChange}
                            required
                            disabled={isSending}
                            />
                        </div>
                        <div className="col-md-6 modal-input">
                            <Form.Label>Alias</Form.Label>
                            <Form.Control
                            type="text"
                            name="alias"
                            placeholder="Alias con letras simples y/o números"
                            value={datos.alias}
                            onChange={handleInputChange}
                            onKeyDown={(event) => {
                                const regex = /^[a-zA-Z0-9]+$/; // Solo letras sin tildes y números
                                if (!regex.test(event.key) && event.key !== 'Backspace') {
                                  event.preventDefault(); // Evita que se introduzcan caracteres no válidos
                                }
                              }}
                              disabled={isSending}
                            />
                        </div>
                     
                        </Form.Group>
                        <Form.Group className="row mt-3">
                        <div className="col-md-6 modal-input">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="descripcion"
                                placeholder='Escribe una descripción del rol...'
                                onChange={handleInputChange}
                                value={datos.descripcion}
                            />     
                        </div>
                        <div className="col-md-6">
                            <Form.Label>Estado</Form.Label>
                            <Form.Control
                                as="select"
                                name="estado_sql"
                                value={datos.estado_sql} // Asegura que el valor actual esté sincronizado
                                onChange={handleInputChange}
                                disabled={isSending}
                            >
                                <option value={true}>Activo</option>
                                <option value={false}>Inactivo</option>
                            </Form.Control>
                        </div>

                        
                        </Form.Group>
                    
                    <div className="d-flex justify-content-between mt-5">
                    <button className="button-mine btn-cancel-outline" onClick={handleClose} type='button'>
                            Cancelar
                        </button>
                        <button className="button-mine btn-success-outline" type="submit" disabled={!isModified}>
                        <i className="bi bi-check-circle"></i> {formDetalles.buttonText}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default FormRol;
