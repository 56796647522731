import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { modifyRolesUsuario } from "../../reducers/usuario_slice";
import { fetchRoles } from "../../reducers/rol_slice";

const FormRolesUsuario = ({ show, handleClose, usuario }) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.rol.roles);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const currentUsuarioId = useSelector(state => state.sesion.id)
  const [availableRoles, setAvailableRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


//  console.log("selectedRoles", selectedRoles);
  useEffect(() => {
    if (usuario) {
      setSelectedRoles([...usuario.roles]);
    }
    dispatch(fetchRoles());
  }, [dispatch, usuario]);

  useEffect(() => {
    if (usuario.roles) {
      const available = roles.filter(
        (rol) =>{
          return !usuario.roles.some(
            (rolU) =>
             {
              console.log("Rol y rolu", rol.id, rolU.id)
              return rolU.id === rol.id}
          ) && rol.alias !== "afiliado"}
      );
      setAvailableRoles(available);
    }
  }, [roles, usuario]);

  const handleAddRoles = (rolToAdd) => {
      setSelectedRoles((prevSelected) => [
        ...prevSelected, rolToAdd,
      ]);
      setAvailableRoles((prevAvailableRoles) =>
        prevAvailableRoles.filter((rol) => rol.id !== rolToAdd.id)
      );
    
  };

  const handleRemoveRoles = (rolToRemove) => {
    setAvailableRoles((prevAvailableRoles) => [
      ...prevAvailableRoles,
      rolToRemove,
    ]);
    setSelectedRoles((prevSelected) =>{
      return prevSelected.filter((rol) => rol.id !== rolToRemove.id)}
    );
  };


  const handleSave = async () => {
    const rolesToSend = selectedRoles.map((rol) => ({
      idrol: rol.id,
      idusu: usuario.id,
    }));
    const fd = new FormData();
    fd.append("roles", JSON.stringify(rolesToSend));

    dispatch(modifyRolesUsuario({id:usuario.id, roles: fd})).then((res) => {
      handleClose();
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRoles = availableRoles.filter((rol) =>
    rol.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!usuario || !usuario.id) {
    return null;
  }
  const sortedRoles = [...selectedRoles].sort(
    (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    }
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#255957", color: 'white' }}>
          <Modal.Title>
            Gestión de Roles del usuario: {usuario.usuario}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Roles Disponibles</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Buscar por nombre"
                      aria-label="Buscar por nombre"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {filteredRoles.map((rol) => (
                      <ListGroup.Item key={rol.id}>
                        <div className="d-flex justify-content-between">
                          {rol.nombre}
                          <button className="icon-button btn-secondary-outline" title="Asignar rol" onClick={() => handleAddRoles(rol)}>
                              <i className="bi bi-plus-lg"></i>
                          </button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Roles asignados al usuario</h5>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {sortedRoles.map((rol) => (
                      <ListGroup.Item key={rol.id}>
                        <div className="d-flex justify-content-between">
                          {rol.nombre}
                         
                            {(currentUsuarioId === usuario.id? rol.alias !== "admin" : true)&&
                           <button className="icon-button btn-secondary-outline" title="Quitar rol" onClick={() => handleRemoveRoles(rol)}>
                              <i className="bi bi-dash-lg"></i>
                          </button>
}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button-mine btn-cancel-outline" onClick={handleClose} type='button'>
              Cancelar
          </button>
          <button className="button-mine btn-success-outline" type="button" onClick={handleSave}>
            <i className="bi bi-check-circle"></i> Guardar
            </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormRolesUsuario;
