import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Table, Image } from 'react-bootstrap';
import { fetchDepositoConCuotas } from '../../reducers/cuota_slice';
import CustomPrintModal5 from '../UI/CustomPrintModal5'; // Reemplazo por CustomPrintModal5
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { formatearFecha, getUploadUrl } from '../../util/valueCalculator';
import moment from 'moment';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { fetchReciboById } from '../../reducers/recibo_slice';
import Swal from 'sweetalert2';
import { numeroALetras } from '../../util/valueCalculator';
import { fetchBancos } from '../../reducers/banco_slice';

const VistaPagoCuota = ({ show, handleClose, afiliado, depositoId, reciboId }) => {
  const dispatch = useDispatch();

  const depositoData = useSelector(state => state.cuota.depositoConCuotas || {});
  const bancos = useSelector(state => state.banco.bancos || []);
  const { deposito = {}, deposito_det = [], cuotas_pagadas = [] } = depositoData;

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [imagenVisible, setImagenVisible] = useState([]);
  
  const [showImprimirReciboModal, setShowImprimirReciboModal] = useState(false);
  const [reciboData, setReciboData] = useState(null);
  
  
  useEffect(() => {
    if (show && depositoId) {
      dispatch(fetchDepositoConCuotas(depositoId));
      dispatch(fetchBancos());
    }
  }, [dispatch, show, depositoId]);

  useEffect(() => {
    if (deposito_det.length > 0) {
      const archivo = deposito_det[0].archivo;
      if (archivo && typeof archivo === 'string') {
        setImagenPreview(getUploadUrl(archivo));
      }
    }
  }, [deposito_det]);

  const getBancoInfo = (id_banco) => {
    const banco = bancos.find(b => b.id === id_banco);
    return banco ? `${banco.banco} - ${banco.cuenta_bancaria}` : 'BANCO';
  };

  const toggleImageVisibility = (index, archivo) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility);

    if (newVisibility[index] && archivo) {
      setImagenPreview(getUploadUrl(archivo));
    } else {
      setImagenPreview(null);
    }
  };

  const handleImprimirRecibo = async () => {
    try {
        const recibo = await dispatch(fetchReciboById(reciboId)).unwrap();
        const totalLiteral = numeroALetras(recibo.monto);

        setReciboData({
            recibo,
            reciboDetalles: recibo.reciboDets,
            totalLiteral
        });

        setShowImprimirReciboModal(true);
    } catch (error) {
        Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
    }
};

  const handleImprimirConstancia = () => {
    const depositosContent = deposito_det.map(det => [
      getBancoInfo(det.id_banco),
      `${parseFloat(det.monto).toFixed(2)} Bs.`,
      det.docum
    ]);

    const cuotasContent = cuotas_pagadas.map(cuota => {
      const montoCatFinal = parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0);
      const montoCabFinal = parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0);
      const totalFinal = montoCatFinal + montoCabFinal;
    
      return [
        cuota.cuota,
        `${parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.`, // Monto CAT Original
        `${parseFloat(cuota.descuento_cat || 0).toFixed(2)} Bs.`, // Descuento CAT
        `${montoCatFinal.toFixed(2)} Bs.`, // Monto CAT Final
        `${parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.`, // Monto CAB Original
        `${parseFloat(cuota.descuento_cab || 0).toFixed(2)} Bs.`, // Descuento CAB
        `${montoCabFinal.toFixed(2)} Bs.`, // Monto CAB Final
        `${totalFinal.toFixed(2)} Bs.`, // Total
        cuota.observacion || 'N/A' // Observación
      ];
    });
    
    setPrintContent({
      title1: 'CONSTANCIA DE PAGO',
      title2: `Afiliado: ${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`,
      title3: `Reg. Nro.: ${afiliado.num_reg}     C.I.: ${afiliado.persona.ci}`,
      title4: `Fecha: ${moment(deposito.fecha).format('DD/MM/YYYY')}`,
      title5: 'Depósitos Realizados',
      detail1: { head: [['Banco','Monto', 'Documento']], body: depositosContent },
      total1: `Total Depósitos: ${deposito_det.reduce((sum, det) => sum + parseFloat(det.monto), 0).toFixed(2)} Bs.`,
      title6: 'Cuotas Pagadas',
      detail2: {
        head: [
          ['Cuota', 'CuotaCAT', 'Desc.CAT', 'PagadoCAT', 
           'CuotaCAB', 'Desc.CAB', 'PagadoCAB', 'TotalPago', 'Observación']
        ],
        body: cuotasContent
      },
      total2: `Total Cuotas Pagadas: ${cuotas_pagadas.reduce(
        (sum, cuota) =>
          sum +
          (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)) +
          (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)),
        0
      ).toFixed(2)} Bs.`
    });

    setShowPrintModal(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {afiliado && (
            <div>
              <h5>Datos del Afiliado</h5>
              <p><strong>Nombre:</strong> {`${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`}</p>
              <p><strong>Reg.Nro.:</strong> {afiliado.num_reg}    <strong>C.I.:</strong> {afiliado.persona.ci}</p>
              <p><strong>Fecha:</strong> {moment(deposito.fecha).format('DD/MM/YYYY')} </p>

            </div>
          )}

          {imagenPreview && (
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '10' }}>
              <Image
                src={imagenPreview}
                alt="Imagen de Depósito"
                className="img-fluid"
                style={{
                  maxWidth: '150px',
                  border: '1px solid #ccc',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#fff'
                }}
              />
            </div>
          )}

          <h5>Depósito</h5>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Banco</th>
                <th>Monto</th>
                <th>Fecha Depósito</th>
                <th>Documento</th>
                <th>Archivo</th>
              </tr>
            </thead>
            <tbody>
              {deposito_det.map((det, index) => (
                <tr key={index}>
                  <td>{getBancoInfo(det.id_banco)}</td>
                  <td>{parseFloat(det.monto).toFixed(2)} Bs.</td>
                  <td>{formatearFecha(det.fecha_deposito)}</td>
                  <td>{det.docum}</td>
                  <td>
                    {det.archivo && (
                      <button
                        className="icon-button"
                        onClick={() => toggleImageVisibility(index, det.archivo)}
                      >
                        {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h5>Cuotas Pagadas</h5>
<Table bordered hover size="sm">
  <thead>
    <tr>
      <th>Cuota</th>
      <th>CuotaCAT</th>
      <th>Desc.CAT</th>
      <th>PagadoCAT</th>
      <th>CuotaCAB</th>
      <th>Desc.CAB</th>
      <th>PagadoCAB</th>
      <th>TotalPago</th>
      <th>Observación</th>
    </tr>
  </thead>
  <tbody>
    {cuotas_pagadas.map((cuota, index) => {
      const montoCatFinal = parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0);
      const montoCabFinal = parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0);
      const totalFinal = montoCatFinal + montoCabFinal;

      return (
        <tr key={index}>
          <td>{cuota.cuota}</td>
          <td>{parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.</td>
          <td>{parseFloat(cuota.descuento_cat || 0).toFixed(2)} Bs.</td>
          <td>{montoCatFinal.toFixed(2)} Bs.</td>
          <td>{parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.</td>
          <td>{parseFloat(cuota.descuento_cab || 0).toFixed(2)} Bs.</td>
          <td>{montoCabFinal.toFixed(2)} Bs.</td>
          <td>{totalFinal.toFixed(2)} Bs.</td>
          <td>{cuota.observacion || 'N/A'}</td>
        </tr>
      );
    })}
  </tbody>
</Table>


        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
            <Button variant="primary" onClick={handleImprimirConstancia}>Imprimir Constancia de Pago</Button>
            <Button variant="success" onClick={handleImprimirRecibo}>Imprimir Recibo</Button>
        </Modal.Footer>

      </Modal>
      {showImprimirReciboModal && reciboData && (
          <ImprimirRecibo
              show={showImprimirReciboModal}
              handleClose={() => setShowImprimirReciboModal(false)}
              recibo={reciboData.recibo}
              reciboDetalles={reciboData.reciboDetalles}
              totalLiteral={reciboData.totalLiteral}
          />
      )}
      {showPrintModal && (
        <CustomPrintModal5
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          title1={printContent.title1}
          title2={printContent.title2}
          title3={printContent.title3}
          title4={printContent.title4}
          title5={printContent.title5}
          detail1={printContent.detail1}
          total1={printContent.total1}
          title6={printContent.title6}
          detail2={printContent.detail2}
          total2={printContent.total2}
          copia={false}
        />
      )}
    </>
  );
};

export default VistaPagoCuota;
