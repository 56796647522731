import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/cabeceraCat.png';
import { useSelector, useDispatch } from 'react-redux';

const CustomPrintModal3 = ({ show, handleClose, title1, title2, title3,title4,title5, total1, total2, content, copia }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre, usuario,rolActivo, roles,id } = useSelector(state => state.sesion);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta

        const marginLeftRight = 55; // Márgenes izquierdo y derecho
        const marginTop = 55; // Margen superior
        const marginBottom = 75; // Ajustar el margen inferior para bajar el pie de página
        const headerHeight = 100; // Altura de la cabecera

        const addWatermark = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setFontSize(72);
            doc.setTextColor(240, 240, 240); // marca de agua
            doc.setFont("helvetica", "bold");
            doc.text("COPIA", pageWidth / 2, pageHeight / 2, {
                angle: 45,
                align: 'center'
            });
        };

        const addHeader = (doc, pageNumber) => {
            doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(12);
            if (pageNumber > 0) {
                doc.text(`Página ${pageNumber}`, doc.internal.pageSize.getWidth() - marginLeftRight, marginTop + headerHeight + 20, { align: 'right' });
            }
        };

        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 50, 0); // Verde más oscuro
            doc.setLineWidth(1);
            doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(12);
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(
                `Impreso el: ${new Date().toLocaleString()} | Usuario: ${nombre}`, 
                pageWidth - marginLeftRight, 
                pageHeight - marginBottom + 40, 
                { align: 'right' }
            );
        };

        // Validaciones: Asegurar que los valores no sean null o undefined
        const validTitle1 = title1 || '';
        const validTitle2 = title2 || '';
        const validTitle3 = title3 || '';
        const validTitle4 = title4 || '';
        const validTitle5 = title5 || '';
        const validTotal1 = total1 || '';
        const validTotal2 = total2 || '';
        

        // Inicializa el número de página
        let pageNumber = 0;

        const addTitles = (doc) => {
            doc.setFontSize(16);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Asegurar que los títulos estén en negro
            // Validar que los títulos no sean nulos o vacíos
            if (validTitle1) {
                doc.text(validTitle1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 40, { align: 'center' });
            }
            doc.setFontSize(12);
            doc.setFont("times", "normal");
            if (validTitle2) {
                doc.text(validTitle2, marginLeftRight, marginTop + headerHeight + 60, { align: 'left' });
            }
            if (validTitle3) {
                doc.text(validTitle3, marginLeftRight, marginTop + headerHeight + 75, { align: 'left' });
            }
            if (validTitle4) {
                doc.text(validTitle4, marginLeftRight, marginTop + headerHeight + 90, { align: 'left' });
            }
            if (validTitle4) {
                doc.text(validTitle5, marginLeftRight, marginTop + headerHeight + 105, { align: 'left' });
            }
        };

        const addTotals = (doc) => {
            const startY = doc.autoTable.previous.finalY + 25;
            doc.setFontSize(11);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Asegurar que los totales estén en negro
            if (validTotal1) {
                doc.text(validTotal1, marginLeftRight, startY);
            }
            if (validTotal2) {
                doc.text(validTotal2, marginLeftRight, startY + 15);
            }
        };

        // Draw watermark on the first page before any content if copia is true
        if (copia) {
            addWatermark(doc);
        }

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            addTitles(doc);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 125);
            addTotals(doc);
            addFooter(doc);
        } else {
            addHeader(doc, pageNumber);
            addTitles(doc);
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + 120, // Debajo de la cabecera y los títulos
                margin: { top: marginTop + headerHeight + 120, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom },
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    addTitles(doc); // Asegurar que los títulos aparezcan en cada página
                    if (copia) addWatermark(doc);
                    addFooter(doc);
                },
                      styles: { 
                    fontSize:9,
                    lineColor: [0, 0, 0], // Color de borde (negro)
                    lineWidth: 0.5, // Grosor del borde más delgado
                },
                headStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el encabezado
                    textColor: [0, 0, 0], // Texto negro para el encabezado
                    lineWidth: 0.5, // Grosor del borde del encabezado más delgado
                    lineColor: [0, 0, 0], // Color de borde del encabezado
                },
                bodyStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el cuerpo
                    textColor: [0, 0, 0], // Texto negro para el cuerpo
                    lineWidth: 0.5, // Grosor del borde del cuerpo más delgado
                    lineColor: [0, 0, 0], // Color de borde del cuerpo
                },
                pageBreak: 'auto', // Permitir que el contenido salte a nuevas páginas
            });
            addTotals(doc);
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del Recibo"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal3;
