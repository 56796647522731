import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "procesos"
const procesoService = apiService('/procesos');

// Thunks para las operaciones CRUD

// Obtener todos los procesos
export const fetchProcesos = createAsyncThunk('proceso/fetchProcesos', async () => {
  const response = await procesoService.getAll();
  return response || [];
});

// Obtener un proceso por ID
export const fetchProcesoById = createAsyncThunk('proceso/fetchProcesoById', async (id) => {
  const response = await procesoService.getById(id);
  return response;
});

// Crear un nuevo proceso
export const addProceso = createAsyncThunk('proceso/addProceso', async (proceso, { rejectWithValue }) => {
  try {
    const response = await procesoService.create(proceso);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Modificar un proceso existente
export const modifyProceso = createAsyncThunk('proceso/modifyProceso', async ({ id, proceso }, { rejectWithValue }) => {
  try {
    const response = await procesoService.update(id, proceso);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Eliminar un proceso
export const removeProceso = createAsyncThunk('proceso/removeProceso', async (id, { rejectWithValue }) => {
  try {
    await procesoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  procesos: [],
  current: null,
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  }
};

// Definición del slice
const procesoSlice = createSlice({
  name: 'proceso',
  initialState,
  reducers: {
    setCurrentProceso(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcesos.fulfilled, (state, action) => {
        state.procesos = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchProcesos.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchProcesos.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchProcesoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addProceso.fulfilled, (state, action) => {
        state.procesos.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addProceso.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addProceso.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyProceso.fulfilled, (state, action) => {
        const index = state.procesos.findIndex(proceso => proceso.id === action.payload.id);
        if (index !== -1) {
          state.procesos[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyProceso.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyProceso.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(removeProceso.fulfilled, (state, action) => {
        state.procesos = state.procesos.filter(proceso => proceso.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeProceso.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeProceso.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

// Exportar las acciones y el reducer
export const { setCurrentProceso, resetStatusSend } = procesoSlice.actions;

export default procesoSlice.reducer;
