import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ListIndicesCalculo from './ListIndicesCalculo';


const ModalIndicesCalculo = ({currentCategoria, showModal, handleClose}) => {

    console.log("ModalIndicesCalculo",currentCategoria)
    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header closeButton style={{ backgroundColor: "#254159", color: 'white' }}>
            <Modal.Title>Índices de Cálculo de la Categoría : {currentCategoria?.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListIndicesCalculo currentCategoria={currentCategoria}/>
        </Modal.Body>
    </Modal>
    );
};

export default ModalIndicesCalculo;
