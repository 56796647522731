import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addCargo, modifyCargo, fetchCargos } from '../../reducers/cargo_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const CargoForm = ({ currentCargo, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [jerarquia, setJerarquia] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentCargo) {
      setNombre(currentCargo.nombre);
      setJerarquia(currentCargo.jerarquia || '');
      setIsModified(false);
    } else {
      resetForm();
    }
  }, [currentCargo]);

  useEffect(() => {
    if (currentCargo) {
      const isDifferent =
        nombre !== currentCargo.nombre ||
        jerarquia !== (currentCargo.jerarquia || '');
      setIsModified(isDifferent);
    }
  }, [nombre, jerarquia, currentCargo]);

  const handleSubmit = e => {
    e.preventDefault();
    const cargoData = { nombre, jerarquia: parseInt(jerarquia, 10) };

    if (currentCargo) {
      dispatch(modifyCargo({ id: currentCargo.id, cargo: cargoData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar el cargo.', 'error');
        } else {
          Swal.fire('Éxito', 'El cargo se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchCargos());
        }
      });
    } else {
      dispatch(addCargo(cargoData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear el cargo.', 'error');
        } else {
          Swal.fire('Éxito', 'El cargo se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchCargos());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setJerarquia('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentCargo ? 'Editar Cargo' : 'Nuevo Cargo'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              placeholder="Nombre del cargo"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Jerarquía</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={jerarquia}
              onChange={(e) => setJerarquia(e.target.value)}
              required
              placeholder="Jerarquía del cargo (número)"
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={currentCargo && !isModified}
            >
              {currentCargo ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CargoForm;
