import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Servicio API

// Crear una instancia del servicio para "comprobantes"
const comprobanteService = apiService('/comprobantes');

// Thunk para obtener todos los comprobantes con sus detalles
export const fetchAllComprobantesWithDetails = createAsyncThunk(
  'comprobante/fetchAllComprobantesWithDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await comprobanteService.getAllSome('all-with-details');
      console.log("Respuesta desde all-with-details:", response); // Verificar datos recibidos
      return response;
    } catch (error) {
      console.error("Error al obtener all-with-details:", error); // Verificar error recibido
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk para obtener todos los comprobantes sin detalles
export const fetchComprobantes = createAsyncThunk(
  'comprobante/fetchComprobantes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await comprobanteService.getAll();
      return response || [];
    } catch (error) {
      console.error("Error al obtener comprobantes:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk para obtener un comprobante específico con detalles
export const fetchComprobanteByNroWithDetails = createAsyncThunk(
  'comprobante/fetchComprobanteByNroWithDetails',
  async (nrocomprobante, { rejectWithValue }) => {
    try {
      const response = await comprobanteService.getAllBySomeId('condetalles', nrocomprobante);
      return response;
    } catch (error) {
      console.error("Error al obtener comprobante por número:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk para agregar un nuevo comprobante con detalles
export const addComprobante = createAsyncThunk(
  'comprobante/addComprobante',
  async (comprobante, { rejectWithValue }) => {
    try {
      const response = await comprobanteService.create(comprobante);
      return response;
    } catch (error) {
      console.error("Error al agregar comprobante:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk para modificar un comprobante existente
export const modifyComprobante = createAsyncThunk(
  'comprobante/modifyComprobante',
  async ({ id, comprobante }, { rejectWithValue }) => {
    try {
      const response = await comprobanteService.update(id, comprobante);
      return response;
    } catch (error) {
      console.error("Error al modificar comprobante:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk para eliminar un comprobante
export const removeComprobante = createAsyncThunk(
  'comprobante/removeComprobante',
  async (id, { rejectWithValue }) => {
    try {
      await comprobanteService.remove(id);
      return id;
    } catch (error) {
      console.error("Error al eliminar comprobante:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Estado inicial
const initialState = {
  comprobantes: [], // Lista de comprobantes
  current: null, // Comprobante actual seleccionado
  statusSend: 'idle', // Estado de envío de datos
  statusGet: 'idle', // Estado de obtención de datos
  loading: false, // Indicador de carga
  error: null // Manejo de errores
};

// Definición del slice
const comprobanteSlice = createSlice({
  name: 'comprobante',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setCurrentComprobante(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      // Manejo de fetchAllComprobantesWithDetails
      .addCase(fetchAllComprobantesWithDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllComprobantesWithDetails.fulfilled, (state, action) => {
        console.log("Datos cargados correctamente:", action.payload); // Depuración
        state.loading = false;
        state.comprobantes = action.payload; // Asignación de los datos obtenidos
      })
      .addCase(fetchAllComprobantesWithDetails.rejected, (state, action) => {
        console.error("Error al cargar comprobantes con detalles:", action.payload); // Depuración
        state.loading = false;
        state.error = action.payload;
      })

      // Manejo de fetchComprobantes
      .addCase(fetchComprobantes.fulfilled, (state, action) => {
        state.comprobantes = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchComprobantes.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchComprobantes.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Manejo de fetchComprobanteByNroWithDetails
      .addCase(fetchComprobanteByNroWithDetails.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchComprobanteByNroWithDetails.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchComprobanteByNroWithDetails.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Manejo de addComprobante
      .addCase(addComprobante.fulfilled, (state, action) => {
        state.comprobantes.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Manejo de modifyComprobante
      .addCase(modifyComprobante.fulfilled, (state, action) => {
        const index = state.comprobantes.findIndex(c => c.id === action.payload.id);
        if (index !== -1) {
          state.comprobantes[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Manejo de removeComprobante
      .addCase(removeComprobante.fulfilled, (state, action) => {
        state.comprobantes = state.comprobantes.filter(c => c.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

// Exportar acciones y el reducer
export const {  resetError, setCurrentComprobante, resetStatusSend } = comprobanteSlice.actions;
export default comprobanteSlice.reducer;
