import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "regiones"
const regionService = apiService('/regiones');

// Thunks para las operaciones existentes
export const fetchRegiones = createAsyncThunk('region/fetchRegiones', async () => {
  const response = await regionService.getAll();
  return response || [];
});

export const fetchRegionesByPais = createAsyncThunk('region/fetchRegionesByPais', async (paisId) => {
  const response = await regionService.getAllBySomeId('pais', paisId);
  return response || [];
});

// Thunks para nuevas operaciones CRUD
export const fetchRegionById = createAsyncThunk('region/fetchRegionById', async (id) => {
  const response = await regionService.getById(id);
  return response;
});

export const addRegion = createAsyncThunk('region/addRegion', async (region, { rejectWithValue }) => {
  try {
    const response = await regionService.create(region);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyRegion = createAsyncThunk('region/modifyRegion', async ({ id, region }, { rejectWithValue }) => {
  try {
    const response = await regionService.update(id, region);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeRegion = createAsyncThunk('region/removeRegion', async (id, { rejectWithValue }) => {
  try {
    await regionService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  regiones: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setCurrentRegion(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Casos para fetchRegiones
      .addCase(fetchRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload;
      })
      .addCase(fetchRegiones.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegiones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Casos para fetchRegionesByPais
      .addCase(fetchRegionesByPais.fulfilled, (state, action) => {
        state.regiones = action.payload;
      })
      .addCase(fetchRegionesByPais.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegionesByPais.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Casos para fetchRegionById
      .addCase(fetchRegionById.fulfilled, (state, action) => {
        state.current = action.payload;
      })

      // Casos para addRegion
      .addCase(addRegion.fulfilled, (state, action) => {
        state.regiones.push(action.payload);
      })
      .addCase(addRegion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addRegion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })

      // Casos para modifyRegion
      .addCase(modifyRegion.fulfilled, (state, action) => {
        const index = state.regiones.findIndex(region => region.id === action.payload.id);
        if (index !== -1) {
          state.regiones[index] = action.payload;
        }
      })
      .addCase(modifyRegion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyRegion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })

      // Casos para removeRegion
      .addCase(removeRegion.fulfilled, (state, action) => {
        state.regiones = state.regiones.filter(region => region.id !== action.payload);
      })
      .addCase(removeRegion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeRegion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentRegion } = regionSlice.actions;

export default regionSlice.reducer;
