import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLugar, modifyLugar, fetchLugares } from '../../reducers/lugar_slice';
import { fetchRegiones } from '../../reducers/region_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const LugarForm = ({ currentLugar, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [gentilicioMasc, setGentilicioMasc] = useState('');
  const [gentilicioFem, setGentilicioFem] = useState('');
  const [abrev, setAbrev] = useState('');
  const [idRegion, setIdRegion] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();
  const regiones = useSelector(state => state.region.regiones || []);

  useEffect(() => {
    dispatch(fetchRegiones());
    if (currentLugar) {
      setNombre(currentLugar.nombre);
      setGentilicioMasc(currentLugar.gentilicio_masc || '');
      setGentilicioFem(currentLugar.gentilicio_fem || '');
      setAbrev(currentLugar.abrev || '');
      setIdRegion(currentLugar.id_region || '');
      setIsModified(false);
    } else {
      resetForm();
    }
  }, [currentLugar, dispatch]);

  useEffect(() => {
    if (currentLugar) {
      const isDifferent =
        nombre !== currentLugar.nombre ||
        gentilicioMasc !== (currentLugar.gentilicio_masc || '') ||
        gentilicioFem !== (currentLugar.gentilicio_fem || '') ||
        abrev !== (currentLugar.abrev || '') ||
        idRegion !== (currentLugar.id_region || '');
      setIsModified(isDifferent);
    }
  }, [nombre, gentilicioMasc, gentilicioFem, abrev, idRegion, currentLugar]);

  const handleSubmit = e => {
    e.preventDefault();
    const lugarData = { nombre, gentilicio_masc: gentilicioMasc, gentilicio_fem: gentilicioFem, abrev, id_region: idRegion };

    if (currentLugar) {
      dispatch(modifyLugar({ id: currentLugar.id, lugar: lugarData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar el lugar.', 'error');
        } else {
          Swal.fire('Éxito', 'El lugar se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchLugares());
        }
      });
    } else {
      dispatch(addLugar(lugarData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear el lugar.', 'error');
        } else {
          Swal.fire('Éxito', 'El lugar se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchLugares());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setGentilicioMasc('');
    setGentilicioFem('');
    setAbrev('');
    setIdRegion('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentLugar ? 'Editar Lugar' : 'Nuevo Lugar'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control 
              type="text" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              required 
              placeholder="Nombre del lugar"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Masculino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioMasc} 
              onChange={(e) => setGentilicioMasc(e.target.value)} 
              placeholder="Gentilicio masculino (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Femenino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioFem} 
              onChange={(e) => setGentilicioFem(e.target.value)} 
              placeholder="Gentilicio femenino (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Abreviatura</Form.Label>
            <Form.Control 
              type="text" 
              value={abrev} 
              onChange={(e) => setAbrev(e.target.value)} 
              placeholder="Abreviatura (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Región</Form.Label>
            <Form.Control 
              as="select" 
              value={idRegion} 
              onChange={(e) => setIdRegion(e.target.value)} 
              required
            >
              <option value="">Seleccione una región</option>
              {regiones.map(region => (
                <option key={region.id} value={region.id}>
                  {region.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={currentLugar && !isModified}
            >
              {currentLugar ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LugarForm;
