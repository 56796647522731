import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCajas, deleteCaja, setCurrentCaja } from '../../reducers/caja_slice';
import CajaForm from './CajaForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal';

const CajaList = () => {
  const dispatch = useDispatch();
  const cajaState = useSelector(state => state.caja || { cajas: [] });
  const cajas = cajaState.cajas || [];
  const [showModal, setShowModal] = useState(false);
  const [currentCaja, setCurrentCaja] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCajas());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCaja(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La caja ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la caja', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key: null, direction: 'asc' });
      return;
    }

    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }

    setSortConfig({ key, direction });
  };

  const handleEdit = caja => {
    setCurrentCaja(caja);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentCaja(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentCaja(null);
    dispatch(fetchCajas());
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedCajas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cajas");
    XLSX.writeFile(workbook, "cajas.xlsx");
  };

  const sortedCajas = useMemo(() => {
    let sortedData = [...cajas];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [cajas, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['ID', 'Nro Caja', 'Ubicación', 'Número Cuenta']],
      body: sortedCajas.map(caja => [
        caja.id,
        caja.nrocaja,
        caja.ubicacion,
        caja.numero_cuenta
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Cajas</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-6"></div>
          <div className="col-md-6 text-right">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nueva Caja
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {cajas.length === 0 ? (
          <p>No hay cajas disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead style={{backgroundColor:"#b2aea3"}}>
                <tr>
                  <th onClick={() => handleSort('nrocaja')}>Nro Caja</th>
                  <th>Ubicación</th>
                  <th>Número Cuenta</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedCajas.map((caja, index) => (
                  <tr key={caja.id}>
                    <td>{caja.nrocaja}</td>
                    <td>{caja.ubicacion}</td>
                    <td>{caja.numero_cuenta}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(caja)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(caja.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {showModal && (
          <CajaForm caja={currentCaja} show={showModal} handleClose={handleClose} />
        )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE CAJAS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default CajaList;
