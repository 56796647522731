import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "dirafiliado"
const dirAfiliadoService = apiService('/directivas/dirafiliados');

// Thunk para crear masivamente DirAfiliado
export const bulkCreateDirAfiliado = createAsyncThunk(
  'dirafiliado/bulkCreateDirAfiliado',
  async ({ id_directiva, miembros }, { rejectWithValue }) => {
    try {
      const response = await dirAfiliadoService.create({ id_directiva, miembros });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk para obtener la lista de afiliados que componen una directiva
export const fetchAfiliadosByDirectiva = createAsyncThunk(
  'dirafiliado/fetchAfiliadosByDirectiva',
  async (id_directiva, { rejectWithValue }) => {
    try {
      const response = await dirAfiliadoService.getBySomeAttribute("id_directiva", id_directiva);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk para obtener la lista de directivas en las que participa un afiliado
export const fetchDirectivasByAfiliado = createAsyncThunk(
  'dirafiliado/fetchDirectivasByAfiliado',
  async (id_afiliado, { rejectWithValue }) => {
    try {
      const response = await dirAfiliadoService.getBySomeAttribute("id_afiliado", id_afiliado);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Estado inicial del slice
const initialState = {
  status: 'idle',
  error: null,
  afiliadosByDirectiva: [],
  directivasByAfiliado: [],
};

// Definición del slice
const dirAfiliadoSlice = createSlice({
  name: 'dirafiliado',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bulkCreateDirAfiliado.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bulkCreateDirAfiliado.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(bulkCreateDirAfiliado.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAfiliadosByDirectiva.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAfiliadosByDirectiva.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.afiliadosByDirectiva = action.payload;
      })
      .addCase(fetchAfiliadosByDirectiva.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchDirectivasByAfiliado.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDirectivasByAfiliado.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.directivasByAfiliado = action.payload;
      })
      .addCase(fetchDirectivasByAfiliado.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export default dirAfiliadoSlice.reducer;
