import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency2 } from '../../util/valueCalculator';
import { fetchUsuarioById } from '../../reducers/usuario_slice';
import { modifyCajaDiaria } from '../../reducers/caja_slice';
import { fetchComprobantes } from '../../reducers/comprobante_slice';
import { fetchAllDepositos } from '../../reducers/deposito_slice';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import Swal from 'sweetalert2';
import ImprimirCajaDiaria from './ImprimirCajaDiaria';

const CajaDiariaCierre = ({ show, handleClose, cajaDiaria, detallesRecibo }) => {
    const dispatch = useDispatch();
    const comprobantes = useSelector(state => state.comprobante.comprobantes || []);
    const depositos = useSelector(state => state.deposito.depositos || []);
    const bancos = useSelector(state => state.banco.bancos || []);
    const reciboActual = useSelector(state => state.recibo.current || {});
    const [usuario, setUsuario] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [totals, setTotals] = useState({
        ingresosCaja: 0,
        egresosCaja: 0,
        ingresosBanco: 0,
        egresosBanco: 0,
    });

    const { nombre } = useSelector(state => state.sesion);

    const today = new Date().toLocaleDateString('es-BO', {
        timeZone: 'America/La_Paz',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');

    useEffect(() => {
        if (cajaDiaria.id_usuario) {
            dispatch(fetchComprobantes());
            dispatch(fetchUsuarioById(cajaDiaria.id_usuario))
                .then(response => {
                    if (response.payload) {
                        setUsuario(response.payload);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el usuario:', error);
                });
        }
    }, [cajaDiaria.id_usuario, dispatch]);

    useEffect(() => {
        const detallesFiltrados = detallesRecibo.filter(det => det.fecha === cajaDiaria.fechaAper);

        const ingresosCaja = detallesFiltrados
            .filter(det => det.pago === 'C' && det.tipo === 'INGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const egresosCaja = detallesFiltrados
            .filter(det => det.pago === 'C' && det.tipo === 'EGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const ingresosBanco = detallesFiltrados
            .filter(det => det.pago === 'B' && det.tipo === 'INGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        const egresosBanco = detallesFiltrados
            .filter(det => det.pago === 'B' && det.tipo === 'EGRESO')
            .reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

        setTotals({
            ingresosCaja,
            egresosCaja,
            ingresosBanco,
            egresosBanco,
        });
    }, [detallesRecibo, cajaDiaria]);

    useEffect(() => {
        if (show) {
            dispatch(fetchAllDepositos());
            dispatch(fetchBancos());
        }
    }, [dispatch, show]);

    const generarNuevoNroComprobante = () => {
        const currentYear = new Date().getFullYear();
        const comprobantesYear = comprobantes.filter(comp => comp.nrocomprobante.startsWith(`${currentYear}-`));
        const ultimoComprobante = comprobantesYear.reduce((max, comp) => {
            const num = parseInt(comp.nrocomprobante.split('-')[1], 10);
            return num > max ? num : max;
        }, 0);

        const nuevoNumero = (ultimoComprobante + 1).toString().padStart(5, '0');
        return `${currentYear}-${nuevoNumero}`;
    };

    const handleCloseCaja = async () => {
      Swal.fire({
          title: '¿Está seguro?',
          text: 'Este es un proceso irreversible. ¿Desea continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, cerrar caja',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
      }).then(async (result) => {
          if (result.isConfirmed) {
              const currentHour = new Date().toLocaleTimeString('es-BO', { hour12: false });
              const fechaCierre = today;

              const nroComprobanteCaja = generarNuevoNroComprobante();
              const comprobanteCajaCabecera = {
                  nrocomprobante: nroComprobanteCaja,
                  gestion: new Date().getFullYear(),
                  fecha: today,
                  tipo_comprobante: 'TRASPASOS',
                  glosa: `MOVIMIENTOS DIARIOS DE CAJA ${cajaDiaria.nrocaja}`,
                  beneficiario: 'VARIOS',
                  estado: 'REGISTRADO',
              };

              const comprobanteCajaDetalles = [
                  {
                      codigo_cuenta: cajaDiaria.numero_cuenta,
                      glosa: 'INGRESOS EGRESOS VARIOS',
                      debe: totals.ingresosCaja,
                      haber: totals.egresosCaja,
                  },
                  ...detallesRecibo
                      .filter(det => det.pago === 'C' && det.fecha === cajaDiaria.fechaAper)
                      .map(det => ({
                          codigo_cuenta: det.codigo_cuentaM,
                          glosa: det.concepto,
                          debe: det.tipo === 'EGRESO' ? det.pagado : 0,
                          haber: det.tipo === 'INGRESO' ? det.pagado : 0,
                      })),
              ];

              const comprobanteCaja = {
                  ...comprobanteCajaCabecera,
                  detalles: comprobanteCajaDetalles,
              };

              const depositosDelDia = depositos.filter(deposito => deposito.fecha === cajaDiaria.fechaAper);

              const comprobanteDepositoDetalles = [];
              for (const deposito of depositosDelDia) {
                  await dispatch(fetchReciboById(deposito.id_recibo));

                  const reciboDetalles = reciboActual.reciboDets || [];
                  const totalDeposito = deposito.detalles.reduce((sum, det) => sum + parseFloat(det.monto || 0), 0);
                  const totalRecibo = reciboDetalles.reduce((sum, det) => sum + parseFloat(det.pagado || 0), 0);

                  if (totalDeposito !== totalRecibo) {
                      Swal.fire(
                          'Error de Balance',
                          `El depósito ${deposito.id} tiene un desbalance entre el total de los depósitos (${totalDeposito}) y el recibo asociado (${totalRecibo}).`,
                          'error'
                      );
                      return;
                  }

                  comprobanteDepositoDetalles.push(
                      ...deposito.detalles.map(detalle => ({
                          codigo_cuenta: bancos.find(b => b.id === detalle.id_banco)?.cuenta_contable || 'NO DEFINIDO',
                          glosa: detalle.docum,
                          debe: detalle.monto,
                          haber: 0,
                      })),
                      ...reciboDetalles.map(detalle => ({
                          codigo_cuenta: detalle.codigo_cuentaM,
                          glosa: detalle.concepto,
                          debe: 0,
                          haber: detalle.pagado,
                      }))
                  );
              }

              const nroComprobanteDeposito = generarNuevoNroComprobante();
              const comprobanteDepositoCabecera = {
                  nrocomprobante: nroComprobanteDeposito,
                  gestion: new Date().getFullYear(),
                  fecha: today,
                  tipo_comprobante: 'TRASPASOS',
                  glosa: `CONSOLIDADO DE DEPÓSITOS DEL DÍA ${today}`,
                  beneficiario: 'VARIOS',
                  estado: 'REGISTRADO',
              };

              const comprobanteDeposito = {
                  ...comprobanteDepositoCabecera,
                  detalles: comprobanteDepositoDetalles,
              };

              const updatedCajaDiaria = {
                  ...cajaDiaria,
                  fechaCierre: fechaCierre,
                  horaCierre: currentHour,
                  ingresos: totals.ingresosCaja,
                  egresos: totals.egresosCaja,
                  saldo: totals.ingresosCaja - totals.egresosCaja,
                  comprobantes: [comprobanteCaja, comprobanteDeposito],
              };

              dispatch(modifyCajaDiaria({ id: cajaDiaria.id, cajaDiaria: updatedCajaDiaria }))
                  .then(() => {
                      Swal.fire(
                          'Caja Cerrada',
                          'La caja ha sido cerrada correctamente y los comprobantes han sido generados.',
                          'success'
                      );
                      handleClose();
                  })
                  .catch(error => {
                      Swal.fire('Error', 'No se pudo cerrar la caja o generar los comprobantes.', 'error');
                  });
          }
      });
  };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Cierre de Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><strong>Fecha:</strong> {cajaDiaria.fechaAper}</p>
                        <p><strong>Caja:</strong> {cajaDiaria.nrocaja}</p>
                        <p><strong>Usuario:</strong> {nombre}</p>
                    </div>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Nro Recibo</th>
                                <th>Concepto</th>
                                <th>ING_CAJA</th>
                                <th>EGR_CAJA</th>
                                <th>ING_BANCO</th>
                                <th>EGR_BANCO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detallesRecibo
                                .filter(det => det.fecha === cajaDiaria.fechaAper)
                                .map((detalle, index) => (
                                    <tr key={index}>
                                        <td>{detalle.nroRecibo}</td>
                                        <td>{detalle.concepto}</td>
                                        <td>{detalle.pago === 'C' && detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                                        <td>{detalle.pago === 'C' && detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                                        <td>{detalle.pago === 'B' && detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                                        <td>{detalle.pago === 'B' && detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                                    </tr>
                                ))}
                            <tr>
                                <td colSpan="2"><strong>Totales</strong></td>
                                <td>{formatCurrency2(totals.ingresosCaja)}</td>
                                <td>{formatCurrency2(totals.egresosCaja)}</td>
                                <td>{formatCurrency2(totals.ingresosBanco)}</td>
                                <td>{formatCurrency2(totals.egresosBanco)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button variant="success" onClick={handleCloseCaja}>Cerrar Caja</Button>
                    <Button variant="primary" onClick={() => setShowPrintModal(true)}>Imprimir</Button>
                </Modal.Footer>
            </Modal>
            {showPrintModal && (
                <ImprimirCajaDiaria
                    show={showPrintModal}
                    handleClose={() => setShowPrintModal(false)}
                    cajaDiaria={cajaDiaria}
                    detallesRecibo={detallesRecibo.filter(det => det.fecha === cajaDiaria.fechaAper)}
                    totals={totals}
                    usuario={usuario?.usuario || ''}
                />
            )}
        </>
    );
};

export default CajaDiariaCierre;
