import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "directivas"
const directivaService = apiService('/directivas');

// Thunks para las operaciones CRUD
export const fetchDirectivas = createAsyncThunk('directiva/fetchDirectivas', async () => {
  const response = await directivaService.getAll();
  return response || [];
});

export const fetchDirectivaById = createAsyncThunk('directiva/fetchDirectivaById', async (id) => {
  const response = await directivaService.getById(id);
  return response;
});

export const addDirectiva = createAsyncThunk('directiva/addDirectiva', async (directiva, { rejectWithValue }) => {
  try {
    const response = await directivaService.create(directiva);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyDirectiva = createAsyncThunk('directiva/modifyDirectiva', async ({ id, directiva }, { rejectWithValue }) => {
  try {
    const response = await directivaService.update(id, directiva);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeDirectiva = createAsyncThunk('directiva/removeDirectiva', async (id, { rejectWithValue }) => {
  try {
    await directivaService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  directivas: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const directivaSlice = createSlice({
  name: 'directiva',
  initialState,
  reducers: {
    setCurrentDirectiva(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectivas.fulfilled, (state, action) => {
        state.directivas = action.payload;
      })
      .addCase(fetchDirectivas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDirectivas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchDirectivaById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addDirectiva.fulfilled, (state, action) => {
        state.directivas.push(action.payload);
      })
      .addCase(addDirectiva.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addDirectiva.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyDirectiva.fulfilled, (state, action) => {
        const index = state.directivas.findIndex(d => d.id === action.payload.id);
        if (index !== -1) {
          state.directivas[index] = action.payload;
        }
      })
      .addCase(modifyDirectiva.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyDirectiva.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeDirectiva.fulfilled, (state, action) => {
        state.directivas = state.directivas.filter(d => d.id !== action.payload);
      })
      .addCase(removeDirectiva.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeDirectiva.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentDirectiva } = directivaSlice.actions;

export default directivaSlice.reducer;
