import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCargos } from '../../reducers/cargo_slice';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { fetchAfiliadosByDirectiva, bulkCreateDirAfiliado } from '../../reducers/dirAfiliado_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const DirAfiliadoModal = ({ showModal, handleClose, directiva }) => {
  const [selected, setSelected] = useState({});
  const [initialSelected, setInitialSelected] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [showAfiliadoModal, setShowAfiliadoModal] = useState(false);
  const [cargoToAssign, setCargoToAssign] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  const cargos = useSelector(state => state.cargo.cargos || []);
  const afiliados = useSelector(state => state.afiliado.afiliados || []);
  const afiliadosByDirectiva = useSelector(state => state.dirafiliado.afiliadosByDirectiva || []);

  useEffect(() => {
    if (directiva && directiva.id) {
      dispatch(fetchAfiliadosByDirectiva(directiva.id)); // Cargar afiliados de la directiva
    }
    dispatch(fetchCargos());
    dispatch(fetchAfiliados());
  }, [dispatch, directiva]);

  useEffect(() => {
    if (afiliadosByDirectiva.length > 0 && afiliados.length > 0) {
      const initialState = {};
  
      afiliadosByDirectiva.forEach(item => {
        const afiliadoCompleto = afiliados.find(afiliado => afiliado.id === item.id_afiliado);
  
        if (afiliadoCompleto && afiliadoCompleto.persona) {
          initialState[item.id_cargo] = {
            id_afiliado: item.id_afiliado,
            nombreCompleto: `${afiliadoCompleto.persona.apellido1 || ''} ${afiliadoCompleto.persona.apellido2 || ''} ${afiliadoCompleto.persona.nombre || ''} - ${afiliadoCompleto.num_reg || ''}`.trim(),
          };
        }
      });
  
      setSelected(initialState);
      setInitialSelected(initialState); // Guardar el estado inicial para comparación
    } else {
      // Si no hay afiliados asignados, inicializar como vacío
      setSelected({});
      setInitialSelected({});
    }
  }, [afiliadosByDirectiva, afiliados]);
  
  useEffect(() => {
    // Comparar el estado actual con el inicial para detectar cambios
    setHasChanges(JSON.stringify(selected) !== JSON.stringify(initialSelected));
  }, [selected, initialSelected]);

  const filteredAfiliados = afiliados.filter(afiliado =>
    `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre} ${afiliado.num_reg}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleOpenAfiliadoModal = (cargoId) => {
    setCargoToAssign(cargoId);
    setShowAfiliadoModal(true);
  };

  const handleUnassignCargo = (cargoId) => {
    setSelected(prev => {
      const updated = { ...prev };
      delete updated[cargoId];
      return updated;
    });
  };

  const handleSelectAfiliado = (afiliado) => {
    if (cargoToAssign) {
      setSelected(prev => ({
        ...prev,
        [cargoToAssign]: {
          id_afiliado: afiliado.id,
          nombreCompleto: `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre} - ${afiliado.num_reg}`,
        }
      }));
      setShowAfiliadoModal(false);
    }
  };

  const handleSave = () => {
    const miembros = Object.keys(selected).map(cargoId => ({
      id_cargo: parseInt(cargoId, 10),
      id_afiliado: selected[cargoId].id_afiliado,
    }));

    dispatch(bulkCreateDirAfiliado({ id_directiva: directiva.id, miembros }))
      .then(response => {
        if (!response.error) {
          Swal.fire('Éxito', 'La directiva fue conformada exitosamente.', 'success');
          handleClose();
        } else {
          Swal.fire('Error', response.error.message || 'Hubo un error al conformar la directiva.', 'error');
        }
      });
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Conformar Directiva: {directiva?.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Jerarquía</th>
                <th>Cargo</th>
                <th>Asignar</th>
                <th>Afiliado</th>
              </tr>
            </thead>
            <tbody>
              {[...cargos].sort((a, b) => a.jerarquia - b.jerarquia).map(cargo => (
                <tr key={cargo.id}>
                  <td>{cargo.jerarquia}</td>
                  <td>{cargo.nombre}</td>
                  <td className="text-center">
                    {selected[cargo.id] ? (
                      <Button
                        variant="outline-danger"
                        size="sm"
                        title="Desasignar Afiliado"
                        onClick={() => handleUnassignCargo(cargo.id)}
                      >
                        <i className="bi bi-arrow-left"></i>
                      </Button>
                    ) : (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        title="Asignar Afiliado"
                        onClick={() => handleOpenAfiliadoModal(cargo.id)}
                      >
                        <i className="bi bi-arrow-right"></i>
                      </Button>
                    )}
                  </td>
                  <td>{selected[cargo.id]?.nombreCompleto || 'Sin asignar'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!hasChanges} // Botón inhabilitado si no hay cambios
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {showAfiliadoModal && (
        <Modal show={showAfiliadoModal} onHide={() => setShowAfiliadoModal(false)} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Seleccionar Afiliado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              type="text"
              placeholder="Buscar afiliado por apellido o número de registro"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-3"
            />
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Apellido1</th>
                  <th>Apellido2</th>
                  <th>Nombre</th>
                  <th>NroRegistro</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {filteredAfiliados.map(afiliado => (
                  <tr key={afiliado.id}>
                    <td>{afiliado.persona.apellido1}</td>
                    <td>{afiliado.persona.apellido2}</td>
                    <td>{afiliado.persona.nombre}</td>
                    <td>{afiliado.num_reg}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleSelectAfiliado(afiliado)}
                      >
                        Asignar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default DirAfiliadoModal;
