import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addDirectiva, modifyDirectiva, fetchDirectivas } from '../../reducers/directiva_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const DirectivaForm = ({ currentDirectiva, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [fechaini, setFechaini] = useState('');
  const [fechafin, setFechafin] = useState('');
  const [comite, setComite] = useState('');
  const [observacion, setObservacion] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentDirectiva) {
      setNombre(currentDirectiva.nombre);
      setFechaini(currentDirectiva.fechaini || '');
      setFechafin(currentDirectiva.fechafin || '');
      setComite(currentDirectiva.comite || '');
      setObservacion(currentDirectiva.observacion || '');
      setIsModified(false);
    } else {
      resetForm();
    }
  }, [currentDirectiva]);

  useEffect(() => {
    if (currentDirectiva) {
      const isDifferent =
        nombre !== currentDirectiva.nombre ||
        fechaini !== (currentDirectiva.fechaini || '') ||
        fechafin !== (currentDirectiva.fechafin || '') ||
        comite !== (currentDirectiva.comite || '') ||
        observacion !== (currentDirectiva.observacion || '');
      setIsModified(isDifferent);
    }
  }, [nombre, fechaini, fechafin, comite, observacion, currentDirectiva]);

  const handleSubmit = e => {
    e.preventDefault();
    const directivaData = { nombre, fechaini, fechafin, comite, observacion };

    if (currentDirectiva) {
      dispatch(modifyDirectiva({ id: currentDirectiva.id, directiva: directivaData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar la directiva.', 'error');
        } else {
          Swal.fire('Éxito', 'La directiva se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchDirectivas());
        }
      });
    } else {
      dispatch(addDirectiva(directivaData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear la directiva.', 'error');
        } else {
          Swal.fire('Éxito', 'La directiva se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchDirectivas());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setFechaini('');
    setFechafin('');
    setComite('');
    setObservacion('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentDirectiva ? 'Editar Directiva' : 'Nueva Directiva'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              placeholder="Nombre de la directiva"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control
              type="date"
              value={fechaini}
              onChange={(e) => setFechaini(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Fecha Fin</Form.Label>
            <Form.Control
              type="date"
              value={fechafin}
              onChange={(e) => setFechafin(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Comité</Form.Label>
            <Form.Control
              as="select"
              value={comite}
              onChange={(e) => setComite(e.target.value)}
              required
            >
              <option value="">Seleccione un comité</option>
              <option value="COMITE ELECTORAL">Comité Electoral</option>
              <option value="DIRECTIVA">Directiva</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Observación</Form.Label>
            <Form.Control
              type="text"
              value={observacion}
              onChange={(e) => setObservacion(e.target.value)}
              placeholder="Observaciones (opcional)"
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={currentDirectiva && !isModified}
            >
              {currentDirectiva ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DirectivaForm;
