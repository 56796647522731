import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRegion, modifyRegion, fetchRegiones } from '../../reducers/region_slice';
import { fetchPaises } from '../../reducers/pais_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const RegionForm = ({ currentRegion, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [gentilicioMasc, setGentilicioMasc] = useState('');
  const [gentilicioFem, setGentilicioFem] = useState('');
  const [abrev, setAbrev] = useState('');
  const [idPais, setIdPais] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();
  const paises = useSelector(state => state.pais.paises || []);

  useEffect(() => {
    dispatch(fetchPaises()); // Cargar la lista de países al montar el componente

    if (currentRegion) {
      setNombre(currentRegion.nombre);
      setGentilicioMasc(currentRegion.gentilicio_masc || '');
      setGentilicioFem(currentRegion.gentilicio_fem || '');
      setAbrev(currentRegion.abrev || '');
      setIdPais(currentRegion.id_pais || '');
      setIsModified(false); // Al cargar una región, no se ha modificado nada
    } else {
      resetForm();
    }
  }, [currentRegion, dispatch]);

  // Efecto para actualizar isModified dinámicamente
  useEffect(() => {
    if (currentRegion) {
      const isDifferent =
        nombre !== currentRegion.nombre ||
        gentilicioMasc !== (currentRegion.gentilicio_masc || '') ||
        gentilicioFem !== (currentRegion.gentilicio_fem || '') ||
        abrev !== (currentRegion.abrev || '') ||
        idPais !== (currentRegion.id_pais || '');
      setIsModified(isDifferent);
    }
  }, [nombre, gentilicioMasc, gentilicioFem, abrev, idPais, currentRegion]);

  const handleSubmit = e => {
    e.preventDefault();
    const regionData = { 
      nombre, 
      gentilicio_masc: gentilicioMasc, 
      gentilicio_fem: gentilicioFem, 
      abrev, 
      id_pais: idPais 
    };

    if (currentRegion) {
      dispatch(modifyRegion({ id: currentRegion.id, region: regionData })).then(response => {
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se pudo actualizar la región.',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'La región se ha actualizado correctamente.',
          });
          handleClose();
          dispatch(fetchRegiones());
        }
      });
    } else {
      dispatch(addRegion(regionData)).then(response => {
        if (response.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se pudo crear la región.',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'La región se ha creado correctamente.',
          });
          handleClose();
          dispatch(fetchRegiones());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setGentilicioMasc('');
    setGentilicioFem('');
    setAbrev('');
    setIdPais('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentRegion ? 'Editar Región' : 'Nueva Región'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control 
              type="text" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              required 
              placeholder="Nombre de la región" 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Masculino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioMasc} 
              onChange={(e) => setGentilicioMasc(e.target.value)} 
              placeholder="Gentilicio masculino (opcional)" 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Femenino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioFem} 
              onChange={(e) => setGentilicioFem(e.target.value)} 
              placeholder="Gentilicio femenino (opcional)" 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Abreviatura</Form.Label>
            <Form.Control 
              type="text" 
              value={abrev} 
              onChange={(e) => setAbrev(e.target.value)} 
              placeholder="Abreviatura (opcional)" 
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>País</Form.Label>
            <Form.Control 
              as="select" 
              value={idPais} 
              onChange={(e) => setIdPais(e.target.value)} 
              required
            >
              <option value="">Seleccione un país</option>
              {paises.map(pais => (
                <option key={pais.id} value={pais.id}>
                  {pais.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={currentRegion && !isModified}
            >
              {currentRegion ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegionForm;
