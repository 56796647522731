import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "grados"
const gradoService = apiService('/grados');

// Thunks para las operaciones CRUD
export const fetchGrados = createAsyncThunk('grado/fetchGrados', async () => {
  const response = await gradoService.getAll();
  return response || [];
});

export const fetchGradoById = createAsyncThunk('grado/fetchGradoById', async (id) => {
  const response = await gradoService.getById(id);
  return response;
});

export const addGrado = createAsyncThunk('grado/addGrado', async (grado, { rejectWithValue }) => {
  try {
    const response = await gradoService.create(grado);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyGrado = createAsyncThunk('grado/modifyGrado', async ({ id, grado }, { rejectWithValue }) => {
  try {
    const response = await gradoService.update(id, grado);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeGrado = createAsyncThunk('grado/removeGrado', async (id, { rejectWithValue }) => {
  try {
    await gradoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial
const initialState = {
  grados: [],
  current: null,
  status: 'idle',
  error: null,
};

// Slice de Grados
const gradoSlice = createSlice({
  name: 'grado',
  initialState,
  reducers: {
    setCurrentGrado(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGrados.fulfilled, (state, action) => {
        state.grados = action.payload;
      })
      .addCase(addGrado.fulfilled, (state, action) => {
        state.grados.push(action.payload);
      })
      .addCase(modifyGrado.fulfilled, (state, action) => {
        const index = state.grados.findIndex(grado => grado.id === action.payload.id);
        if (index !== -1) {
          state.grados[index] = action.payload;
        }
      })
      .addCase(removeGrado.fulfilled, (state, action) => {
        state.grados = state.grados.filter(grado => grado.id !== action.payload);
      });
  }
});

export const { setCurrentGrado } = gradoSlice.actions;
export default gradoSlice.reducer;
