import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "indices"
const indiceCalculoService = apiService('/indices-calculo');

// Thunks para las operaciones CRUD
export const fetchIndicesCalculo = createAsyncThunk('indice/fetchIndicesCalculo', async () => {
  const response = await indiceCalculoService.getAll();
  return response || [];
});

export const fetchIndicesCalculoByCategoriaId = createAsyncThunk('indice/fetchIndicesCalculo', async (idcategoria) => {
  const response = await indiceCalculoService.getAllBySomeId("categoria", idcategoria);
  return response;
});

export const addIndiceCalculo = createAsyncThunk('indice/addIndiceCalculo', async (indiceCalculo, { rejectWithValue }) => {
  try {
    const response = await indiceCalculoService.create(indiceCalculo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyIndiceCalculo = createAsyncThunk('indice/modifyIndiceCalculo', async ({ id, indiceCalculo }, { rejectWithValue }) => {
  try {
    const response = await indiceCalculoService.update(id, indiceCalculo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeIndiceCalculo = createAsyncThunk('indice/removeIndiceCalculo', async (id, { rejectWithValue }) => {
  try {
    await indiceCalculoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  indices: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const indiceCalculoSlice = createSlice({
  name: 'indiceCalculo',
  initialState,
  reducers: {
    setCurrentIndiceCalculo(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndicesCalculo.fulfilled, (state, action) => {
        state.indices = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchIndicesCalculo.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchIndicesCalculo.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(addIndiceCalculo.fulfilled, (state, action) => {
        state.indices.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addIndiceCalculo.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addIndiceCalculo.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyIndiceCalculo.fulfilled, (state, action) => {
        const index = state.indices.findIndex(indice => indice.id === action.payload.id);
        if (index !== -1) {
          state.indices[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyIndiceCalculo.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyIndiceCalculo.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeIndiceCalculo.fulfilled, (state, action) => {
        state.indices = state.indices.filter(indice => indice.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeIndiceCalculo.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeIndiceCalculo.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentIndiceCalculo, resetStatusSend } = indiceCalculoSlice.actions;

export default indiceCalculoSlice.reducer;
