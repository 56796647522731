import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { generarCuotasPorRango, obtenerCuotasGeneradas } from '../../reducers/cuota_slice';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const GenerarCuotas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Instanciar useNavigate
    const [cuotaInicial, setCuotaInicial] = useState(`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`);
    const [cuotaFinal, setCuotaFinal] = useState(cuotaInicial);
    const [showConfirm, setShowConfirm] = useState(false);
    const userId = useSelector(state => state.sesion.id);
    const cuotasGeneradas = useSelector(state => state.cuota.cuotasGeneradas);

    useEffect(() => {
        dispatch(obtenerCuotasGeneradas())
            .then((result) => {
                console.log("Resultado de dispatch en obtenerCuotasGeneradas:", result);
            })
            .catch(error => console.error("Error al despachar obtenerCuotasGeneradas:", error));
    }, [dispatch]);

    const verificarRango = () => {
        if (!cuotaInicial || !cuotaFinal) {
            Swal.fire('Error', 'Cuota inicial o final no definida correctamente.', 'error');
            return false;
        }

        const gestionInicial = parseInt(cuotaInicial.split('-')[0]);
        const mesInicial = parseInt(cuotaInicial.split('-')[1]);
        const gestionFinal = parseInt(cuotaFinal.split('-')[0]);
        const mesFinal = parseInt(cuotaFinal.split('-')[1]);

        for (const cuota of cuotasGeneradas) {
            if (!cuota.fecha_generacion) {
                console.error(`Fecha inválida: ${cuota.fecha_generacion}`);
                continue;
            }

            const gestion = parseInt(cuota.cuota.split('-')[0]);
            const mes = parseInt(cuota.cuota.split('-')[1]);

            if (
                (gestion > gestionInicial || (gestion === gestionInicial && mes >= mesInicial)) &&
                (gestion < gestionFinal || (gestion === gestionFinal && mes <= mesFinal))
            ) {
                return true;  // Se encontró una cuota en el rango
            }
        }
        return false;  // No hay cuotas en el rango
    };

    const handleGenerar = () => {
        console.log("Entro a HandleGenerar");

        if (verificarRango()) {
            Swal.fire('Advertencia', 'Ya existen cuotas generadas en el rango especificado. Por favor, ajuste el rango.', 'warning');
            return;
        }

        if (!userId) {
            Swal.fire('Error', 'No se ha identificado el usuario.', 'error');
            return;
        }

        dispatch(generarCuotasPorRango({
            cuota_inicial: cuotaInicial,
            cuota_final: cuotaFinal,
            id_usuario: userId
        }))
        .then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                Swal.fire('Éxito', 'Cuotas generadas correctamente.', 'success').then(() => {
                    navigate('/admin'); // Redirigir al menú principal
                });
            } else {
                Swal.fire('Error', result.payload || 'Ocurrió un error al generar las cuotas.', 'error');
            }
        })
        .catch((error) => {
            Swal.fire('Error', error.message || 'Ocurrió un error inesperado.', 'error');
        });

        setShowConfirm(false);
    };

    const handleCancel = () => {
        const currentMonthYear = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`;
        setCuotaInicial(currentMonthYear);
        setCuotaFinal(currentMonthYear);
        navigate('/admin'); // Redirigir al menú principal al cancelar
    };

    return (
        <div className="list-container list3">
            <h1 className="table-title">Generar Cuotas para un Rango Específico</h1>
            <p className="text-danger">Este proceso es delicado y su ejecución es irreversible.</p>

            <div className="list-content">
                <Form>
                    <Form.Group controlId="cuotaInicial">
                        <Form.Label>Cuota Inicial (YYYY-MM):</Form.Label>
                        <Form.Control
                            type="text"
                            value={cuotaInicial}
                            onChange={(e) => setCuotaInicial(e.target.value)}
                            style={{ width: '100px' }}
                        />
                    </Form.Group>

                    <Form.Group controlId="cuotaFinal">
                        <Form.Label>Cuota Final (YYYY-MM):</Form.Label>
                        <Form.Control
                            type="text"
                            value={cuotaFinal}
                            onChange={(e) => setCuotaFinal(e.target.value)}
                            style={{ width: '100px' }}
                        />
                    </Form.Group>

                    <div className="list-controls mt-4">
                        <Button variant="danger" onClick={() => setShowConfirm(true)}>
                            Iniciar Generación de Cuotas
                        </Button>
                        <Button variant="secondary" className="ms-2" onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </div>
                </Form>

                <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">¡Advertencia!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
    <p>Este proceso generará cuotas en el rango especificado. ¿Desea continuar?</p>
    <p className="text-danger">
        El sistema generará las cuotas en el rango de fechas configurado, tanto para el CAT como para el CAB. 
        Para que la generación se realice de forma satisfactoria, es necesario configurar previamente los montos 
        para el CAT y el CAB de las diferentes situaciones en las que se encuentran los afiliados. 
        Las cuotas no serán generadas para aquellas situaciones en las que el monto sea 0. 
        Es importante recalcar que la generación de cuotas es necesaria para poder realizar los cobros correspondientes.
    </p>
    <p className="text-danger">Esta acción es irreversible. ¿Está seguro de que desea continuar?</p>
</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirm(false)}>Cancelar</Button>
                        <Button variant="danger" onClick={handleGenerar}>
                            Confirmar y Continuar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default GenerarCuotas;
