import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNoticia, modifyNoticia } from '../../reducers/noticia_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Swal from 'sweetalert2';
import { getUploadUrl } from '../../util/valueCalculator';
import userImgPlaceholder from '../../assets/usuario.png';

const NoticiasForm = ({ currentNoticia, showModal, handleClose }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.sesion.id); // Obtener userId del estado global
  const [datos, setDatos] = useState({
    tituloCorto: '',
    tituloLargo: '',
    descripcion: '',
    fechaIni: '',
    fechaFin: '',
    url: '',
  });
  const [fotoPreview, setFotoPreview] = useState(userImgPlaceholder);
  const [fotoNuevaPreview, setFotoNuevaPreview] = useState(null);
  const [foto, setFoto] = useState(null);

  useEffect(() => {
    if (currentNoticia) {
      setDatos({
        tituloCorto: currentNoticia.tituloCorto,
        tituloLargo: currentNoticia.tituloLargo,
        descripcion: currentNoticia.descripcion || '',
        fechaIni: currentNoticia.fechaIni,
        fechaFin: currentNoticia.fechaFin || '',
        url: currentNoticia.url || '',
      });

      if (currentNoticia.foto) {
        setFotoPreview(getUploadUrl(currentNoticia.foto));
      } else {
        setFotoPreview(userImgPlaceholder);
      }
      setFotoNuevaPreview(null);
    } else {
      const now = new Date();
      const fiveDaysLater = new Date(now);
      fiveDaysLater.setDate(now.getDate() + 5);

      setDatos({
        tituloCorto: '',
        tituloLargo: '',
        descripcion: '',
        fechaIni: now.toISOString().split('T')[0], // Fecha actual
        fechaFin: fiveDaysLater.toISOString().split('T')[0], // +5 días
        url: '',
      });
      resetPhotos();
    }
  }, [currentNoticia]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDatos({ ...datos, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFoto(file);
      setFotoNuevaPreview(URL.createObjectURL(file));
    }
  };

  const resetPhotos = () => {
    setFoto(null);
    setFotoPreview(userImgPlaceholder);
    setFotoNuevaPreview(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Añadir datos al formData
    Object.keys(datos).forEach((key) => {
      if (datos[key]) {
        formData.append(key, datos[key]);
      }
    });

    // Añadir idUsuario explícitamente
    formData.append('idUsuario', userId);

    if (foto) {
      formData.append('foto', foto);
    }

    if (currentNoticia) {
      dispatch(modifyNoticia({ id: currentNoticia.id, formData }))
        .then(() => {
          Swal.fire('Éxito', 'La noticia se actualizó correctamente.', 'success');
          handleClose();
        })
        .catch(() => Swal.fire('Error', 'No se pudo actualizar la noticia.', 'error'));
    } else {
      dispatch(addNoticia(formData))
        .then(() => {
          Swal.fire('Éxito', 'La noticia se creó correctamente.', 'success');
          handleClose();
        })
        .catch(() => Swal.fire('Error', 'No se pudo crear la noticia.', 'error'));
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{currentNoticia ? 'Editar Noticia' : 'Nueva Noticia'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          {/* Sección de Fotos */}
          <div className="w-50 me-4">
            <h6>Foto Actual</h6>
            <Image src={fotoPreview} alt="Foto actual" fluid />
            {fotoNuevaPreview && (
              <>
                <h6 className="mt-3">Foto Nueva</h6>
                <Image src={fotoNuevaPreview} alt="Foto nueva" fluid />
              </>
            )}
            <Form.Group className="mt-3">
              <Form.Label>Seleccionar Foto</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </div>

          {/* Sección de Datos */}
          <div className="w-50">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Título Corto</Form.Label>
                <Form.Control
                  type="text"
                  name="tituloCorto"
                  value={datos.tituloCorto}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Título Largo</Form.Label>
                <Form.Control
                  type="text"
                  name="tituloLargo"
                  value={datos.tituloLargo}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  name="descripcion"
                  value={datos.descripcion}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="url"
                  name="url"
                  value={datos.url}
                  onChange={handleInputChange}
                  placeholder="https://example.com"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Fecha Inicio</Form.Label>
                <Form.Control
                  type="date"
                  name="fechaIni"
                  value={datos.fechaIni}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Fecha Fin</Form.Label>
                <Form.Control
                  type="date"
                  name="fechaFin"
                  value={datos.fechaFin}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button type="submit" variant="primary" className="mt-3">
                {currentNoticia ? 'Actualizar' : 'Crear'}
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NoticiasForm;
