import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { fetchIndicesCalculoByCategoriaId, removeIndiceCalculo } from '../../reducers/indice_calculo_slice';
import { formatNumber } from '../../util/customFormatter';

const ListIndicesCalculo = ({currentCategoria}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const indicesCalculo = useSelector(state => state.indiceCalculo.indices || []);

  const [showModal, setShowModal] = useState(false);
  const [currentIndice, setCurrentIndice] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    if(currentCategoria)
      {dispatch(fetchIndicesCalculoByCategoriaId(currentCategoria.id));}
  }, [dispatch,currentCategoria]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeIndiceCalculo(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La categoría ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la categoría', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if(sortConfig.direction === 'desc'){
        setSortConfig({ key: null, direction: 'asc' });
        return;
    }
    
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = indice => {
    setCurrentIndice(indice);
    setShowModal(true);
  };


  const sortedIndices = useMemo(() => {
    let sortedData = indicesCalculo;
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [indicesCalculo, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Nombre', 'Costo Mes (CM)']],
      body: sortedIndices.map((indice, index) => [
        index+1,
        indice.nombre,
        indice.costo_mes,
      ])
    };
    return content;
  };

  return (
   <>
        {indicesCalculo.length === 0 ? (
          <p>No hay índices disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr className="text-center">
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('num')}>
                    Índice de cálculo
                    {sortConfig.key === 'num' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('nombre')}>
                    Superficie Mínima (m2)
                    {sortConfig.key === 'nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('nombre')}>
                    Superficie Máxima (m2)
                    {sortConfig.key === 'nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                 
                  {/* <th className='col-simple-actions'>Acciones</th> */}
                </tr>
              </thead>
              <tbody>
                {sortedIndices.map((indice, index) => (
                  <tr key={indice.id} className="text-center">
                    <td>{index + 1}</td>
                    <td>{formatNumber(parseFloat(indice.indice_calculo))}</td>
                    <td >{indice.superficie_min}</td>
                    <td>{indice.superficie_max || "Sin límite"}</td>
                    {/* <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(indice)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(indice.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
 </>
  );
};

export default ListIndicesCalculo;
