import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "roles"
const rolService = apiService('/roles');

// Thunks para las operaciones CRUD

// Obtener todos los roles
export const fetchRoles = createAsyncThunk('rol/fetchRoles', async () => {
  const response = await rolService.getAll();
  return response || [];
});

// Obtener un rol por ID
export const fetchRolById = createAsyncThunk('rol/fetchRolById', async (id) => {
  const response = await rolService.getById(id);
  return response;
});

// Crear un nuevo rol
export const addRol = createAsyncThunk('rol/addRol', async (rol, { rejectWithValue }) => {
  try {
    const response = await rolService.create(rol);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Modificar un rol existente
export const modifyRol = createAsyncThunk('rol/modifyRol', async ({ id, rol }, { rejectWithValue }) => {
  try {
    const response = await rolService.update(id, rol);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Modificar roles de un rol
export const modifyProcesosRol = createAsyncThunk('rol/modifyRol', async ({ id, procesos }, { rejectWithValue }) => {
  try {
    const response = await rolService.updateSomeDatoJ("procesos", id, procesos);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
// Eliminar un rol
export const removeRol = createAsyncThunk('rol/removeRol', async (id, { rejectWithValue }) => {
  try {
    await rolService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  roles: [],
  current: null,
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  }
};

// Definición del slice
const rolSlice = createSlice({
  name: 'rol',
  initialState,
  reducers: {
    setCurrentRol(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchRoles.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRolById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addRol.fulfilled, (state, action) => {
        state.roles.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addRol.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addRol.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyRol.fulfilled, (state, action) => {
        const index = state.roles.findIndex(rol => rol.id === action.payload.id);
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyRol.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyRol.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(removeRol.fulfilled, (state, action) => {
        state.roles = state.roles.filter(rol => rol.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeRol.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeRol.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

// Exportar las acciones y el reducer
export const { setCurrentRol, resetStatusSend } = rolSlice.actions;

export default rolSlice.reducer;
