import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTiposProyecto, removeTipoProyecto } from '../../reducers/tipoProyecto_slice';
import TipoProyectoForm from './TipoProyectoForm';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import FormValoradosTipoProyecto from './FormValoradosTipoProyecto';

const TipoProyectoList = () => {
  const dispatch = useDispatch();
  const tipoProyectoState = useSelector(state => state.tipoProyecto || { tipos: [] });
  const tipos = tipoProyectoState.tipos || [];
  const [showModal, setShowModal] = useState(false);
  const [currentTipoProyecto, setCurrentTipoProyecto] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showValoradoModal, setValoradoModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTiposProyecto());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeTipoProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El tipo de proyecto ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el tipo de proyecto', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if(sortConfig.direction === 'desc'){
        setSortConfig({ key: null, direction: 'asc' });
        return;
    }
    
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = tipo => {
    setCurrentTipoProyecto(tipo);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentTipoProyecto(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentTipoProyecto(null);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    // Combinar las cabeceras y los datos
    const exportData = [...contentArrays.head, ...contentArrays.body];
    // Crear la hoja de trabajo con las cabeceras personalizadas
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "TiposProyecto");
    XLSX.writeFile(workbook, "tiposProyecto.xlsx");
  };

  const sortedTipos = useMemo(() => {
    let sortedData = [...tipos];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [tipos, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };
  
  const handleManageValorados = tipo => {
    setCurrentTipoProyecto(tipo);
    setValoradoModal(true);
};

const handleCloseValoradosModal = () => {
    setValoradoModal(false);
    setCurrentTipoProyecto(null);
    dispatch(fetchTiposProyecto());
};
  const getFormulaHonorarios= (tipoProyecto) => {
    const parentesis= tipoProyecto.adicion_costo_mes !== "0.00"
    const suma = tipoProyecto.adicion_costo_mes>0
    const valor_adicional = tipoProyecto.adicion_costo_mes*100

    return `M2 x IC x ${parentesis? "(CM ":"CM"} ${suma? "+": ""} ${parentesis? valor_adicional : ""} ${parentesis? "%)" :""}`
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Nombre','Descripción', 'Fórmula de cálculo de honorarios']],
      body: sortedTipos.map((tipo, index) => [
        index+1,
        tipo.nombre,
        tipo.descripcion,
        getFormulaHonorarios(tipo),
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Tipos de Proyecto</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6">
           
          </div>
          <div className="col-md-6">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Tipo de Proyecto
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {tipos.length === 0 ? (
          <p>No hay tipos de proyecto disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('descripcion')}>
                    Nombre
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Descripción
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th>Fórmula de cálculo de honorarios</th>
          
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedTipos.map((tipo, index) => {
                
                  return (
                  <tr key={tipo.id}>
                    <td>{index + 1}</td>
                    <td>{tipo.nombre}</td>
                    <td>{tipo.descripcion}</td>
                    <td>{getFormulaHonorarios(tipo)}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(tipo)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(tipo.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                      <button className="btn-sm btn-secondary-outline" title="Gestionar valorados de este tipo de proyecto" onClick={() => handleManageValorados(tipo)}>
                            Valorados
                        </button>
                    </td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        <TipoProyectoForm currentTipoProyecto={currentTipoProyecto} showModal={showModal} handleClose={handleClose} />
        {showValoradoModal && currentTipoProyecto && (
                <FormValoradosTipoProyecto show={showValoradoModal} handleClose={handleCloseValoradosModal} tipo={currentTipoProyecto} />
            )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE TIPOS DE PROYECTO"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default TipoProyectoList;
