import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTipologia, modifyTipologia, fetchTipologias } from '../../reducers/tipologia_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatearDecimal } from '../../util/customFormatter';
import { fetchCategorias } from '../../reducers/categoria_slice';
import { fetchAreasProyecto } from '../../reducers/areaProyecto_slice';

const initialState = {
    descripcion: "",
    id_categoria: "",
    id_area: "",
}
const initialFormDetalles = {title:"Nueva Tipología",
    headerColor:"#254159", 
    buttonText:"Crear" }

const FormTipologia = ({ currentTipologia, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.tipologia.statusSend);
    const errorInRequest = useSelector(state => state.tipologia.error);
    const categorias = useSelector(state => state.categoria.categorias || [] );
    const areas = useSelector(state => state.areaProyecto.areas || []);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    useEffect(() => {
        if(showModal)
       { if (currentTipologia) {
            setFormDetalles({title:"Editar Tipología",headerColor:"#255957", buttonText:"Actualizar" })
            setDatos(currentTipologia);
            setIsModified(false);
        } else {
            resetForm();
        }
        dispatch(fetchCategorias());
        dispatch(fetchAreasProyecto());}

    }, [currentTipologia, showModal]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            const tipologiaData = { ...datos, descripcion: datos.descripcion };

            if (currentTipologia) {
                dispatch(modifyTipologia({ id: currentTipologia.id, tipologia: tipologiaData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar el tipologia de proyecto.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchTipologias());
                    }
                });
            } else {
                dispatch(addTipologia(tipologiaData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el tipologia de proyecto.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchTipologias());
                    }
                });
            }
        } else {
            handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setError('');
        setIsModified(false);
        setFormDetalles(initialFormDetalles);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row xs={1}>
                        <Col className='col-form'>
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                type="text"
                                name="descripcion"
                                value={datos.descripcion}
                                onChange={handleInputChange}
                                required
                            />
                        </Col>
                        </Row>
                        <Row xs={1} md={2}>
                        <Col className='col-form'>
                        <Form.Label>Categoría</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_categoria"
                                value={datos.id_categoria}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecciona una categoría</option>
                                {categorias.map(categoria =>
                                 (<option key={categoria.id} value={categoria.id}>{`${categoria.nombre}`}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col className='col-form'>
                        <Form.Label>Rubro</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_area"
                                value={datos.id_area}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecciona un rubro</option>
                                {areas.map(area =>
                                 (<option key={area.id} value={area.id}>{`${area.nombre}`}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={ handleClose}>
                            Cancelar
                        </Button>
                        <ActionButton outline>
                            {formDetalles.buttonText}
                        </ActionButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FormTipologia;
