import Footer from "../components/templates/PublicFooter";
import PublicHeader from "../components/templates/PublicHeader";
import "../styles/Inicio.scss";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDatGeneral } from "../reducers/datGeneral_slice";
import { fetchCajasDiariasByUsuario } from "../reducers/caja_slice"; // Importar el thunk para cajas diarias

const PublicLayout = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.sesion.id);

  useEffect(() => {
    const usuarioId = userId; // Obtiene el ID del usuario desde localStorage (ajusta esto si es necesario)
    console.log("usuarioId=",usuarioId)
    if (usuarioId) {
      dispatch(fetchCajasDiariasByUsuario(usuarioId)); // Llamar al thunk para cajas diarias
    }
    dispatch(fetchDatGeneral()); // Llamar al thunk para cargar datos generales
  }, [dispatch]);

  return (
    <div className="app-container">
      <PublicHeader />
      <main className="main-public-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default PublicLayout;
