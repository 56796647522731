import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "afiliados"
const afiliadoService = apiService('/afiliados');

// Thunks para las operaciones CRUD
export const fetchAfiliados = createAsyncThunk('afiliado/fetchAfiliados', async () => {
  const response = await afiliadoService.getAll();
  return response || [];
});
export const fetchInscripcionesAfiliados = createAsyncThunk('afiliado/fetchAfiliados', async () => {
  const response = await afiliadoService.getAllBySomeId("inscripciones","");
  return response || [];
});


export const fetchAfiliadoById = createAsyncThunk('afiliado/fetchAfiliadoById', async (id) => {
  const response = await afiliadoService.getById(id);
  return response;
});

export const addAfiliado = createAsyncThunk('afiliado/addAfiliado', async (afiliado, { rejectWithValue }) => {
  try {
    const response = await afiliadoService.create(afiliado);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
 export const inscribirAfiliado = createAsyncThunk('afiliado/inscribirAfiliado', async ({ id, afiliado }, { rejectWithValue }) => {
    try {
      const response = await afiliadoService.updateSomeDato("inscripcion", id, afiliado);
      return response;
    }  catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

export const modifyAfiliado = createAsyncThunk('afiliado/modifyAfiliado', async ({ id, afiliado }, { rejectWithValue }) => {
  try {
    const response = await afiliadoService.update(id, afiliado);
    return response;
  }  catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const modifySituacionAfiliado = createAsyncThunk('afiliado/modifySituacionAfiliado', async ({ id, afiliado }, { rejectWithValue }) => {
  try {
    const response = await afiliadoService.updateSomeDato("situacion", id, afiliado);
    return response;
  }  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeAfiliado = createAsyncThunk('afiliado/removeAfiliado', async (id, { rejectWithValue }) => {
  try {
    await afiliadoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  afiliados: [],
  sortedFilteredList: null,
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const afiliadoSlice = createSlice({
  name: 'afiliado',
  initialState,
  reducers: {
    setSortedFilteredList(state, action) {
      state.sortedFilteredList = action.payload;
    },
    setCurrentAfiliado(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAfiliados.fulfilled, (state, action) => {
        state.afiliados = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchAfiliados.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchAfiliados.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAfiliadoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addAfiliado.fulfilled, (state, action) => {
        state.afiliados.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addAfiliado.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addAfiliado.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyAfiliado.fulfilled, (state, action) => {
        const index = state.afiliados.findIndex(afiliado => afiliado.id === action.payload.id);
        if (index !== -1) {
          state.afiliados[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyAfiliado.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyAfiliado.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifySituacionAfiliado.fulfilled, (state, action) => {
        state.statusSend = 'idle';
      })
      .addCase(modifySituacionAfiliado.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifySituacionAfiliado.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeAfiliado.fulfilled, (state, action) => {
        state.afiliados = state.afiliados.filter(afiliado => afiliado.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeAfiliado.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeAfiliado.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const afiliadoActions = afiliadoSlice.actions;

export default afiliadoSlice.reducer;
