import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { afiliadoActions, fetchAfiliados, removeAfiliado } from '../../reducers/afiliado_slice.js';
import AfiliadoForm from './AfiliadoForm.js';
import RequisitoModal from './RequisitoModal.js';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton.js';
import CustomPrintModal from '../UI/CustomPrintModal.js';
import moment from 'moment';
import { formatearFechaSimple, fullNamePersona } from '../../util/customFormatter.js';
import { Form } from 'react-bootstrap';
import { fetchSituaciones } from '../../reducers/situacion_slice.js';
import PersonaForm from '../persona/PersonaForm.js';
import InscripcionModal from './InscripcionModal.js';
import ReactSelect from 'react-select';
import CambioSituacionModal from './CambioSituacionModal.js';
import AfiliadoList from './AfiliadoList.js';
import { personaActions } from '../../reducers/persona_slice.js';

const GestionAfiliados = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const afiliadoState = useSelector(state => state.afiliado);
  const situaciones = useSelector(state => (state.situacion.situaciones || [])) ;
  const [selectedSituaciones, setSelectedSituaciones] = useState([]); 
  const currentAfiliado = afiliadoState.current;
  const afiliados = afiliadoState.afiliados || [];
  const sortedFilteredAfiliados = afiliadoState.sortedFilteredList || afiliados;
  const [showModal, setShowModal] = useState(false);
  const [showModalDatosPersona, setShowModalDatosPersona] = useState(false);
  const [showModalInscripcion, setShowModalInscripcion] = useState(false);
  const [showModalCambioSituacion, setShowModalCambioSituacion] = useState(false);
  const [showRequisitosModal, setShowRequisitosModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });


  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchSituaciones());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeAfiliado(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El afiliado ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el afiliado', 'error');
        });
      }
    });
  };

  const handleEdit = afiliado => {
    dispatch(afiliadoActions.setCurrentAfiliado(afiliado));
    setShowModal(true);
  };

  const handleEditPersona = afiliado => {
    dispatch(personaActions.setCurrentPersona(afiliado.persona));
    setShowModalDatosPersona(true);
  };
  const handleCreate = () => {
    dispatch(personaActions.setCurrentPersona(null));
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    dispatch(afiliadoActions.setCurrentAfiliado(null));
  };
  const handleInscripcion = afiliado => {
    dispatch(afiliadoActions.setCurrentAfiliado(afiliado));
    setShowModalInscripcion(true);
  };

  const handleCloseInscripcion = () => {
    setShowModalInscripcion(false);
    dispatch(afiliadoActions.setCurrentAfiliado(null));
  };

  const handleCambioSituacion = afiliado => {
    dispatch(afiliadoActions.setCurrentAfiliado(afiliado));
    setShowModalCambioSituacion(true);
  };

  const handleCloseCambioSituacion = () => {
    setShowModalCambioSituacion(false);
    dispatch(afiliadoActions.setCurrentAfiliado(null));
  };
  const handleCloseDatosPersona = () => {
    setShowModalDatosPersona(false);
    dispatch(afiliadoActions.setCurrentAfiliado(null));
  };
  const handleRequisitos = afiliado => {
    dispatch(afiliadoActions.setCurrentAfiliado(afiliado));
    setShowRequisitosModal(true);
  };

  const handleCloseRequisitos = () => {
    setShowRequisitosModal(false);
    dispatch(afiliadoActions.setCurrentAfiliado(null));
  };

  const handleBack = () => {
    navigate('/admin');
  };
  const handleShowPreview = () => {
    // Usamos los datos ya ordenados y filtrados (sortedFilteredAfiliados) para la impresión
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };
   // Manejo de la selección múltiple
   const handleSituacionChange = (selectedOptions) => {
    setSelectedSituaciones(selectedOptions || []); // Actualiza el estado con las opciones seleccionadas
  };

//EXCEL
const handleExport = () => {
  const contentArrays = getPreviewContent()
  // Combinar las cabeceras y los datos
  const exportData = [...contentArrays.head, ...contentArrays.body];
  // Crear la hoja de trabajo con las cabeceras personalizadas
  const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados");
  // Exportar el archivo Excel
  XLSX.writeFile(workbook, "afiliados.xlsx");
};


//IMPRESION
  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Ap. Paterno', 'Ap. Materno', 'Nombre', 'C.I.', 'N° de registro', 'Fecha de registro', 'Situación']],
      body: sortedFilteredAfiliados.map((afiliado, index) => [
        index+1,
        afiliado.persona.apellido1,
        afiliado.persona.apellido2,
        afiliado.persona.nombre,
        afiliado.persona.ci,
        afiliado.num_reg|| 'N/A',
        afiliado.fecha_reg_cab || 'N/A',
        afiliado.situacion.nombre || 'N/A',
      ])
    };
    return content;
  };
console.log("situaciones",situaciones)
  return (
    <div className="list-container list1">
      <h1 className='table-title'>Lista de Afiliados</h1>
      <div className="list-content">
        <div className='list-controls'>
        <div className="col-md-4 mt-2"> </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent mr-3"><i className="bi bi-arrow-left"></i></button>
            <ReactSelect
                isMulti
                options={situaciones.filter(s=>s.alias && !s.alias.includes("preinscrito")).map(situ => ({ value: situ.id, label: situ.nombre }))}
                value={selectedSituaciones}
                onChange={handleSituacionChange}
                placeholder="Filtrar por situaciones"
                className="basic-multi-select"
                classNamePrefix="select"
              />
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent mr-3"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
      {/* COMPONENTE LISTA DE AFILIADOS */}
       <AfiliadoList selectedSituaciones={selectedSituaciones}
                      handleEditPersona={handleEditPersona} 
                      handleEdit={handleEdit}
                      handleInscripcion={handleInscripcion}
                      handleRequisitos={handleRequisitos}
                      handleCambioSituacion={handleCambioSituacion}
                      handleDelete={handleDelete}/>




        {/* MODALES FUNCIONES DE GESTION */}
        <CambioSituacionModal currentAfiliado={currentAfiliado} showModal={showModalCambioSituacion} handleClose={handleCloseCambioSituacion} />
        <AfiliadoForm currentAfiliado={currentAfiliado} showModalForm={showModal} handleCloseForm={handleClose} />
        <PersonaForm showModal={showModalDatosPersona} actualizarListaFn={fetchAfiliados} handleClose={handleCloseDatosPersona} />
        <RequisitoModal afiliado={currentAfiliado} showModal={showRequisitosModal} handleClose={handleCloseRequisitos} />
        <InscripcionModal currentAfiliado={currentAfiliado} showModal={showModalInscripcion} handleClose={handleCloseInscripcion} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE AFILIADOS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default GestionAfiliados;
