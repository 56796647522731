import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "caja"
const cajaService = apiService('/cajas');

// Thunk para obtener todas las cajas
export const fetchCajas = createAsyncThunk('caja/fetchCajas', async () => {
  const response = await cajaService.getAll();
  return response;
});

// Thunk para agregar una nueva caja
export const addCaja = createAsyncThunk('caja/addCaja', async (newCaja) => {
  const response = await cajaService.create(newCaja);
  return response;
});

// Thunk para modificar una caja
export const modifyCaja = createAsyncThunk('caja/modifyCaja', async ({ id, caja }) => {
  const response = await cajaService.update(id, caja);
  return response;
});

// Thunk para eliminar una caja
export const deleteCaja = createAsyncThunk('caja/deleteCaja', async (id) => {
    console.log("Entro al slice de delete caja....",id)
  await cajaService.remove(id);
  return id;
});

// Thunk para obtener todas las cajas diarias por id de usuario
export const fetchCajasDiariasByUsuario = createAsyncThunk('caja/fetchCajasDiariasByUsuario', async (id_usuario) => {
  console.log("id=",id_usuario)
  const response = await cajaService.getAllBySomeId('cajas-diarias/usuario',id_usuario);
  return response;
});

export const addCajaDiaria = createAsyncThunk('caja/addCajaDiaria', async (newCajaDiaria) => {
  console.log("Datos de newCajaDiaria enviados al backend:", newCajaDiaria);
  
  try {
    const response = await cajaService.createSome('/cajas-diarias', newCajaDiaria);
    console.log("Respuesta del backend al crear caja_diaria:", response);
    return response;
  } catch (error) {
    console.error("Error al enviar la petición al backend:", error);
    throw error;  // Volvemos a lanzar el error para que sea manejado en el extraReducer
  }
});

// Thunk para modificar una caja diaria
export const modifyCajaDiaria = createAsyncThunk('caja/modifyCajaDiaria', async ({ id, cajaDiaria }) => {
  console.log("slice update cajadiaria=",cajaDiaria)
  const response = await cajaService.updateSomeDatoJ('/cajas-diarias',id, cajaDiaria);
  return response;
});

// Slice de caja
// Slice de caja
const cajaSlice = createSlice({
  name: 'caja',
  initialState: {
    cajas: [],
    cajasDiarias: [],
    currentCaja: null,
    idCajaDiaria: null, // Nuevo estado
    status: 'idle',
    error: null,
  },
  reducers: {
    setCurrentCaja(state, action) {
      state.currentCaja = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCajas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCajas.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cajas = action.payload;
      })
      .addCase(fetchCajas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCaja.fulfilled, (state, action) => {
        state.cajas.push(action.payload);
      })
      .addCase(modifyCaja.fulfilled, (state, action) => {
        const index = state.cajas.findIndex(caja => caja.id === action.payload.id);
        state.cajas[index] = action.payload;
      })
      .addCase(deleteCaja.fulfilled, (state, action) => {
        state.cajas = state.cajas.filter(caja => caja.id !== action.payload);
      })
      .addCase(addCajaDiaria.fulfilled, (state, action) => {
        if (!state.cajasDiarias) {
          state.cajasDiarias = [];
        }
        state.cajasDiarias.push(action.payload);
      })
      .addCase(modifyCajaDiaria.fulfilled, (state, action) => {
        const index = state.cajasDiarias.findIndex(cajaDiaria => cajaDiaria.id === action.payload.id);
        state.cajasDiarias[index] = action.payload;
      })
      .addCase(fetchCajasDiariasByUsuario.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cajasDiarias = action.payload;

        // Actualizar idCajaDiaria con la caja abierta
        const cajaAbierta = action.payload.find(
          (caja) => caja.fechaAper && !caja.fechaCierre
        );
        console.log("cajaAbierta=",cajaAbierta)
        state.idCajaDiaria = cajaAbierta ? cajaAbierta.id : null;
      });
  }
});

export const { setCurrentCaja } = cajaSlice.actions;
export default cajaSlice.reducer;
