import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCargos, removeCargo } from '../../reducers/cargo_slice';
import CargoForm from './CargoForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';

const CargoList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cargos = useSelector(state => state.cargo.cargos || []);
  const [showModal, setShowModal] = useState(false);
  const [currentCargo, setCurrentCargo] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchCargos());
  }, [dispatch]);

  const sortedCargos = cargos.slice().sort((a, b) => a.jerarquia - b.jerarquia);

  const handleDelete = index => {
    const cargo = sortedCargos[index];
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeCargo(cargo.id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El cargo ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar el cargo.', 'error');
        });
      }
    });
  };

  const handleEdit = index => {
    setCurrentCargo(sortedCargos[index]);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentCargo(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      sortedCargos.map((cargo, index) => ({
        Número: index + 1,
        Nombre: cargo.nombre,
        Jerarquía: cargo.jerarquia,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cargos");
    XLSX.writeFile(workbook, "cargos.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['Número', 'Nombre', 'Jerarquía']],
      body: sortedCargos.map((cargo, index) => [
        index + 1,
        cargo.nombre,
        cargo.jerarquia,
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Cargos</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nuevo Cargo
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className='table-container'>
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Jerarquía</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedCargos.map((cargo, index) => (
              <tr key={cargo.id}>
                <td>{index + 1}</td>
                <td>{cargo.nombre}</td>
                <td>{cargo.jerarquia}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(index)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(index)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {sortedCargos.length === 0 && (
          <p className="text-center mt-4">No hay cargos disponibles.</p>
        )}
      </div>
      <CargoForm currentCargo={currentCargo} showModal={showModal} handleClose={() => setShowModal(false)} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE CARGOS"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default CargoList;
