import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TablePersona from './TablePersona';

const initialState = {
    id_persona:"",
    personaSeleccionada:null 
}
const SelectPersona = ({
  onSelect,
  labelText,
  personaSelected,
  excludeList = null,
  simplePersona = null,
  permitirEdicion = true,
  actualizarListaFn = null,
}) => {
  const [datos, setDatos] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (personaSelected) {
      setDatos({ id_persona: personaSelected.id, personaSeleccionada: personaSelected });
    } else {
      setDatos(initialState);
    }
  }, [personaSelected]);

  const handleSelectPersona = (persona) => {
    if (permitirEdicion) {
      const personaId = persona ? persona.id : '';
      setDatos({ id_persona: personaId, personaSeleccionada: persona });
      onSelect(labelText.toLowerCase(), persona);
      setShowModal(false); // Cierra el modal después de seleccionar
    }
  };

  return (
    <>
      <Form.Label className="mt-1">{labelText}</Form.Label>
      {datos.personaSeleccionada ? (
        <div className="selected-container p-3 border rounded shadow-sm">
          <div>
            <strong>
              {`${datos.personaSeleccionada.nombre} ${datos.personaSeleccionada.apellido1} ${datos.personaSeleccionada.apellido2}`}
            </strong>
            <br />
            <span>C.I.: {datos.personaSeleccionada.ci}</span>
          </div>
          {permitirEdicion && (
            <Button
              variant="link"
              onClick={() => {
                setDatos(initialState);
                handleSelectPersona(null);
                setShowModal(true);
              }}
            >
              Cambiar persona
            </Button>
          )}
        </div>
      ) : (
        <div className="selected-container p-3 border rounded shadow-sm">
            <p>No se ha seleccionado una persona aún.</p>
          <Button variant="link" onClick={() => setShowModal(true)}>
            Seleccionar persona
          </Button>
        </div>
      )}

      {/* Modal para seleccionar persona */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar persona</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TablePersona
            onSelectPersona={handleSelectPersona}
            simplePersona={simplePersona}
            excludeList={excludeList}
            actualizarListaFn={actualizarListaFn}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectPersona;
