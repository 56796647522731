import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { iniciarBackup, resetState } from '../../reducers/backup_slice';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const BackupList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, successMessage } = useSelector((state) => state.backup);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetState());
  };

  const handleBackup = () => {
    dispatch(iniciarBackup())
      .unwrap()
      .then((result) => {
        Swal.fire('Éxito', result.message, 'success');
        handleCloseModal();
      })
      .catch((err) => {
        Swal.fire('Error', err || 'Ocurrió un error al realizar el backup.', 'error');
      });
  };

  const handleCancel = () => {
    navigate('/admin'); // Redirigir al menú principal al cancelar
  };

  return (
    <div className="list-container list2">
      <h1 className="table-title">Gestión de Backups</h1>
      <p className="mt-4">
        <strong>¿Qué es un backup de base de datos?</strong> <br />
        Un backup de base de datos es una copia de seguridad de la información almacenada en la base de datos de tu sistema. 
        Este proceso asegura que todos los datos críticos estén protegidos en caso de fallos técnicos, ataques cibernéticos o errores humanos.
      </p>
      <p>
        <strong>¿Por qué es importante?</strong> <br />
        Los backups son fundamentales para garantizar la continuidad del negocio. En caso de pérdida de datos, un backup te permitirá restaurar la información y minimizar interrupciones.
      </p>
      <p>
        <strong>Recomendación:</strong> <br />
        Realiza backups de tu base de datos de forma <u>periódica</u>. Esto asegura que siempre tengas acceso a una copia actualizada de tus datos y reduces el riesgo de perder información valiosa.
      </p>

      <div className="list-controls d-flex justify-content-end gap-3">
        <Button
          variant="outline-secondary"
          onClick={handleCancel}
          className="float-end"
        >
          <i className="bi bi-x-circle"></i> Cancelar
        </Button>
        <Button
          variant="outline-danger"
          onClick={handleOpenModal}
          className="float-end"
        >
          <i className="bi bi-shield-fill-exclamation"></i> Iniciar Backup
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="bi bi-exclamation-triangle-fill text-danger" style={{ fontSize: '2rem' }}></i>{' '}
            Confirmación de Backup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="text-danger fw-bold">
            Este proceso es delicado. Por favor, asegúrese de que nadie esté utilizando la base de datos mientras se realiza el backup.
          </p>
          <p>¿Desea proceder con el backup?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={handleBackup}
            disabled={isLoading}
          >
            {isLoading ? 'Procesando...' : 'Iniciar Backup'}
          </Button>
        </Modal.Footer>
      </Modal>

      {error && (
        <p className="text-danger mt-3">Error: {error}</p>
      )}
      {successMessage && (
        <p className="text-success mt-3">{successMessage}</p>
      )}
    </div>
  );
};

export default BackupList;
