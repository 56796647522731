import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "cargos"
const cargoService = apiService('/cargos');

// Thunks para las operaciones CRUD
export const fetchCargos = createAsyncThunk('cargo/fetchCargos', async () => {
  const response = await cargoService.getAll();
  return response || [];
});

export const fetchCargoById = createAsyncThunk('cargo/fetchCargoById', async (id) => {
  const response = await cargoService.getById(id);
  return response;
});

export const addCargo = createAsyncThunk('cargo/addCargo', async (cargo, { rejectWithValue }) => {
  try {
    const response = await cargoService.create(cargo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyCargo = createAsyncThunk('cargo/modifyCargo', async ({ id, cargo }, { rejectWithValue }) => {
  try {
    const response = await cargoService.update(id, cargo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeCargo = createAsyncThunk('cargo/removeCargo', async (id, { rejectWithValue }) => {
  try {
    await cargoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  cargos: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const cargoSlice = createSlice({
  name: 'cargo',
  initialState,
  reducers: {
    setCurrentCargo(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCargos.fulfilled, (state, action) => {
        state.cargos = action.payload;
      })
      .addCase(fetchCargos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCargos.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCargoById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addCargo.fulfilled, (state, action) => {
        state.cargos.push(action.payload);
      })
      .addCase(addCargo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCargo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyCargo.fulfilled, (state, action) => {
        const index = state.cargos.findIndex(c => c.id === action.payload.id);
        if (index !== -1) {
          state.cargos[index] = action.payload;
        }
      })
      .addCase(modifyCargo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyCargo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeCargo.fulfilled, (state, action) => {
        state.cargos = state.cargos.filter(c => c.id !== action.payload);
      })
      .addCase(removeCargo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeCargo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentCargo } = cargoSlice.actions;

export default cargoSlice.reducer;
