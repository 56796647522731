import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDepositos } from '../../reducers/deposito_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import { Table, Button, Spinner } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import DepositoForm from './DepositoForm';

const DepositoList = () => {
    const dispatch = useDispatch();
    const { depositos, statusGet, error } = useSelector(state => state.deposito);
    const personas = useSelector(state => state.persona.personas);

    const [showModal, setShowModal] = useState(false);
    const [selectedDeposito, setSelectedDeposito] = useState(null);

    useEffect(() => {
        dispatch(fetchAllDepositos());
        dispatch(fetchPersonas());
    }, [dispatch]);

    // Asociar personas con los depósitos
    const depositosConPersonas = depositos.map(deposito => {
        const persona = personas.find(p => p.ci === deposito.ci);
        return {
            ...deposito,
            persona: persona ? `${persona.nombre} ${persona.apellido1} ${persona.apellido2}` : 'No registrado',
        };
    });

    const handleOpenModal = (deposito) => {
        setSelectedDeposito(deposito);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedDeposito(null);
        setShowModal(false);
    };

    if (statusGet === 'loading') {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center text-danger">
                <p>Ocurrió un error al cargar los depósitos: {error}</p>
            </div>
        );
    }

    return (
        <div className="list-container list0">
            <h2 className="text-center">Lista de Depósitos</h2>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>CI</th>
                        <th>Nombre Completo</th>
                        <th>Monto Total</th>
                        <th>Usuario</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {depositosConPersonas.map((deposito, index) => (
                        <tr key={deposito.id}>
                            <td>{index + 1}</td>
                            <td>{deposito.fecha}</td>
                            <td>{deposito.ci}</td>
                            <td>{deposito.persona}</td>
                            <td>{parseFloat(deposito.monto_total).toFixed(2)} Bs.</td>
                            <td>{deposito.id_usuario}</td>
                            <td>
                                <Button variant="info" onClick={() => handleOpenModal(deposito)}>
                                    <BsEye /> Ver Detalles
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal para ver detalles */}
            {showModal && (
                <DepositoForm
                    show={showModal}
                    onHide={handleCloseModal}
                    deposito={selectedDeposito}
                />
            )}
        </div>
    );
};

export default DepositoList;
