import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addPais, modifyPais, fetchPaises } from '../../reducers/pais_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const PaisForm = ({ currentPais, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [gentilicioMasc, setGentilicioMasc] = useState('');
  const [gentilicioFem, setGentilicioFem] = useState('');
  const [abrev, setAbrev] = useState('');
  const [isModified, setIsModified] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPais) {
      setNombre(currentPais.nombre);
      setGentilicioMasc(currentPais.gentilicio_masc || '');
      setGentilicioFem(currentPais.gentilicio_fem || '');
      setAbrev(currentPais.abrev || '');
      setIsModified(false);
    } else {
      resetForm();
    }
  }, [currentPais]);

  useEffect(() => {
    if (currentPais) {
      const isDifferent =
        nombre !== currentPais.nombre ||
        gentilicioMasc !== (currentPais.gentilicio_masc || '') ||
        gentilicioFem !== (currentPais.gentilicio_fem || '') ||
        abrev !== (currentPais.abrev || '');
      setIsModified(isDifferent);
    }
  }, [nombre, gentilicioMasc, gentilicioFem, abrev, currentPais]);

  const handleSubmit = e => {
    e.preventDefault();
    const paisData = { 
      nombre, 
      gentilicio_masc: gentilicioMasc, 
      gentilicio_fem: gentilicioFem, 
      abrev 
    };

    if (currentPais) {
      dispatch(modifyPais({ id: currentPais.id, pais: paisData })).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo actualizar el país.', 'error');
        } else {
          Swal.fire('Éxito', 'El país se actualizó correctamente.', 'success');
          handleClose();
          dispatch(fetchPaises());
        }
      });
    } else {
      dispatch(addPais(paisData)).then(response => {
        if (response.error) {
          Swal.fire('Error', 'No se pudo crear el país.', 'error');
        } else {
          Swal.fire('Éxito', 'El país se creó correctamente.', 'success');
          handleClose();
          dispatch(fetchPaises());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setGentilicioMasc('');
    setGentilicioFem('');
    setAbrev('');
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentPais ? 'Editar País' : 'Nuevo País'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control 
              type="text" 
              value={nombre} 
              onChange={(e) => setNombre(e.target.value)} 
              required 
              placeholder="Nombre del país"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Masculino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioMasc} 
              onChange={(e) => setGentilicioMasc(e.target.value)} 
              placeholder="Gentilicio masculino (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gentilicio Femenino</Form.Label>
            <Form.Control 
              type="text" 
              value={gentilicioFem} 
              onChange={(e) => setGentilicioFem(e.target.value)} 
              placeholder="Gentilicio femenino (opcional)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Abreviatura</Form.Label>
            <Form.Control 
              type="text" 
              value={abrev} 
              onChange={(e) => setAbrev(e.target.value)} 
              placeholder="Abreviatura (opcional)"
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={currentPais && !isModified}
            >
              {currentPais ? 'Actualizar' : 'Crear'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaisForm;
