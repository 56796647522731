import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "cuotas"
const cuotaService = apiService('/cuotas');

export const updateCuotaFields = createAsyncThunk(
  'cuota/updateCuotaFields',
  async ({ id, monto_cab, monto_cat, descuento_cab, descuento_cat, observacion, id_usuario }, { rejectWithValue }) => {
    try {
      const response = await cuotaService.updateSomeDato(`update-fields/${id}`, {
        monto_cab,
        monto_cat,
        descuento_cab,
        descuento_cat,
        observacion,
        id_usuario
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const obtenerCuotasGeneradas = createAsyncThunk(
  'cuota/obtenerCuotasGeneradas',
  async (_, { rejectWithValue }) => {
     // console.log("Entro a slice cuota generada...")
      try {
          const response = await cuotaService.getAllSome('generadas');
          return response;
      } catch (error) {
          return rejectWithValue(error.response?.data || error.message);
      }
  }
);
export const generarCuotasPorRango = createAsyncThunk(
  'cuota/generarCuotasPorRango',
  async ({ cuota_inicial, cuota_final, id_usuario }, { rejectWithValue }) => {
      try {
          const response = await cuotaService.createSome('generar-rango', { cuota_inicial, cuota_final, id_usuario });
          return response;
      } catch (error) {
          return rejectWithValue(error.response?.data || error.message);
      }
  }
);
// Fetch all cuotas
export const fetchCuotas = createAsyncThunk('cuota/fetchCuotas', async () => {
  try {
    const response = await cuotaService.getAll();
    return response || [];
  } catch (error) {
    throw error;
  }
});

// Fetch cuotas by afiliado
export const fetchCuotasByAfiliado = createAsyncThunk('cuota/fetchCuotasByAfiliado', async (id_afiliado) => {
  try {
    const response = await cuotaService.getAllBySomeId('afiliado', id_afiliado);
    return response;
  } catch (error) {
    throw error;
  }
});

// Modify a set of cuotas for an affiliate
export const modifyCuota = createAsyncThunk('cuota/modifyCuota', async ({ id_afiliado, cuotas }, { rejectWithValue }) => {
  try {
    //console.log(`Modifying cuotas for afiliado ID: ${id_afiliado}`, cuotas);
    const response = await cuotaService.update(id_afiliado, cuotas); // Enviamos el conjunto de cuotas al backend
    return response;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Nueva acción para modificar cuotas con depósitos y manejar archivos
export const modifyCuotaWithDeposito = createAsyncThunk('cuota/modifyCuotaWithDeposito', async ({ id_afiliado, cuotas, depositos, recibo, reciboDetalles,cuentaCAT,montoCAT,cuentaCAB,montoCAB }, { rejectWithValue }) => {
  //console.log("datos cuentas=",cuentaCAT, montoCAT,cuentaCAB, montoCAB)
  try {
    const formData = new FormData();

    // Agregar los detalles de las cuotas, depósitos, recibo y reciboDetalles en FormData
    formData.append('data', JSON.stringify({ cuotas, depositos, recibo, reciboDetalles}));
    formData.append('cuentaCAT', cuentaCAT);
    formData.append('montoCAT', montoCAT);
    formData.append('cuentaCAB', cuentaCAB);
    formData.append('montoCAB', montoCAB);
    // Agregar los archivos de los depósitos
    depositos.forEach((deposito, index) => {
      formData.append(`depositos[${index}][id_banco]`, deposito.id_banco);
      formData.append(`depositos[${index}][fecha_deposito]`, deposito.fecha_deposito);
      formData.append(`depositos[${index}][monto]`, deposito.monto);
      formData.append(`depositos[${index}][docum]`, deposito.docum);
      if (deposito.archivo) {
        formData.append(`depositos[${index}][archivo]`, deposito.archivo);
      }
    });
    //console.log("formData en el slice...")
    //for (let pair of formData.entries()) {
    //  console.log(`${pair[0]}:`, pair[1]);
   // }
    // Enviar el FormData al backend
    const response = await cuotaService.updateSomeDato("depositos", id_afiliado, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Nueva acción para obtener un depósito, sus detalles y las cuotas asociadas
export const fetchDepositoConCuotas = createAsyncThunk('cuota/fetchDepositoConCuotas', async (id_deposito, { rejectWithValue }) => {
  try {
    const response = await cuotaService.getAllBySomeId('deposito', id_deposito);
    return response;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Slice definition
const cuotaSlice = createSlice({
  name: 'cuota',
  initialState: {
    cuotas: [],
    current: null,
    depositoConCuotas: null, // Nuevo estado para almacenar el depósito y cuotas relacionadas
    statusSend: 'idle',
    statusGet: 'idle',
    cuotasGeneradas: [],
    statusGeneradas: 'idle',
    errorGeneradas: null,
    error: {
      status: null,
      messages: []
    },
  },
  reducers: {
    setCurrentCuota(state, action) {
      state.current = action.payload;
    },
    setCuotas(state, action) {
      state.cuotas = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch all cuotas
       .addCase(updateCuotaFields.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(updateCuotaFields.fulfilled, (state, action) => {
        state.statusSend = 'idle';
        const updatedCuota = action.payload.cuota;
        const existingCuota = state.cuotas.find(cuota => cuota.id === updatedCuota.id);
        if (existingCuota) {
          Object.assign(existingCuota, updatedCuota);
        }
      })
      .addCase(updateCuotaFields.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(obtenerCuotasGeneradas.pending, (state) => {
        state.statusGeneradas = 'loading';
      })
      .addCase(obtenerCuotasGeneradas.fulfilled, (state, action) => {
          state.statusGeneradas = 'succeeded';
          state.cuotasGeneradas = action.payload;
      })
      .addCase(obtenerCuotasGeneradas.rejected, (state, action) => {
          state.statusGeneradas = 'failed';
          state.errorGeneradas = action.payload || action.error.message;
      })
      .addCase(fetchCuotas.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchCuotas.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.cuotas = action.payload;
      })
      .addCase(fetchCuotas.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      // Fetch cuotas by afiliado
      .addCase(fetchCuotasByAfiliado.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchCuotasByAfiliado.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.cuotas = action.payload;
      })
      .addCase(fetchCuotasByAfiliado.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      // Modify multiple cuotas
      .addCase(modifyCuota.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyCuota.fulfilled, (state, action) => {
        state.statusSend = 'idle';
        const updatedCuotas = action.payload.cuotasActualizadas;

        updatedCuotas.forEach((updatedCuota) => {
          const existingCuota = state.cuotas.find(cuota => cuota.id === updatedCuota.id);
          if (existingCuota) {
            Object.assign(existingCuota, updatedCuota);
          }
        });
      })
      .addCase(modifyCuota.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      // Modify cuotas with deposits
      .addCase(modifyCuotaWithDeposito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyCuotaWithDeposito.fulfilled, (state, action) => {
        state.statusSend = 'idle';
        const updatedCuotas = action.payload.cuotasActualizadas;

        updatedCuotas.forEach((updatedCuota) => {
          const existingCuota = state.cuotas.find(cuota => cuota.id === updatedCuota.id);
          if (existingCuota) {
            Object.assign(existingCuota, updatedCuota);
          }
        });
      })
      .addCase(modifyCuotaWithDeposito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      // Fetch deposito and related cuotas
      .addCase(fetchDepositoConCuotas.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchDepositoConCuotas.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositoConCuotas = action.payload;
      })
      .addCase(fetchDepositoConCuotas.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(generarCuotasPorRango.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(generarCuotasPorRango.fulfilled, (state) => {
          state.statusSend = 'idle';
      })
      .addCase(generarCuotasPorRango.rejected, (state, action) => {
          state.statusSend = 'failed';
          state.error = action.payload || action.error.message;
      });
  }
});


export const { setCurrentCuota, setCuotas, resetStatusSend } = cuotaSlice.actions;

export default cuotaSlice.reducer;
