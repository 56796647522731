import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchInmuebles, removeInmueble } from '../../reducers/inmueble_slice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import FormInmueble from './FormInmueble';
import ListInmuebles from './ListInmuebles';

const GestionInmuebles = () => {
  const dispatch = useDispatch();
  const inmuebles = useSelector(state => state.inmueble.inmuebles || []);
  const sortedFilteredInmuebles = useSelector(state => state.inmueble.sortedFilteredList || inmuebles);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchInmuebles());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeInmueble(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El inmueble ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el inmueble', 'error');
        });
      }
    });
  };

 

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    const exportData = [...contentArrays.head, ...contentArrays.body];
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); 
     const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inmuebles");
    XLSX.writeFile(workbook, "inmuebles.xlsx");
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Código Catastral', 'Zona']],
      body: sortedFilteredInmuebles.map((inmueble, index) => [
        index+1,
        inmueble.cod_catastral,
        inmueble.zona.nombre,
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Inmuebles</h1>
      <div className="list-content">
    
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        <ListInmuebles  handleDelete={handleDelete}/>
    
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE CATEGORÍAS DE LAS TIPOLOGÍAS DE PROYECTOS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default GestionInmuebles;
