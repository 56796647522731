import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCategoria, modifyCategoria, fetchCategorias } from '../../reducers/categoria_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatearDecimal } from '../../util/customFormatter';

const initialState = {
    nombre: "",
    costo_mes: "",
    num:""
}

const FormCategoria = ({ currentCategoria, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.categoria.statusSend);
    const errorInRequest = useSelector(state => state.categoria.error);

    useEffect(() => {
        if (currentCategoria) {
            setDatos(currentCategoria);
            setIsModified(false);
        } else {
            resetForm();
        }
    }, [currentCategoria]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            const categoriaData = { ...datos, nombre: datos.nombre.toUpperCase() };

            if (currentCategoria) {
                dispatch(modifyCategoria({ id: currentCategoria.id, categoria: categoriaData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar el categoria de proyecto.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchCategorias());
                    }
                });
            } else {
                dispatch(addCategoria(categoriaData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el categoria de proyecto.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchCategorias());
                    }
                });
            }
        } else {
            handleClose();
            resetForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    const handleFormat = e => {
        const { name, value } = e.target;
        const valueFormatted = name === 'costo_mes'? formatearDecimal(value) : value 
        setDatos((prevDatos) => ({ ...prevDatos, 
          [name]: valueFormatted
        }));
        setIsModified(true);
      };
      
    const handleNumeroEntero= e => {
        const { name, value } = e.target;
        let numero = parseInt(value, 10);
    
        // Asegurar que el valor esté entre -100 y 100
        if (numero < -100) {
            numero = 0;
        }
        // Actualizar el estado con el valor ajustado
        setDatos(prevDatos => ({
            ...prevDatos,
            [name]: numero
        }));
    };
    
    let title = currentCategoria ? "Editar Categoría" : "Nueva Categoría";
    let headerColor = currentCategoria ? '#255957' : '#254159';
    let buttonText = requestStatus !== "sending" ? (currentCategoria ? 'Actualizar' : 'Crear') : "Enviando...";

    return (
        <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row xs={1} md={2}>
                        <Col className='col-form'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                placeholder='Nombre de la categoría'
                                value={datos.nombre}
                                onChange={handleInputChange}
                                required
                                disabled={currentCategoria}
                            />
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Costo Mes</Form.Label>
                            <Form.Control
                                type="number"
                                step="0.01"
                                placeholder="0,00"
                                name="costo_mes"
                                value={datos.costo_mes}
                                onChange={handleInputChange}
                                onBlur={handleFormat}
                                required
                            />
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Número de orden</Form.Label>
                            <Form.Control
                                type="number"
                                step="1"
                                name="num"
                                placeholder='1, 2, 3, 4, 5 ...'
                                value={datos.num}
                                onChange={handleInputChange}
                                onBlur={handleNumeroEntero}
                                required
                                disabled={currentCategoria}
                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
                            Cancelar
                        </Button>
                        <ActionButton outline>
                            {buttonText}
                        </ActionButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FormCategoria;
