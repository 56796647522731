import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { fetchDetalleComprobantes } from '../../reducers/detalleComprobante_slice';
import Table from 'react-bootstrap/Table';

const BalanceGeneral = () => {
  const dispatch = useDispatch();
  const planCuentas = useSelector(state => state.planCuentas.planCuentas || []);
  const detalleComprobantes = useSelector(state => state.detalleComprobantes.detalleComprobantes || []);
  const [mayorizedAccounts, setMayorizedAccounts] = useState([]);
  const [activo, setActivo] = useState({ corriente: [], noCorriente: [] });
  const [pasivo, setPasivo] = useState({ corriente: [], noCorriente: [] });
  const [patrimonio, setPatrimonio] = useState([]);

  useEffect(() => {
    dispatch(fetchPlanCuentas());
    dispatch(fetchDetalleComprobantes());
  }, [dispatch]);

  useEffect(() => {
    console.log("Antes de sumas=",planCuentas,detalleComprobantes)
    if (planCuentas.length > 0 && detalleComprobantes.length > 0) {
      const updatedPlanCuentas = applyDetallesToPlanCuentas(planCuentas, detalleComprobantes);
      const mayorized = mayorizarCuentas(updatedPlanCuentas);

      const activos = mayorized.filter(cuenta => cuenta.codigo_cuenta.startsWith('01')); // Activos
      const pasivos = mayorized.filter(cuenta => cuenta.codigo_cuenta.startsWith('02')); // Pasivos
      const patrimonios = mayorized.filter(cuenta => cuenta.codigo_cuenta.startsWith('03')); // Patrimonio

      const activoCorriente = activos.filter(cuenta => cuenta.codigo_cuenta.startsWith('0101')); // Activo Corriente
      const activoNoCorriente = activos.filter(cuenta => !cuenta.codigo_cuenta.startsWith('0101')); // Activo No Corriente

      const pasivoCorriente = pasivos.filter(cuenta => cuenta.codigo_cuenta.startsWith('0201')); // Pasivo Corriente
      const pasivoNoCorriente = pasivos.filter(cuenta => !cuenta.codigo_cuenta.startsWith('0201')); // Pasivo No Corriente

      setMayorizedAccounts(mayorized);
      setActivo({ corriente: activoCorriente, noCorriente: activoNoCorriente });
      setPasivo({ corriente: pasivoCorriente, noCorriente: pasivoNoCorriente });
      setPatrimonio(patrimonios);
    }
  }, [planCuentas, detalleComprobantes]);

  const applyDetallesToPlanCuentas = (cuentas, detalles) => {
    const cuentasMap = {};
    cuentas.forEach(cuenta => {
      cuentasMap[cuenta.codigo_cuenta] = { ...cuenta, debe: 0, haber: 0 };
    });

    detalles.forEach(detalle => {
      if (cuentasMap[detalle.codigo_cuenta]) {
        cuentasMap[detalle.codigo_cuenta].debe += parseFloat(detalle.debe || 0);
        cuentasMap[detalle.codigo_cuenta].haber += parseFloat(detalle.haber || 0);
      }
    });

    return Object.values(cuentasMap).map(cuenta => ({
      ...cuenta,
      saldo: cuenta.debe - cuenta.haber,
    }));
  };

  const mayorizarCuentas = (cuentas) => {
    const cuentasMap = {};
    cuentas.forEach(cuenta => {
      cuentasMap[cuenta.id] = { ...cuenta, saldoAcumulado: parseFloat(cuenta.saldo || 0) };
    });

    cuentas.forEach(cuenta => {
      if (cuenta.id_padre && cuentasMap[cuenta.id_padre]) {
        cuentasMap[cuenta.id_padre].saldoAcumulado += cuentasMap[cuenta.id].saldoAcumulado;
      }
    });

    return Object.values(cuentasMap);
  };

  const calcularTotal = cuentas => cuentas.reduce((total, cuenta) => total + parseFloat(cuenta.saldoAcumulado || 0), 0);

  const formatSaldo = (saldo) => {
    const saldoNumerico = parseFloat(saldo);
    return isNaN(saldoNumerico) ? '0.00' : saldoNumerico.toFixed(2);
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Balance General</h2>
      <div className="row">
        <div className="col-md-6">
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="2" className="text-center"><strong>Activo</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2"><strong>Activo Corriente</strong></td>
              </tr>
              {activo.corriente.map(cuenta => (
                <tr key={cuenta.id}>
                  <td>{cuenta.nombre_cuenta}</td>
                  <td className="text-end">{formatSaldo(cuenta.saldoAcumulado)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2"><strong>Activo No Corriente</strong></td>
              </tr>
              {activo.noCorriente.map(cuenta => (
                <tr key={cuenta.id}>
                  <td>{cuenta.nombre_cuenta}</td>
                  <td className="text-end">{formatSaldo(cuenta.saldoAcumulado)}</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total Activo</strong></td>
                <td className="text-end">
                  {(calcularTotal(activo.corriente) + calcularTotal(activo.noCorriente)).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="col-md-6">
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="2" className="text-center"><strong>Pasivo y Patrimonio</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2"><strong>Pasivo Corriente</strong></td>
              </tr>
              {pasivo.corriente.map(cuenta => (
                <tr key={cuenta.id}>
                  <td>{cuenta.nombre_cuenta}</td>
                  <td className="text-end">{formatSaldo(cuenta.saldoAcumulado)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2"><strong>Pasivo No Corriente</strong></td>
              </tr>
              {pasivo.noCorriente.map(cuenta => (
                <tr key={cuenta.id}>
                  <td>{cuenta.nombre_cuenta}</td>
                  <td className="text-end">{formatSaldo(cuenta.saldoAcumulado)}</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total Pasivo</strong></td>
                <td className="text-end">
                  {(calcularTotal(pasivo.corriente) + calcularTotal(pasivo.noCorriente)).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colSpan="2"><strong>Patrimonio</strong></td>
              </tr>
              {patrimonio.map(cuenta => (
                <tr key={cuenta.id}>
                  <td>{cuenta.nombre_cuenta}</td>
                  <td className="text-end">{formatSaldo(cuenta.saldoAcumulado)}</td>
                </tr>
              ))}
              <tr>
                <td><strong>Total Patrimonio</strong></td>
                <td className="text-end">{calcularTotal(patrimonio).toFixed(2)}</td>
              </tr>
              <tr>
                <td><strong>Total Pasivo + Patrimonio</strong></td>
                <td className="text-end">
                  {(calcularTotal(pasivo.corriente) + calcularTotal(pasivo.noCorriente) + calcularTotal(patrimonio)).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default BalanceGeneral;
