import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegiones, removeRegion } from '../../reducers/region_slice';
import RegionForm from './RegionForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Button from 'react-bootstrap/Button';
import CustomPrintModal from '../UI/CustomPrintModal';

const RegionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const regiones = useSelector(state => state.region.regiones || []);
  const [showModal, setShowModal] = useState(false);
  const [currentRegion, setCurrentRegion] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchRegiones());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarla!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeRegion(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La región ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.message || 'No se pudo eliminar la región.', 'error');
        });
      }
    });
  };

  const handleEdit = region => {
    setCurrentRegion(region);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentRegion(null);
    setShowModal(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      regiones.map(region => ({
        ID: region.id,
        Nombre: region.nombre,
        "Gentilicio Masculino": region.gentilicio_masc,
        "Gentilicio Femenino": region.gentilicio_fem,
        Abreviatura: region.abrev,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Regiones");
    XLSX.writeFile(workbook, "regiones.xlsx");
  };

  const handleShowPreview = () => {
    const content = {
      head: [['ID', 'Nombre', 'Gentilicio Masculino', 'Gentilicio Femenino', 'Abreviatura']],
      body: regiones.map(region => [
        region.id,
        region.nombre,
        region.gentilicio_masc,
        region.gentilicio_fem,
        region.abrev,
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className="table-title">Lista de Regiones</h1>
      <div className="list-container-header d-flex justify-content-between">
        <div className="left-buttons">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
        </div>
        <div className="right-buttons d-flex gap-2">
          <Button variant="outline-primary" onClick={handleCreate}>
            <i className="bi bi-plus-circle"></i> Nueva Región
          </Button>
          <Button variant="outline-secondary" onClick={handleShowPreview}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
          <Button variant="outline-success" onDoubleClick={handleExportExcel}>
            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
          </Button>
        </div>
      </div>
      <div className="table-container">
        <Table bordered hover size='sm'>
          <thead className='text-center align-middle'>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Gentilicio Masculino</th>
              <th>Gentilicio Femenino</th>
              <th>Abreviatura</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {regiones.map(region => (
              <tr key={region.id}>
                <td>{region.id}</td>
                <td>{region.nombre}</td>
                <td>{region.gentilicio_masc}</td>
                <td>{region.gentilicio_fem}</td>
                <td>{region.abrev}</td>
                <td>
                  <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    title="Editar" 
                    onClick={() => handleEdit(region)}
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>{' '}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    title="Eliminar" 
                    onClick={() => handleDelete(region.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {regiones.length === 0 && (
          <p className="text-center mt-4">No hay regiones disponibles.</p>
        )}
      </div>
      <RegionForm currentRegion={currentRegion} showModal={showModal} handleClose={() => setShowModal(false)} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE REGIONES"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default RegionList;
