// PersonaList.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPersonas, personaActions, removePersona } from '../../reducers/persona_slice';
import PersonaForm from './PersonaForm';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import TablePersona from './TablePersona';

const PersonaList = () => {
  const dispatch = useDispatch();
  const sortedFilteredPersonas = useSelector(state => state.persona.sortedFilteredList || []);
  const [showModal, setShowModal] = useState(false);
  const currentPersona = useSelector(state => state.persona.current);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
 
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPersonas());
  }, [dispatch]);

  

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePersona(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'La persona ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar la persona', 'error');
        });
      }
    });
  };
   

  const handleBack = () => {
    navigate('/admin'); // Ruta a la que deseas regresar, en este caso, la página principal
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedFilteredPersonas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Personas");
    XLSX.writeFile(workbook, "personas.xlsx");
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Nombre', 'Apellido paterno', 'Apellido materno', 'C.I.', 'Fotografía']],
      body: sortedFilteredPersonas.map((persona, index) => [
        index+1,
        persona.nombre,
        persona.apellido1,
        persona.apellido2,
        persona.ci,
        persona.foto ? `Sí` : `No`
      ])
    };
    return content;
  };
  console.log("Personas=",sortedFilteredPersonas)
  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Personas</h1>
      <div className="list-content">
   
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        <TablePersona handleDelete={handleDelete}/>
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PERSONAS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default PersonaList;
