import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

const depositoService = apiService('/depositos');

// Fetch depósitos by afiliado
export const fetchDepositosByAfiliado = createAsyncThunk('deposito/fetchDepositosByAfiliado', async (id_afiliado) => {
  try {
    const response = await depositoService.getAllBySomeId('afiliado', id_afiliado);
    return response;
  } catch (error) {
    throw error;
  }
});

// Add depósito
export const addDeposito = createAsyncThunk('deposito/addDeposito', async (depositoData) => {
  try {
    const response = await depositoService.create(depositoData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchAllDepositos = createAsyncThunk(
  'deposito/fetchAllDepositos',
  async () => {
    try {
      const response = await depositoService.getAllSome('All');
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDepositosByCI = createAsyncThunk(
  'deposito/fetchDepositosByCI',
  async (ci) => {
    try {
      const response = await depositoService.getAllBySomeId('ci', ci);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Slice
const depositoSlice = createSlice({
  name: 'deposito',
  initialState: {
    depositos: [],
    current: null,
    statusSend: 'idle',
    statusGet: 'idle',
    error: null,
  },
  reducers: {
    resetCurrentDeposito(state) {
      state.current = null;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepositosByAfiliado.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchDepositosByAfiliado.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchDepositosByAfiliado.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDeposito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addDeposito.fulfilled, (state, action) => {
        state.statusSend = 'idle';
        state.depositos.push(action.payload);
      })
      .addCase(addDeposito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.error.message;
      })
       // Fetch all depósitos
      .addCase(fetchAllDepositos.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchAllDepositos.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchAllDepositos.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })

      // Fetch depósitos by CI
      .addCase(fetchDepositosByCI.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchDepositosByCI.fulfilled, (state, action) => {
        state.statusGet = 'idle';
        state.depositos = action.payload;
      })
      .addCase(fetchDepositosByCI.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      });
      },
    });

export const { resetCurrentDeposito, resetStatusSend } = depositoSlice.actions;
export default depositoSlice.reducer;
