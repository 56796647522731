import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAfiliado, modifyAfiliado, fetchAfiliados, modifySituacionAfiliado, fetchInscripcionesAfiliados } from '../../reducers/afiliado_slice';
import { fetchPersonas, fetchPersonasNoAfiliadas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import PersonaForm from '../persona/PersonaForm';
import { fullNamePersona } from '../../util/customFormatter';
import SelectPersona from '../persona/SelectPersona';

const initialState = {
    id_persona: "",
    persona:null,
    aliasSituacion:"preinscrito",
    observacion: "",
}

const initialFormDetalles = {title:"Nueva Inscripción",
    headerColor:"#254159", 
    buttonText:"Crear" }

const AfiliadoForm = ({ currentAfiliado, showModalForm, handleCloseForm }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const situaciones = useSelector(state => state.situacion.situaciones)

    const dispatch = useDispatch();
    const personas = useSelector(state => state.persona.personas);
    const persona = useSelector(state => state.persona.current);
    const afiliados = useSelector(state => state.afiliado.afiliados);
    const requestStatus = useSelector(state => state.afiliado.statusSend);
    const errorInRequest = useSelector(state => state.afiliado.error);
    const [currentPersona, setCurrentPersona] = useState(null);
    const [showModalPersona, setShowModalPersona] = useState(false);
   
    useEffect(() => {
        if(showModalForm)
        {
        dispatch(fetchPersonasNoAfiliadas());

        if (currentAfiliado) {
            dispatch(fetchAfiliados());
            setFormDetalles({title:"Cambio de situación afiliado",
                headerColor:"#255957", 
                buttonText:"Actualizar" })
            setDatos({
                observacion:"",
                aliasSituacion:currentAfiliado.situacion.alias,
            });
            setIsModified(false);
        } else {
            dispatch(fetchInscripcionesAfiliados());
            resetForm();
        }}
    }, [currentAfiliado,showModalForm, dispatch]);

    useEffect(() => {
           if(persona){ setDatos({
                id_persona: persona.id,
                aliasSituacion:"preinscrito",
                observacion: "",
            });}
    }, [persona]);

    const cambiarSituacionAfiliado = (afiliado) => {
        const {id, ...afiliadoData} = afiliado
        dispatch(modifySituacionAfiliado({id, afiliado:afiliadoData}))
        .then(data => dispatch(fetchAfiliados()))
        handleCloseForm();
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified && datos.aliasSituacion !== currentAfiliado?.situacion.alias) {
            setError('');

            if (currentAfiliado) {
              cambiarSituacionAfiliado({id:currentAfiliado.id, situacion:datos.aliasSituacion, comentario:"Cambio de situación", observacion:datos.observacion})
            } else {
                dispatch(addAfiliado(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el afiliado.',
                        });
                    } else {
                        handleCloseForm();
                        console.log("Va a cambiar lista")
                        dispatch(fetchInscripcionesAfiliados());
                    }
                });
            }
        } else {
            handleCloseForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setFormDetalles(initialFormDetalles);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    const handleSelect = (name, object) => {
        const objetoId=object? object.id : ""
        setIsModified(true);
        setDatos((prevDatos) => ({ ...prevDatos, [`id_${name}`]: objetoId, [name]:object }));
    };

    const personasAfiliadas = personas.filter(persona => afiliados.some(afiliado => afiliado.id_persona === persona.id));
    const filteredSituaciones= situaciones.filter(situ=>
        situ.alias !== "preinscrito" && situ.alias !== "preinscrito2"
    )

    const handleCreatePersona = () => {
        setCurrentPersona(null);
        setShowModalPersona(true);
      };
      
  const handleCloseCreatePersona = () => {
    setShowModalPersona(false);
    setCurrentPersona(null);
  };
console.log("Persona", datos.id_persona)
    return (
        <>
        <Modal show={showModalForm} onHide={handleCloseForm} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit} className='normal-form'>
                
                    <Row xs={1} md={2}>
                       {currentAfiliado &&  
                         <div>
                                <p>N° de registro: <strong>{currentAfiliado?.num_reg}</strong></p>
                                <p>Afiliado:<strong>{currentAfiliado?.persona ? fullNamePersona(currentAfiliado?.persona):""}</strong> </p>
                                <p>C.I.: <strong>{currentAfiliado?.persona?.ci }</strong></p>
                                <p>Situación actual:  <strong>{currentAfiliado?.situacion.nombre}</strong></p>
                        
                        </div>}
                        
                      {currentAfiliado &&
                       <>
                       <Col className='col-form mt-2'>
                           <Form.Label>Situación</Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control"
                                size="sm"
                                value={datos.aliasSituacion}
                                name='aliasSituacion'
                                onChange={handleInputChange}
                                disabled={!currentAfiliado}
                                >
                                <option value="" disabled>- Seleccionar situación -</option>
                                {filteredSituaciones.map(situ =>
                                    <option key={situ.id} value={situ.alias}>{situ.nombre}</option>
                                )}

                                </Form.Control>
                     <Form.Label className='mt-3'>Observación</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="observacion"
                                onChange={handleInputChange}
                                value={datos.observacion}
                            />
                            </Col>
                            </> }
                       
                    </Row>
                {!currentAfiliado &&
                    <SelectPersona labelText="Persona"  
                                    personaSelected={datos.persona} 
                                    onSelect={handleSelect} 
                                    excludeList={personasAfiliadas}
                                    actualizarListaFn={fetchPersonasNoAfiliadas}/>}
                    <div className="d-flex justify-content-between mt-5">
                    <button className="button-mine btn-cancel-outline" onClick={handleCloseForm} type='button'>
                            Cancelar
                        </button>
                        <button className="button-mine btn-success-outline" type="submit" disabled={!isModified}>
                        <i className="bi bi-check-circle"></i> {formDetalles.buttonText}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default AfiliadoForm;
