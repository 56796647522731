import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/cabeceraCat.png';
import { useSelector, useDispatch } from 'react-redux';
const CustomPrintModal5 = ({ 
    show, 
    handleClose, 
    title1, 
    title2, 
    title3, 
    title4, 
    title5, 
    title6, 
    detail1, 
    total1, 
    detail2, 
    total2, 
    copia 
}) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre, usuario,rolActivo, roles,id } = useSelector(state => state.sesion);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, detail1, detail2]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta

        const marginLeftRight = 55; 
        const marginTop = 30; 
        const marginBottom = 75; 
        const headerHeight = 70; 

        const addWatermark = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setFontSize(72);
            doc.setTextColor(240, 240, 240);
            doc.setFont("helvetica", "bold");
            doc.text("COPIA", pageWidth / 2, pageHeight / 2, {
                angle: 45,
                align: 'center'
            });
        };

        const addHeader = (doc, pageNumber) => {
            doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(10);
            if (pageNumber > 0) {
                doc.text(`Página ${pageNumber}`, doc.internal.pageSize.getWidth() - marginLeftRight, marginTop + headerHeight + 20, { align: 'right' });
            }
        };

        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 50, 0);
            doc.setLineWidth(1);
            doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(10);
            doc.setTextColor(0, 50, 0);
            doc.setFont("times", "normal");
            doc.text(' Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(
                `Impreso el: ${new Date().toLocaleString()} | Usuario: ${nombre}`, 
                pageWidth - marginLeftRight, 
                pageHeight - marginBottom + 40, 
                { align: 'right' }
            );
        };

        let pageNumber = 0;

        const addTitles = (doc) => {
            doc.setFontSize(16);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0);
            doc.text(title1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 20, { align: 'center' });
            doc.setFontSize(12);
            doc.text(title2, marginLeftRight, marginTop + headerHeight + 40, { align: 'left' });
            doc.text(title3, marginLeftRight, marginTop + headerHeight + 60, { align: 'left' });
            doc.text(title4, marginLeftRight, marginTop + headerHeight + 80, { align: 'left' });
        };

        const addDetailsAndTotals = (doc) => {
            doc.setFontSize(10);
            doc.setFont("times", "bold");
            doc.text(title5, marginLeftRight, doc.autoTable.previous.finalY + 20);
        
            autoTable(doc, {
                head: detail1.head,
                body: detail1.body,
                startY: doc.autoTable.previous.finalY + 30,
                margin: { left: marginLeftRight, right: marginLeftRight },
                styles: { fontSize: 8 }, // Tamaño de fuente reducido para el cuerpo
            });
        
            const startYAfterFirstDetail = doc.autoTable.previous.finalY + 10;
            doc.text(total1, marginLeftRight, startYAfterFirstDetail + 20);
        
            doc.text(title6, marginLeftRight, startYAfterFirstDetail + 40);
            autoTable(doc, {
                head: detail2.head,
                body: detail2.body,
                startY: startYAfterFirstDetail + 50,
                margin: { left: marginLeftRight, right: marginLeftRight },
                styles: { fontSize: 8 }, // Tamaño de fuente reducido para el cuerpo
            });
        
            doc.text(total2, marginLeftRight, doc.autoTable.previous.finalY + 20);
        };

        if (copia) {
            addWatermark(doc);
        }

        addHeader(doc, pageNumber);
        addTitles(doc);

        autoTable(doc, {
            startY: marginTop + headerHeight + 90,
            margin: { top: marginTop + headerHeight + 80, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom },
            didDrawPage: (data) => {
                pageNumber++;
                addHeader(doc, pageNumber);
                addTitles(doc);
                if (copia) addWatermark(doc);
                addFooter(doc);
            },
            pageBreak: 'auto',
        });

        addDetailsAndTotals(doc);
        addFooter(doc);

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del Recibo"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal5;
