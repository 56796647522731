import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import FormPropietario from './FormPropietario';

const initialFormDetalles = {title:"Nuevo propietario",
    headerColor:"#254159", 
    buttonText:"Crear" }

const ModalPropietario = ({ currentPropietario, showModal, handleClose }) => {
    const dispatch = useDispatch();
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

    useEffect(() => {
        if(showModal)
        {
        dispatch(fetchPersonas());
        dispatch(fetchPropietarios());
        if (currentPropietario) {
            setFormDetalles({title:"Cambio de situación propietario",
                headerColor:"#255957", 
                buttonText:"Actualizar" })
    }
    }}
    , [showModal, dispatch]);

   
    return (
        <>
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormPropietario currentPropietario={currentPropietario} 
                                handleClose={handleClose} 
                                buttonText={formDetalles.buttonText} 
                                resetFormDetalles={()=>setFormDetalles(initialFormDetalles)}
                />
            </Modal.Body>
        </Modal>
        </>
    );
};

export default ModalPropietario;
