import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { fetchAfiliadosByDirectiva } from '../../reducers/dirAfiliado_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import CustomPrintModal from '../UI/CustomPrintModal3';
import * as XLSX from 'xlsx';

const DirAfiliadoView = ({ showModal, handleClose, directiva }) => {
  const [integrantes, setIntegrantes] = useState([]);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showPrintModal, setShowPrintModal] = useState(false);
  const dispatch = useDispatch();
  const afiliadosByDirectiva = useSelector(state => state.dirafiliado.afiliadosByDirectiva || []);
  const afiliados = useSelector(state => state.afiliado.afiliados || []);

  useEffect(() => {
    if (directiva && directiva.id) {
      dispatch(fetchAfiliadosByDirectiva(directiva.id)); // Cargar afiliados de la directiva
    }
    dispatch(fetchAfiliados()); // Cargar todos los afiliados
  }, [dispatch, directiva]);

  useEffect(() => {
    if (afiliadosByDirectiva.length > 0 && afiliados.length > 0) {
      // Construir la lista de integrantes con numeración y datos completos
      const list = afiliadosByDirectiva.map((item, index) => {
        const afiliadoCompleto = afiliados.find(afiliado => afiliado.id === item.id_afiliado);
  
        return {
          nro: index + 1,
          cargo: item.cargo?.nombre || 'Sin cargo',
          nroRegistro: afiliadoCompleto?.num_reg || 'Sin registro',
          ci: afiliadoCompleto?.persona?.ci || 'Sin CI',
          afiliado: afiliadoCompleto?.persona
            ? `${afiliadoCompleto.persona.apellido1 || ''} ${afiliadoCompleto.persona.apellido2 || ''} ${afiliadoCompleto.persona.nombre || ''}`.trim()
            : 'Sin asignar',
        };
      });
  
      setIntegrantes(list);
  
      // Preparar contenido para impresión
      const head = [['Nro', 'Cargo', 'NroRegistro', 'CI', 'Afiliado']];
      const body = list.map(row => [row.nro, row.cargo, row.nroRegistro, row.ci, row.afiliado]);
      setPrintContent({ head, body });
    } else {
      // Si no hay afiliados asignados, inicializar como vacío
      setIntegrantes([]);
      setPrintContent({ head: [['Nro', 'Cargo', 'NroRegistro', 'CI', 'Afiliado']], body: [] });
    }
  }, [afiliadosByDirectiva, afiliados]);
  

  const handleShowPrintModal = () => {
    setShowPrintModal(true);
  };

  const handleClosePrintModal = () => {
    setShowPrintModal(false);
  };

  const handleExportExcel = () => {
    if (integrantes.length === 0) return;

    const worksheet = XLSX.utils.json_to_sheet(
      integrantes.map(row => ({
        Nro: row.nro,
        Cargo: row.cargo,
        NroRegistro: row.nroRegistro,
        CI: row.ci,
        Afiliado: row.afiliado,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Directiva");
    XLSX.writeFile(workbook, `${directiva?.nombre || 'Directiva'}.xlsx`);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <div><strong>Directiva:</strong> {directiva?.nombre || 'Sin nombre'}</div>
              <div><strong>Gestión:</strong> {directiva?.fechaini || 'Sin fecha'} - {directiva?.fechafin || 'Sin fecha'}</div>
              <div><strong>Tipo:</strong> {directiva?.comite || 'Sin tipo'}</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Nro</th>
                <th>Cargo</th>
                <th>NroRegistro</th>
                <th>CI</th>
                <th>Afiliado</th>
              </tr>
            </thead>
            <tbody>
              {integrantes.map((row, index) => (
                <tr key={index}>
                  <td>{row.nro}</td>
                  <td>{row.cargo}</td>
                  <td>{row.nroRegistro}</td>
                  <td>{row.ci}</td>
                  <td>{row.afiliado}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="bi bi-x-circle"></i> Cerrar
          </Button>
          <Button variant="outline-success" onClick={handleExportExcel}>
            <i className="bi bi-file-earmark-spreadsheet"></i> Exportar a Excel
          </Button>
          <Button variant="primary" onClick={handleShowPrintModal}>
            <i className="bi bi-printer"></i> Imprimir
          </Button>
        </Modal.Footer>
      </Modal>

      <CustomPrintModal
        show={showPrintModal}
        handleClose={handleClosePrintModal}
        title1="DIRECTIVA"
        title2={`Directiva: ${directiva?.nombre || ''}`}
        title3={`Gestión: ${directiva?.fechaini || ''} - ${directiva?.fechafin || ''}`}
        title4={`Tipo: ${directiva?.comite || ''}`}
        content={printContent}
        copia={false}
      />
    </>
  );
};

export default DirAfiliadoView;
