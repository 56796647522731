import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "datgeneral"
const datGeneralService = apiService('/datgeneral');

// Thunk para obtener todos los datos generales
export const fetchDatGeneral = createAsyncThunk('datgeneral/fetchDatGeneral', async () => {
  const response = await datGeneralService.getAll();
  return response;
});

// Thunk para agregar un nuevo dato general
export const addDatGeneral = createAsyncThunk('datgeneral/addDatGeneral', async (newDatGeneral) => {
  const response = await datGeneralService.create(newDatGeneral);
  return response;
});

// Thunk para modificar un dato general
export const modifyDatGeneral = createAsyncThunk(
    'datgeneral/modifyDatGeneral',
    async ({ id, datGeneral }, { rejectWithValue }) => {
      try {
        //console.log('Datos recibidos por el thunk:', { id, datGeneral });
        const response = await datGeneralService.update(id, datGeneral);
        //console.log('Respuesta del servicio:', response);
        return response;
      } catch (error) {
        //console.error('Error en el thunk:', error);
        return rejectWithValue(error.response?.data || 'Error al actualizar los datos generales');
      }
    }
  );
// Thunk para eliminar un dato general
export const deleteDatGeneral = createAsyncThunk('datgeneral/deleteDatGeneral', async (id) => {
  await datGeneralService.delete(id);
  return id;
});

// Slice de datgeneral
const datGeneralSlice = createSlice({
  name: 'datgeneral',
  initialState: {
    datgeneralList: [],
    currentDatGeneral: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setCurrentDatGeneral(state, action) {
      state.currentDatGeneral = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDatGeneral.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDatGeneral.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.datgeneralList = action.payload;
      })
      .addCase(fetchDatGeneral.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDatGeneral.fulfilled, (state, action) => {
        state.datgeneralList.push(action.payload);
      })
      .addCase(modifyDatGeneral.fulfilled, (state, action) => {
        const index = state.datgeneralList.findIndex(dat => dat.id === action.payload.id);
        state.datgeneralList[index] = action.payload;
      })
      .addCase(deleteDatGeneral.fulfilled, (state, action) => {
        state.datgeneralList = state.datgeneralList.filter(dat => dat.id !== action.payload);
      });
  }
});

export const { setCurrentDatGeneral } = datGeneralSlice.actions;

export default datGeneralSlice.reducer;
