import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";

const initialUnidad = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia_completa:"",
    descripcion: "",
    superficie: "",
    honorarios:"0",
    indice_calculo:"0",
    num_unidades: "1",
}
const porcentaje_comision=0.06;

const TabDatosProyecto = ({
    datos,
    errors,
    tiposProyecto,
    categorias,
    uniqueAreas,
    tipologiasFiltered,
    unidadSelected,
    editIndex,
    errorUnidadForm,
    handleInputChange,
    setUnidadSelected,
    handleAddUnidad,
    handleEditUnidad,
    handleSaveEdit,
    handleDeleteUnidad,
    recalcularHonorariosTotal,
    formatNumber,
    handleFormat,
    tipoChanged,
    requiereCalcular,
    setRequiereCalcular,
    requiereUnidades,
    esLote,
    isCheckedEspecial,
    handleCheckboxChange,
    tiposEspecialesVisado,
    handleTipoLoteChange,
    handleAddUniqueUnidad,
    handleRequiereUnidades,
    tipoProceso,
    permitirCambios
}) => {
    
    const tipologias = useSelector(state => state.tipologia.tipologias || []);
    
    const handleInputChangeUnidadSelected = (name, value ) => {

        if(name === "id_tipologia" && (unidadSelected.id_categoria === "" || unidadSelected.id_area === "")) {

            const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${value}`);
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_categoria: selectedTipologia.categoria.id,
                                                id_area: selectedTipologia.area.id,
             }))
        }
        else if(name === "id_categoria" && value === "") {
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_tipologia: "",
                                                id_area: "",
             }))
        }
        else if(name === "id_area" && value === "") {
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_tipologia: ""
             }))
        }
        else{ setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value }));}
        if(!requiereUnidades) 
            setRequiereCalcular(true)
    };
    console.log("formatNumber",Number(datos.comision_cat),formatNumber(datos.proyectoPrevio?.comision_cat))
    return (
    <>  
        <Form.Label>DATOS DEL PROYECTO</Form.Label>
                        <Row>
                            <Col md={7}>
                            <Row>
                            <Col md={7} className='col-form'>
                                <Form.Label>Tipo de Proyecto</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="id_tipo_proyecto"
                                    value={datos.id_tipo_proyecto}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    isInvalid={errors.id_tipo_proyecto}
                                    required
                                    disabled={tipoProceso==="resellar" || !permitirCambios}
                                >
                                    <option value="" disabled>Selecciona un tipo de proyecto</option>
                                    {tiposProyecto.map(tipo => (
                                    <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                                    ))}
                                </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Por favor selecciona un tipo de proyecto.
                                    </Form.Control.Feedback>
                                {tipoChanged && <button 
                                        className="button-mine btn-mine-tertiary mt-2" 
                                        onClick={recalcularHonorariosTotal} 
                                        type="button"
                                    > 
                                   <i className="bi bi-calculator"></i>  Recalcular honorarios
                                    </button>}
                                    {errors.recalcularHonorarios && <div className="text-center text-danger">
                                        Debe recalcular los honorarios para guardar el proyecto
                                </div>}
                                </Col>
                                <Col md={5} >
                                <Form.Group>
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="descripcion"
                                        placeholder="Descripción del proyecto"
                                        value={datos.descripcion}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        isInvalid={errors.descripcion}
                                        rows={4} // Optional: sets the height of the textarea
                                        disabled={isCheckedEspecial || !permitirCambios}
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresa una descripción del proyecto.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                          {datos.observacion &&   <Col md={6} >
                                <Form.Group>
                                    <Form.Label>Observación</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="observacion"
                                        placeholder="Observación del proyecto"
                                        value={datos.observacion}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>}
                            </Row>
                            </Col>
                            <Col md={5}>
                            <Form.Label>Cálculo</Form.Label>
                            <div>        
                            <Card className={`shadow-sm ${errors.superficie ? 'border-danger' : ''}`}>
                                <Card.Body>
                                {tipoProceso === "resellar" &&<> 
                                 <Row className="mb-2">
                                    <Col><strong>Anterior Superficie:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.superficie_total)} m2</Col>
                                </Row>
                              <Row className="mb-2">
                                    <Col><strong>Anterior Honorarios:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.honorarios_total)} Bs</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Anterior Comisión C.A.T:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.proyectoPrevio?.comision_cat)} Bs</Col>
                                </Row>
                                <hr className="my-3" /> {/* Línea divisora */}
                                </>}
                                <Row className="mb-2">
                                    <Col><strong>Superficie total:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.superficie_total)} m2</Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col><strong>Total Honorarios:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.honorarios_total)} Bs</Col>
                                </Row>
                                <Row>
                                    <Col><strong>Comisión C.A.T.:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.comision_cat)} Bs</Col>
                                </Row>
                                </Card.Body>
                            </Card>
                            {errors.superficie && (
                                <div className="text-danger mt-2">
                                La superficie no puede ser 0 al aprobar el visado.
                                </div>
                            )}
                            </div>

                            </Col>
                          
                        {!esLote&&    <Col>
                            <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Tiene unidades"
                                    onChange={handleRequiereUnidades}
                                    checked={requiereUnidades}
                                    disabled={!permitirCambios}
                                />
                                
                                </Col>}
                            </Row>
                          
                        {requiereUnidades && !esLote && <>
                           <Form.Label>UNIDADES</Form.Label>
                             <div className="form-container mx-auto p-3 border m-1 rounded-5 bg-light">
                             {errorUnidadForm !== "" && <p className="text-danger">{errorUnidadForm}</p>}
                                <Row
                                    xs={1}
                                    md={3}
                                    className={`${errors.unidades ? 'border-danger' : ''}`}
                                    style={{padding:"0px 15px"}}
                                >
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="categoria">
                                            <Form.Label>Categoría</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_categoria"
                                                value={unidadSelected.id_categoria}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona una categoría</option>
                                                {categorias.map((cate) => (
                                                    <option key={cate.id} value={cate.id}>
                                                        {cate.nombre}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="rubro">
                                            <Form.Label>Rubro</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_area"
                                                value={unidadSelected.id_area}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona un rubro</option>
                                                {uniqueAreas.map((area) => (
                                                    <option key={area.id} value={area.id}>
                                                        {area.nombre}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="mb-3 soft-labels">
                                        <Form.Group controlId="tipologia">
                                            <Form.Label>Tipología</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="id_tipologia"
                                                value={unidadSelected.id_tipologia}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            >
                                                <option value="">Selecciona una tipología</option>
                                                {tipologiasFiltered.map((tipolo) => (
                                                    <option key={tipolo.id} value={tipolo.id}>
                                                        {tipolo.descripcion}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="descripcion">
                                            <Form.Label>Descripción de la unidad</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="descripcion"
                                                placeholder="Descripción de la unidad  (Ej: planta baja, estacionamiento, etc.)"
                                                value={unidadSelected.descripcion}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                                rows={3}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="num_unidades">
                                            <Form.Label>Cantidad de Unidades</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="1"
                                                placeholder="0"
                                                name="num_unidades"
                                                value={unidadSelected.num_unidades}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="soft-labels">
                                        <Form.Group controlId="superficie">
                                            <Form.Label>Superficie (m2)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                placeholder="0,00"
                                                name="superficie"
                                                value={unidadSelected.superficie}
                                                onChange={(e) => handleInputChangeUnidadSelected(e.target.name, e.target.value)}
                                                onBlur={handleFormat}
                                            />
                                        </Form.Group>
                                    </Col>
                                 
                                    <Col md={12} className="d-flex justify-content-end">
                                        {editIndex === null ? (
                                            <button
                                                className="button-mine btn-mine-primary-outline"
                                                onClick={handleAddUnidad}
                                                type="button"
                                                disabled={!permitirCambios}
                                            >
                                                <i className="bi bi-plus-circle"></i> Agregar Unidad
                                            </button>
                                        ) : (
                                            <button
                                                className="button-mine btn-light-blue-outline"
                                                onClick={handleSaveEdit}
                                                type="button"
                                                disabled={!permitirCambios}
                                            >
                                                <i className="bi bi-save"></i> Guardar Edición
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                          
                                {/* Tabla para mostrar las unidades registradas */}
                        <div className='table-container'>
                        <Table 
                            striped 
                            bordered 
                            hover 
                            className={`mt-4`}
                        >
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th style={{width:"23%"}}>Tipología</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Superficie/unidad (m2)</th>
                                    <th>Índice de cálculo</th>
                                    <th>Subtotal Honorarios (Bs)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(datos.unidades) && !errors.unidades?
                            (datos.unidades.map((unidad, index) => {
                                // console.log("")
                                const backColor = `${index}` === `${editIndex}`? "#c4e1f5":null
                                return(
                                <tr key={index} style={{backgroundColor:backColor}}>
                                    <td style={{backgroundColor:backColor}}>{`${unidad.tipologia?.categoria.nombre} CATEGORÍA / ${unidad.tipologia?.area.nombre} / ${unidad.tipologia?.descripcion}`}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.descripcion}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.num_unidades}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.superficie)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.indice_calculo)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.honorarios)}</td>
                                    <td style={{backgroundColor:backColor}}>
                                    {!backColor && <>
                                        <button 
                                            type="button"
                                            onClick={() => handleEditUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Editar unidad">
                                                <i className="bi bi-pencil"></i>
                                        </button>
                                        <button 
                                            type="button"
                                            onClick={() => handleDeleteUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Eliminar unidad">
                                                <i className="bi bi-trash"></i>
                                        </button>
                                    </>}
                                    
                                    </td>
                                </tr>
                            )})):(
                                <tr>
                                <td colSpan="7" className="text-center text-danger">
                                    No hay unidades registradas.
                                </td>
                            </tr>
                            )}

                            </tbody>
                        </Table>
                        {errors.unidades && (
                            <p className="text-danger mt-2">
                                Debe registrar al menos una unidad para enviar el proyecto a Revisión.
                            </p>
                        )}
                        </div>
                        </>}
                        {!requiereUnidades && !esLote && (
                            <>
                             {errorUnidadForm !== "" && <p className="text-danger">{errorUnidadForm}</p>}
                            <Row
                            xs={1}
                            md={4}
                            className={`mt-4 ${errors.unidades ? 'border-danger' : ''}`}
                            style={{padding:"0px 15px"}}
                              >
                                  <Col className="mb-3 soft-labels">
                                      <Form.Group controlId="categoria">
                                          <Form.Label>Categoría</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_categoria"
                                              value={unidadSelected.id_categoria}
                                              onChange={(e) => permitirCambios? handleInputChangeUnidadSelected(e.target.name, e.target.value): null}
                                              disabled={!permitirCambios}
                                          >
                                              <option value="">Selecciona una categoría</option>
                                              {categorias.map((cate) => (
                                                  <option key={cate.id} value={cate.id}>
                                                      {cate.nombre}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col className="mb-3 soft-labels">
                                      <Form.Group controlId="rubro">
                                          <Form.Label>Rubro</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_area"
                                              value={unidadSelected.id_area}
                                              onChange={(e) => permitirCambios? handleInputChangeUnidadSelected(e.target.name, e.target.value): null}
                                              disabled={!permitirCambios}
                                          >
                                              <option value="">Selecciona un rubro</option>
                                              {uniqueAreas.map((area) => (
                                                  <option key={area.id} value={area.id}>
                                                      {area.nombre}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col className="mb-3 soft-labels">
                                      <Form.Group controlId="tipologia">
                                          <Form.Label>Tipología</Form.Label>
                                          <Form.Control
                                              as="select"
                                              name="id_tipologia"
                                              value={unidadSelected.id_tipologia}
                                              onChange={(e) => permitirCambios? handleInputChangeUnidadSelected(e.target.name, e.target.value): null}
                                              disabled={!permitirCambios}
                                          >
                                              <option value="">Selecciona una tipología</option>
                                              {tipologiasFiltered.map((tipolo) => (
                                                  <option key={tipolo.id} value={tipolo.id}>
                                                      {tipolo.descripcion}
                                                  </option>
                                              ))}
                                          </Form.Control>
                                      </Form.Group>
                                  </Col>
                                  <Col className="soft-labels">
                                      <Form.Group controlId="superficie">
                                          <Form.Label>Superficie total (m2)</Form.Label>
                                          <Form.Control
                                              type="number"
                                              step="0.01"
                                              placeholder="0,00"
                                              name="superficie"
                                              value={unidadSelected.superficie}
                                              onChange={(e) => permitirCambios? handleInputChangeUnidadSelected(e.target.name, e.target.value): null}
                                              disabled={!permitirCambios}
                                              onBlur={handleFormat}
                                          />
                                      </Form.Group>
                                  </Col>
                                  <Col md={12} className="d-flex justify-content-end">
                                    {requiereCalcular&&
                                          <button 
                                          className="button-mine btn-mine-tertiary" 
                                          onClick={(e) => permitirCambios? handleAddUniqueUnidad(e):null} 
                                          disabled={!permitirCambios}
                                          type="button"
                                      >
                                         <i className="bi bi-calculator"></i>   Calcular por superficie
                                      </button>
                                    }
                                      {errors.calcularHonorarios && <div className="text-center text-danger">
                                        Debe calcular los honorarios para guardar el proyecto
                                </div>}
                                  </Col>
                              </Row>
                       </> )}
                    
                     
                        {esLote &&
                        (
                            <Row
                            xs={1}
                            md={3}
                            className={`mb-5 ${errors.
                                tipoLote ? 'border-danger' : ''}`}
                            style={{padding:"0px 15px"}}
                        >
                          
                            <Col className="soft-labels">
                                <Form.Check
                                    type="checkbox"
                                    id="default-checkbox"
                                    label="Visado especial de lote"
                                    onChange={handleCheckboxChange}
                                    checked={isCheckedEspecial}
                                    disabled={!permitirCambios}
                                />
                                
                               {isCheckedEspecial && 
                                 <>
                                 <Form.Control
                                 as="select"
                                 className="form-control"
                                 value={datos.tipo_lote}
                                 name='tipo_lote'
                                 onChange={(e) => handleTipoLoteChange(e.target.name, e.target.value)}
                                 disabled={!permitirCambios}
                                 >
                                 <option value="" disabled>- Selecciona una opción -</option>
                                 {tiposEspecialesVisado?.map(especial => (
                                 <option value={especial.alias}>{especial.nombre}</option>  
                               ))}
 
                                 </Form.Control>
                                
                                {errors.tipoLote && (
                                    <p className="text-danger mt-2">
                                        Debe seleccionar una opción.
                                    </p>
                                )}
                                 </>
                             }
                            </Col>
                            {!isCheckedEspecial &&
                              <Col className="soft-labels">
                              <Form.Group controlId="superficie">
                                  <Form.Label>Superficie Total (m2)</Form.Label>
                                  <Form.Control
                                      type="number"
                                      step="0.01"
                                      placeholder="0,00"
                                      name="superficie_total"
                                      value={datos.superficie_total}
                                      onChange={(e) => handleTipoLoteChange(e.target.name, e.target.value)}
                                      onBlur={handleFormat}
                                      disabled={!permitirCambios}
                                  />
                              </Form.Group>
                          </Col>}
                        </Row> 
                        )}
    </>
    );
    }

    export default TabDatosProyecto;