import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import VistaGeneralProyecto from './VistaGeneralProyecto';
import { getEstadoProyecto, getUploadUrl } from '../../util/valueCalculator';
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import { Col, Image, Row } from 'react-bootstrap';


const VerProyectoModal = ({ currentProyecto, handleClose, show}) => {
    const [headerColor, setHeaderColor] = useState("#7b2829");
    
    useEffect(()=>{
        if(show)
          {if(currentProyecto?.estado !== "visado"){
            setHeaderColor("#255957")
            }else{
                setHeaderColor("#7b2829")
            }}
    },[show, currentProyecto])

    {const fotoPreview = currentProyecto?.foto_plano ? getUploadUrl(currentProyecto.foto_plano) : imgPlaceholder
    return (
        <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton style={{ backgroundColor:headerColor, color: 'white' }}>
        <Modal.Title>
          PROYECTO {currentProyecto? getEstadoProyecto(currentProyecto.estado).titulo : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <VistaGeneralProyecto currentProyecto={currentProyecto} ver={true} />
      </Modal.Body>
    </Modal>
    );}
};

export default VerProyectoModal;
