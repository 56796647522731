import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { fetchProcesos } from "../../reducers/proceso_slice";
import { modifyProcesosRol } from "../../reducers/rol_slice";

const FormProcesosRol = ({ show, handleClose, rol }) => {
  const dispatch = useDispatch();
  const procesos = useSelector((state) => state.proceso?.procesos);
  const [selectedProcesos, setSelectedProcesos] = useState([]);
  const currentUsuarioId = useSelector(state => state.sesion.id)
  const [availableProcesos, setAvailableProcesos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


//  console.log("selectedProcesos", selectedProcesos);
  useEffect(() => {
    if (rol) {
      setSelectedProcesos([...rol.procesos]);
    }
    dispatch(fetchProcesos());
  }, [dispatch, rol]);

  useEffect(() => {
    if (rol.procesos) {
      const available = procesos.filter(
        (proceso) =>{
          return !rol.procesos.some(
            (procesoU) =>
             {
              console.log("Rol y procesou", proceso.id, procesoU.id)
              return procesoU.id === proceso.id}
          ) && proceso.alias !== "afiliado"}
      );
      setAvailableProcesos(available);
    }
  }, [procesos, rol]);

  const handleAddProcesos = (procesoToAdd) => {
      setSelectedProcesos((prevSelected) => [
        ...prevSelected, procesoToAdd,
      ]);
      setAvailableProcesos((prevAvailableProcesos) =>
        prevAvailableProcesos.filter((proceso) => proceso.id !== procesoToAdd.id)
      );
    
  };

  const handleRemoveProcesos = (procesoToRemove) => {
    setAvailableProcesos((prevAvailableProcesos) => [
      ...prevAvailableProcesos,
      procesoToRemove,
    ]);
    setSelectedProcesos((prevSelected) =>{
      return prevSelected.filter((proceso) => proceso.id !== procesoToRemove.id)}
    );
  };


  const handleSave = async () => {
    const procesosToSend = selectedProcesos.map((proceso) => proceso.id);
    const fd = new FormData();
    fd.append("procesos", JSON.stringify(procesosToSend));

    dispatch(modifyProcesosRol({id:rol.id, procesos: fd})).then((res) => {
      handleClose();
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProcesos = availableProcesos.filter((proceso) =>
    proceso.titulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!rol || !rol.id) {
    return null;
  }
  const sortedProcesos = [...selectedProcesos].sort(
    (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    }
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#255957", color: 'white' }}>
          <Modal.Title>
            Gestión de Procesos del rol: {rol.rol}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Procesos Disponibles</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Buscar por nombre"
                      aria-label="Buscar por nombre"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {filteredProcesos.map((proceso) => (
                      <ListGroup.Item key={proceso.id}>
                        <div className="d-flex justify-content-between">
                          {proceso.titulo}
                          <button className="icon-button btn-secondary-outline" title="Asignar proceso" onClick={() => handleAddProcesos(proceso)}>
                              <i className="bi bi-plus-lg"></i>
                          </button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Procesos asignados al rol</h5>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {sortedProcesos.map((proceso) => (
                      <ListGroup.Item key={proceso.id}>
                        <div className="d-flex justify-content-between">
                          {proceso.titulo}
                         
                            {(currentUsuarioId === rol.id? proceso.alias !== "admin" : true)&&
                           <button className="icon-button btn-secondary-outline" title="Quitar proceso" onClick={() => handleRemoveProcesos(proceso)}>
                              <i className="bi bi-dash-lg"></i>
                          </button>
}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button-mine btn-cancel-outline" onClick={handleClose} type='button'>
              Cancelar
          </button>
          <button className="button-mine btn-success-outline" type="button" onClick={handleSave}>
            <i className="bi bi-check-circle"></i> Guardar
            </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormProcesosRol;
