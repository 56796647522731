import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProyectos, fetchProyectosByUsuario, removeProyecto, resellarProyecto } from '../../reducers/proyecto_slice';
import FormProyecto from './FormProyecto';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatNumber, formatoOracion, fullNamePersona } from '../../util/customFormatter';
import CustomPrintModalH from '../UI/CustomPrintModalH';
import VerProyectoModal from './VerProyectoModal';
import { formatFechaHora, getEstadoProyecto, numeroALetras } from '../../util/valueCalculator';
import { InputGroup } from 'react-bootstrap';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { fetchReciboById } from '../../reducers/recibo_slice';
import CustomBadge from './CustomBadge';

const ListProyectos = () => {
  const dispatch = useDispatch();
  const proyectos = useSelector(state => state.proyecto.proyectos || [] );
  const [showModal, setShowModal] = useState({open:false, tipo:""});
  const [showModalVer, setShowModalVer] = useState(false);
  const [currentProyecto, setCurrentProyecto] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const { rolActivo, nombre, id:idUser} = useSelector(state => state.sesion);
  const [sortConfig, setSortConfig] = useState({ key1: "", key2: '', key3: null,  direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState({numVisado:'', estado:'activos', textoFiltro:"", filtroSelected:""});
  const [showModalRecibo, setShowModalRecibo] = useState(false); 
  const [reciboData, setReciboData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
  dispatch(fetchProyectos()) 
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
       cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El proyecto de construcción ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el proyecto de construcción', 'error');
        });
      }
    });
  };

 
  const handleSort = (key1, key2, key3=null) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key3:null, key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key3, key1, key2, direction });
  };

  const handleEdit = proyecto => {
    setCurrentProyecto(proyecto);
    setShowModal({open:true, tipo:"editar"});
  };

  const handleViewRecibo = async (proyecto) => {
    try {
        const recibo = proyecto.recibos[0]? await dispatch(fetchReciboById(proyecto.recibos[0].id)).unwrap() : null
        const totalLiteral = numeroALetras(recibo.monto);
        setCurrentProyecto(proyecto)
        setReciboData(recibo? {
            recibo,
            reciboDetalles: recibo.reciboDets,
            totalLiteral
        }:null);
        setShowModalRecibo(true);
    } catch (error) {
        Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
    }
};

  const handleVer= proyecto => {
    setCurrentProyecto(proyecto);
    setShowModalVer(true);
  };
  const handleCloseVer = () => {
    setShowModalVer(false);
    setCurrentProyecto(null);
  };

  const handleCreate = () => {
    setCurrentProyecto(null);
    setShowModal({open:true, tipo:"crear"});
  };
  const handleDuplicar = (proyecto) => {
    const {id, recibos, num_visado, createdAt, updatedAt,
      id_gerente, gerente, id_usuario_creador, usuario,
      fecha_registro, fecha_aprobacion, observacion,
      foto_plano, usuarioAnulador, id_usuario_anulador, ...proyectoToDuplciate} = proyecto
    setCurrentProyecto({...proyectoToDuplciate,
      estado:"borrador"
    });
    setShowModal({open:true, tipo:"duplicar"});
  };

  const handleResellar = async(proyecto) => {
    let mensajeAlerta = `
    <strong>RESELLAR</strong> proyecto <strong>N° ${proyecto.num_visado}.</strong>
    <br> <br>
    <small>Se creará un nuevo proyecto con el mismo N° de visado que tendrá que volver a ser <strong>revisado, aprobado y pagado</strong>. 
    <br> <br>
    También <strong>se anulará</strong> éste ya visado.</small>
  `;
  
    const result = await Swal.fire({
        title: '¿Estás seguro?',
        html: mensajeAlerta, // Usa HTML aq
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#464646',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar'
        })
      if (result.isConfirmed) {
        console.log("Resellando", proyecto)
        dispatch(resellarProyecto({id:proyecto.id})).then((response)=>{
          console.log("REsponse Resellando", response)
          if (response.error || response.payload.error || response.payload.messages) {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text:  response.payload.error || response.payload.messages[0] || 'Error al resellar el proyecto. Intente de nuevo más tarde.',
              });
          } else {
            Swal.fire(`Resellado iniciado`, `Se ha iniciado el resellado del proyecto N°${proyecto.num_visado}`, 'success');   
                      
          }
        })
      }
  };
  
  const handleClose = () => {
    setShowModal({open:false, tipo:""});
    setCurrentProyecto(null);
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleSearch = (event) => {
    const {name, value} =event.target
    setSearchTerm(prev => ({...prev, [name]:value}));
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    // Combinar las cabeceras y los datos
    const exportData = [...contentArrays.head, ...contentArrays.body];
    // Crear la hoja de trabajo con las cabeceras personalizadas
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); // Utiliza aoa_to_sheet para definir tanto cabecera como datos
     const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Proyectos");
    XLSX.writeFile(workbook, "Proyectos.xlsx");
  };

  const sortedProyectos = useMemo(() => {
    // ORDENACION
    let sortedData = [...proyectos];
    if (sortConfig.key2) {
      console.log("Ordenandooooo",sortedData)
      sortedData.sort((a, b) => {
        let aValue, bValue;
            // Comparar subpropiedades
            if (sortConfig.key1 !== "") {
              
              if (sortConfig.key3 !== null) {
                aValue = a[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                bValue = b[sortConfig.key1][sortConfig.key2][sortConfig.key3];
              }else{
                aValue = a[sortConfig.key1][sortConfig.key2];
                bValue = b[sortConfig.key1][sortConfig.key2];
              }
            } 
            // Comparar otros valores
            else if (sortConfig.key2 === "createdAt" || sortConfig.key2 === "updatedAt") {
              aValue = a[sortConfig.key2] ? new Date(a[sortConfig.key2]) : null;
              bValue = b[sortConfig.key2] ? new Date(b[sortConfig.key2]) : null;
            }else{
                aValue = a[sortConfig.key2];
                bValue = b[sortConfig.key2];
            }
            // Manejar valores nulos para que vayan al final
            if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
            if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
    
            // Comparar valores no nulos
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
      });
    }
  
    return sortedData;
  }, [proyectos, sortConfig]);
  
  
  const sortedFilteredProyectos = useMemo(() => {
    
    // FILTRADO
    let filteredList = sortedProyectos.filter(proy => {
      console.log("proy",proy.num_visado,searchTerm)
      const matchesSearch = searchTerm.numVisado!==""? proy.num_visado
        ? `${proy.num_visado}`.includes(searchTerm.numVisado.toLowerCase()) 
        : false : true;
        const matchesEstado = searchTerm.estado==="activos"? proy.estado!=="anulado"? true : false 
                                                  :`${proy.estado}`.includes(searchTerm.estado.toLowerCase()) 
        let matchesFiltro=true;
      if(searchTerm.filtroSelected!==""){
        if(searchTerm.filtroSelected === "proyectista")
        { 
          matchesFiltro = proy.proyectista 
          ? `${fullNamePersona(proy.proyectista.persona, true)}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase()) 
          : false;}
        else if(searchTerm.filtroSelected === "inmueble")
          { matchesFiltro = proy.inmueble 
            ? `${`${proy.inmueble.zona.nombre} ${proy.inmueble.cod_catastral}`}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase()) 
            : false;}
        else if(searchTerm.filtroSelected === "propietario")
          { 
            matchesFiltro = proy.propietario 
            ? `${fullNamePersona(proy.propietario.persona, true)}`.toLowerCase().includes(searchTerm.textoFiltro.toLowerCase()) 
            : false;}
      }
      // const matchesState = selectedEstados?.length > 0
      //   ? selectedSituaciones.some(situ => situ.value === proy.id_situacion)
      //   : true;

      return matchesSearch && matchesFiltro && matchesEstado;
    });
    return filteredList;
}, [sortedProyectos, searchTerm.filtroSelected, searchTerm.textoFiltro, searchTerm.numVisado, searchTerm.estado]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°','Núm. Visado','Proyectista','Inmueble','Propietario','Descripción','Tipo de Proyecto','Superficie (m2)', 'Honorarios (Bs.)', 'Comisión C.A.T. (Bs.)', 'Estado del proyecto']],
      body: sortedFilteredProyectos.map((proyecto, index) => [
        index+1,
        proyecto.num_visado,
        fullNamePersona(proyecto.proyectista.persona, true),
        `${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`,
        fullNamePersona(proyecto.propietario, true),
        proyecto.descripcion || 'N/A',
        proyecto.tipo.nombre,
        formatNumber(proyecto.superficie_total),
        formatNumber(proyecto.honorarios_total),
        formatNumber(proyecto.comision_cat),
        getEstadoProyecto(proyecto.estado).titulo,
      ])
    };
    return content;
  };
const filtros = ['proyectista','inmueble','propietario']
  return (
    <div className="list-container list5">
      <h1 className='table-title'>Lista de Proyectos</h1>
      <div className="list-content">
      
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
           
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent" style={{marginRight:"2rem"}}><i className="bi bi-file-spreadsheet"></i></button>
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Proyecto
            </ActionButton>
          </div>
        </div>
        <div className='row list-controls'>
          <div className="sort-controls col-md-3">
              <Form.Label>Filtro: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.filtroSelected}
                name='filtroSelected'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                <option value="" disabled>- Selecciona un filtro -</option>
                {filtros?.map(filtro => (
                <option key={filtro} value={filtro}>{formatoOracion(filtro)}</option>  
              ))}

                </Form.Control>
                </div>
                <div className="sort-controls col-md-3">
         
            <Form.Control
                size='sm'
                  type="text"
                  placeholder={`Filtrar por ${searchTerm.filtroSelected}`}
                  className="form-control"
                  value={searchTerm.textoFiltro}
                  name="textoFiltro"
                  onChange={handleSearch}
                />
          </div>
          <div className="sort-controls col-md-3">
          <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
             size='sm'
               type="text"
              placeholder="Buscar por número de visado"
              className="form-control"
              value={searchTerm.numVisado}
              name='numVisado'
              onChange={handleSearch}
            />
              </InputGroup>
          </div>
          <div className="sort-controls col-md-3">
              <Form.Label>Estado: </Form.Label>
             <Form.Control
                as="select"
                size='sm'
                className="form-control"
                value={searchTerm.estado}
                name='estado'
                onChange={handleSearch}
                style={{marginLeft:"7px"}}
                >
                <option value="activos">- Activos -</option>
                {["visado","borrador","revision","aprobado","anulado"]?.map(estado => (
                <option key={estado} value={estado}>{getEstadoProyecto(estado).titulo}</option>  
              ))}

                <option value="">- Todos -</option>
                </Form.Control>
                </div>
        </div>
        {proyectos.length === 0 ? (
          <p>No hay proyectos de construcción disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>Indice</th>
                  <th onClick={() => handleSort('','num_visado')}>
                    Número Visado
                    {sortConfig.key2 === 'num_visado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','createdAt')}>
                    Creado
                    {sortConfig.key2 === 'createdAt' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','updatedAt')}>
                    Ultima vez Modificado
                    {sortConfig.key2 === 'updatedAt' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('proyectista','persona','apellido1')}>
                    Proyectista
                    {sortConfig.key1 === 'proyectista' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('inmueble','cod_catastral')}>
                    Inmueble
                    {sortConfig.key1 === 'inmueble' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('propietario','apellido1')}>
                    Propietario
                    {sortConfig.key1 === 'propietario' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','descripcion')}>
                    Descripción del proyecto
                    {sortConfig.key2 === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('tipo', 'nombre')}>
                    Tipo de proyecto
                    {sortConfig.key1 === 'tipo' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','superficie_total')}>
                    Superficie (m2)
                    {sortConfig.key2 === 'superficie_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','honorarios_total')}>
                    Honorarios (Bs)
                    {sortConfig.key2 === 'honorarios_total' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','comision_cat')}>
                    Comisión C.A.T. (Bs)
                    {sortConfig.key2 === 'comision_cat' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','estado')}>
                    Estado
                    {sortConfig.key2 === 'estado' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th>Observación</th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedFilteredProyectos.map((proyecto, index) => {
              
                  return(
                  <tr key={proyecto.id}>
                    <td>{index + 1}</td>
                    <td>{proyecto.num_visado ||  "N/A"}</td>
                    <td>{formatFechaHora(proyecto.createdAt || "")}</td>
                    <td>{formatFechaHora(proyecto.updatedAt) || ""}</td>
                    <td>{fullNamePersona(proyecto.proyectista.persona)}</td>
                    <td>{`${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`}</td>
                    <td>{fullNamePersona(proyecto.propietario)}</td>
                    <td>{proyecto.descripcion}</td>
                    <td>{proyecto.tipo.nombre}</td>
                    <td>{formatNumber(proyecto.superficie_total)}</td>
                    <td>{formatNumber(proyecto.honorarios_total)}</td>
                    <td>{formatNumber(proyecto.comision_cat)}</td>
                    <td><CustomBadge estado={proyecto.estado}/></td>
                    <td >{proyecto.observacion || "N/A"}</td>
                    <td>
                     {(proyecto.estado === "borrador" || (proyecto.estado ==="revision" && rolActivo?.alias === "gerente") || proyecto.estado ==="aprobado") 
                     && <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(proyecto)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      }
                    { proyecto.estado === "borrador"&& !proyecto.observacion?.toLowerCase().includes("resellado") && <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(proyecto.id)}>
                        <i className="bi bi-trash"></i>
                       </button>}
                      <button className="icon-button btn-secondary-outline" title="Ver" onClick={() => handleVer(proyecto)}>
                        <i className="bi bi-eye"></i>
                      </button>
          
                      <button className="icon-button btn-secondary-outline" title="Duplicar" onClick={() => handleDuplicar(proyecto)}>
                      <i className="bi bi-files"></i>
                      </button>
                    
                      {proyecto.estado === "visado"  && 
                      <>
                       {(proyecto.tipo.alias ==="lote"? !proyecto.tipo_lote : true) &&<button className="icon-button btn-secondary-outline" title="Resellar" onClick={() => handleResellar(proyecto)}>
                      <i className="bi bi-ui-checks"></i>
                      </button>}
                      <button className="icon-button btn-secondary-outline" title="Ver Recibo" onClick={() => handleViewRecibo(proyecto)}>
                          <i className="bi bi-receipt"></i>
                      </button>
                      </>}
                    </td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        <FormProyecto handleViewRecibo={handleViewRecibo}
        currentProyecto={currentProyecto} 
        tipoProceso={showModal.tipo}
        showModal={showModal.open} 
        handleClose={handleClose} />
        <VerProyectoModal currentProyecto={currentProyecto} show={showModalVer} handleClose={handleCloseVer} />
       
        <CustomPrintModalH
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PROYECTOS DE CONSTRUCCIÓN"
          title2=""
          content={printContent}
          copia={false}
        />
              {reciboData && showModalRecibo&& (
                <ImprimirRecibo
                    show={showModalRecibo}
                    handleClose={() => setShowModalRecibo(false)}
                    recibo={reciboData.recibo}
                    reciboDetalles={reciboData.reciboDetalles}
                    totalLiteral={reciboData.totalLiteral}
                />
            )}
      </div>
    </div>
  );
};

export default ListProyectos;
